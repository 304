import React from 'react'

import {Input, Select, Toggle, Editor} from '../../../../components/UI/index.js'
import {Divider, H3, H6, S2, Panel} from '../../../../components/UI/index.js'
import {Reseller} from '../../../../../server/functions/resellers/resellers.types.js'

interface AdministrationSettingsProps {
    reseller: Reseller
    onChange: (reseller: Reseller, key?: string) => void
}

export default class AdministrationSettings extends React.Component<AdministrationSettingsProps> {
    onChangeChecked(option, event) {
        const {reseller, onChange} = this.props
        reseller.settings.administration[option] = event.target.checked
        onChange(reseller, `administration[${option}]`)
    }

    onChangeValue(key, event) {
        const {reseller, onChange} = this.props
        reseller.settings.administration[key] = event.target.value
        onChange(reseller)
    }

    onSaveValue(key, event) {
        const {reseller, onChange} = this.props

        if (event && event.target) {
            reseller.settings.administration[key] = event.target.value
        }

        onChange(reseller, `administration[${key}]`)
    }

    render() {
        const {reseller} = this.props

        return (
            <Panel style={{width: 600, padding: 20}}>
                <H3>Administratie</H3>

                <H6>Boekhoudkoppeling</H6>
                <Divider />
                <S2>Selecteer het juiste boekhoudsysteem en vul de benodigde gegevens in.</S2>

                <br />

                <Select
                    label='Boekhoudsysteem'
                    allowEmptyValue
                    placeholder='Geen boekhoudsysteem'
                    value={reseller.settings.administration.api}
                    onChange={this.onSaveValue.bind(this, 'api')}
                >
                    <option value='AFAS'>AFAS</option>
                    <option value='JORTT'>JORTT</option>
                    <option value='JORTT V2'>JORTT V2</option>
                    <option value='WeFact'>WeFact</option>
                    <option value='Twinfield'>Twinfield</option>
                </Select>

                {reseller.settings.administration.api &&
                    <Toggle
                        label='Geen nieuwe klanten aanmaken in Veloyd'
                        info='Nieuwe klanten moeten in het boekhoudsysteem worden aangemaakt.'
                        checked={reseller.settings.administration.noNewCustomers}
                        onChange={this.onChangeChecked.bind(this, 'noNewCustomers')}
                    />
                }

                {reseller.settings.administration.api &&
                    <Toggle
                        label='Klanten niet aanpasbaar in Veloyd'
                        info='Naam en adresgegevens van klanten kunnen niet worden aangepast in Veloyd.'
                        checked={reseller.settings.administration.customersNotEditable}
                        onChange={this.onChangeChecked.bind(this, 'customersNotEditable')}
                    />
                }

                {reseller.settings.administration.api === 'AFAS' &&
                    <>
                        <Input
                            label='Omgevingsnummer'
                            value={reseller.settings.administration.apiEnvironment}
                            onChange={this.onChangeValue.bind(this, 'apiEnvironment')}
                            onBlur={this.onSaveValue.bind(this, 'apiEnvironment')}

                        />

                        <Input
                            label='API Key'
                            value={reseller.settings.administration.apiKey}
                            onChange={this.onChangeValue.bind(this, 'apiKey')}
                            onBlur={this.onSaveValue.bind(this, 'apiKey')}
                        />

                        <Input
                            label='Magazijn'
                            value={reseller.settings.administration.afasWarehouse}
                            onChange={this.onChangeValue.bind(this, 'afasWarehouse')}
                            onBlur={this.onSaveValue.bind(this, 'afasWarehouse')}
                        />

                        <Input
                            label='Administratie'
                            value={reseller.settings.administration.afasAdministration}
                            onChange={this.onChangeValue.bind(this, 'afasAdministration')}
                            onBlur={this.onSaveValue.bind(this, 'afasAdministration')}
                        />

                        <Input
                            label='Type item'
                            value={reseller.settings.administration.afasTypeItem}
                            onChange={this.onChangeValue.bind(this, 'afasTypeItem')}
                            onBlur={this.onSaveValue.bind(this, 'afasTypeItem')}
                        />

                        <Input
                            label='Eenheid'
                            value={reseller.settings.administration.afasUnit}
                            onChange={this.onChangeValue.bind(this, 'afasUnit')}
                            onBlur={this.onSaveValue.bind(this, 'afasUnit')}
                        />

                        <Input
                            label='Klanten getConnector'
                            value={reseller.settings.administration.afasCustomerGetConnector}
                            onChange={this.onChangeValue.bind(this, 'afasCustomerGetConnector')}
                            onBlur={this.onSaveValue.bind(this, 'afasCustomerGetConnector')}
                        />

                        <Input
                            label='Veld Debiteurnummer'
                            placeholder='DebtorId'
                            value={reseller.settings.administration.afasGUIDDebtorId}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDDebtorId')}
                        />

                        <Input
                            label='Veld Debiteurnaam'
                            placeholder='DebtorName'
                            value={reseller.settings.administration.afasGUIDDebtorName}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDDebtorName')}
                        />

                        <Input
                            label='Veld Emailadres'
                            value={reseller.settings.administration.afasGUIDEmail}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDEmail')}
                        />

                        <Input
                            label='Veld Telefoon'
                            value={reseller.settings.administration.afasGUIDPhone}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDPhone')}
                        />

                        <Input
                            label='Veld Straat'
                            placeholder='AdressLine1'
                            value={reseller.settings.administration.afasGUIDStreet}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDStreet')}
                        />

                        <Input
                            label='Veld Huisnummer'
                            value={reseller.settings.administration.afasGUIDNr}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDNr')}
                        />

                        <Input
                            label='Veld Huisnummer toevoeging'
                            value={reseller.settings.administration.afasGUIDAddition}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDAddition')}
                        />

                        <Input
                            label='Veld 2e adres regel'
                            placeholder='AdressLine2'
                            value={reseller.settings.administration.afasGUIDStreet2}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDStreet2')}
                        />

                        <Input
                            label='Veld postcode'
                            placeholder='AdressLine3'
                            value={reseller.settings.administration.afasGUIDPostalCode}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDPostalCode')}
                        />

                        <Input
                            label='Veld plaats'
                            placeholder='AdressLine4'
                            value={reseller.settings.administration.afasGUIDCity}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDCity')}
                        />

                        <Input
                            label='Veld landcode'
                            value={reseller.settings.administration.afasGUIDCountryCode}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDCountryCode')}
                        />

                        <Input
                            label='GUID Veloyd ID'
                            value={reseller.settings.administration.afasGUIDVeloydId}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDVeloydId')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDVeloydId')}
                        />

                        <Input
                            label='GUID klant referentie'
                            value={reseller.settings.administration.afasGUIDCustomerReference}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDCustomerReference')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDCustomerReference')}
                        />

                        <Input
                            label='GUID Track & Trace'
                            value={reseller.settings.administration.afasGUIDTrackTrace}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDTrackTrace')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDTrackTrace')}
                        />

                        <Input
                            label='GUID vervoerder'
                            value={reseller.settings.administration.afasGUIDCarrier}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDCarrier')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDCarrier')}
                        />

                        <Input
                            label='GUID zendingopties'
                            value={reseller.settings.administration.afasGUIDOptions}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDOptions')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDOptions')}
                        />

                        <Input
                            label='GUID landcode'
                            value={reseller.settings.administration.afasGUIDCountry}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDCountry')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDCountry')}
                        />

                        <Input
                            label='GUID gewicht'
                            value={reseller.settings.administration.afasGUIDWeight}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDWeight')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDWeight')}
                        />

                        <Input
                            label='GUID overige info'
                            value={reseller.settings.administration.afasGUIDInfo}
                            onChange={this.onChangeValue.bind(this, 'afasGUIDInfo')}
                            onBlur={this.onSaveValue.bind(this, 'afasGUIDInfo')}
                        />
                    </>
                }

                {reseller.settings.administration.api === 'JORTT' &&
                    <>
                        <Input
                            label='Naam'
                            value={reseller.settings.administration.apiName}
                            onChange={this.onChangeValue.bind(this, 'apiName')}
                            onBlur={this.onSaveValue.bind(this, 'apiName')}
                        />

                        <Input
                            label='API Key'
                            value={reseller.settings.administration.apiKey}
                            onChange={this.onChangeValue.bind(this, 'apiKey')}
                            onBlur={this.onSaveValue.bind(this, 'apiKey')}
                        />
                    </>
                }

                {reseller.settings.administration.api === 'JORTT V2' &&
                    <>
                        <Input
                            label='Client ID'
                            value={reseller.settings.administration.clientId}
                            onChange={this.onChangeValue.bind(this, 'clientId')}
                            onBlur={this.onSaveValue.bind(this, 'clientId')}
                        />

                        <Input
                            label='Client Secret'
                            value={reseller.settings.administration.clientSecret}
                            onChange={this.onChangeValue.bind(this, 'clientSecret')}
                            onBlur={this.onSaveValue.bind(this, 'clientSecret')}
                        />
                    </>
                }

                {reseller.settings.administration.api === 'WeFact' &&
                    <>
                        <Input
                            label='API Key'
                            value={reseller.settings.administration.apiKey}
                            onChange={this.onChangeValue.bind(this, 'apiKey')}
                            onBlur={this.onSaveValue.bind(this, 'apiKey')}
                        />

                        <Toggle
                            label='Controleer op bestaande facturen'
                            info='Als er al een concept factuur bestaat voor de betreffende debiteur dan worden nieuwe factuurregels hieraan toegevoegd.'
                            checked={reseller.settings.administration.checkConceptInvoices}
                            onChange={this.onChangeChecked.bind(this, 'checkConceptInvoices')}
                        />

                        <Toggle
                            label='Stuur bijlage naar WeFact'
                            checked={reseller.settings.administration.sendAttachment}
                            onChange={this.onChangeChecked.bind(this, 'sendAttachment')}
                        />

                        <Toggle
                            label='Ritten samenvoegen op één factuurregel'
                            checked={reseller.settings.administration.mergeOrders}
                            onChange={this.onChangeChecked.bind(this, 'mergeOrders')}
                        />

                        <Toggle
                            label='Periode op factuurregel'
                            checked={reseller.settings.administration.setPeriod}
                            onChange={this.onChangeChecked.bind(this, 'setPeriod')}
                        />

                        <Input
                            label='Productcode ritten'
                            value={reseller.settings.administration.ordersProductCode}
                            onChange={this.onChangeValue.bind(this, 'ordersProductCode')}
                            onBlur={this.onSaveValue.bind(this, 'ordersProductCode')}
                        />

                        <Input
                            label='Productcode zendingen'
                            value={reseller.settings.administration.parcelsProductCode}
                            onChange={this.onChangeValue.bind(this, 'parcelsProductCode')}
                            onBlur={this.onSaveValue.bind(this, 'parcelsProductCode')}
                        />

                        <Input
                            label='Productcode abonnementen'
                            value={reseller.settings.administration.subscriptionsProductCode}
                            onChange={this.onChangeValue.bind(this, 'subscriptionsProductCode')}
                            onBlur={this.onSaveValue.bind(this, 'subscriptionsProductCode')}
                        />

                        <Input
                            label='Synchroniseer klanten uit debiteurgroep'
                            info='Alléén klanten in deze debiteurgroep worden gesynchroniseerd.'
                            value={reseller.settings.administration.customerGroup}
                            onChange={this.onChangeValue.bind(this, 'customerGroup')}
                            onBlur={this.onSaveValue.bind(this, 'customerGroup')}
                        />
                    </>
                }

                {reseller.settings.administration.api === 'Twinfield' &&
                    <>
                        <Input
                            label='Naam'
                            value={reseller.settings.administration.apiName}
                            onChange={this.onChangeValue.bind(this, 'apiName')}
                            onBlur={this.onSaveValue.bind(this, 'apiName')}
                        />

                        <Input
                            label='Wachtwoord'
                            type='password'
                            autocomplete='new-password'
                            value={reseller.settings.administration.apiPassword}
                            onChange={this.onChangeValue.bind(this, 'apiPassword')}
                            onBlur={this.onSaveValue.bind(this, 'apiPassword')}
                        />

                        <Input
                            label='Organisatie'
                            value={reseller.settings.administration.apiOrganisation}
                            onChange={this.onChangeValue.bind(this, 'apiOrganisation')}
                            onBlur={this.onSaveValue.bind(this, 'apiOrganisation')}
                        />

                        <Input
                            label='Administratie'
                            value={reseller.settings.administration.apiOffice}
                            onChange={this.onChangeValue.bind(this, 'apiOffice')}
                            onBlur={this.onSaveValue.bind(this, 'apiOffice')}
                        />

                        <Input
                            label='Factuur type'
                            value={reseller.settings.administration.apiInvoiceType}
                            onChange={this.onChangeValue.bind(this, 'apiInvoiceType')}
                            onBlur={this.onSaveValue.bind(this, 'apiInvoiceType')}
                        />

                        <Input
                            label='Factuur dimensie'
                            value={reseller.settings.administration.apiInvoiceDim}
                            onChange={this.onChangeValue.bind(this, 'apiInvoiceDim')}
                            onBlur={this.onSaveValue.bind(this, 'apiInvoiceDim')}
                        />

                        <Input
                            label='VAT code'
                            value={reseller.settings.administration.apiInvoiceVATCode}
                            onChange={this.onChangeValue.bind(this, 'apiInvoiceVATCode')}
                            onBlur={this.onSaveValue.bind(this, 'apiInvoiceVATCode')}
                        />
                    </>
                }

                <br />
                <br />
                <H6>Factuur instellingen</H6>
                <Divider />

                <Toggle
                    label='Accordeer ritten'
                    checked={reseller.settings.administration.checkOrders}
                    onChange={this.onChangeChecked.bind(this, 'checkOrders')}
                />

                <Toggle
                    label='Toon rit prijsopbouw op facturen'
                    checked={reseller.settings.administration.showPriceExplanationOrders}
                    onChange={this.onChangeChecked.bind(this, 'showPriceExplanationOrders')}
                />

                <Toggle
                    label='Toon zending prijsopbouw op facturen'
                    checked={reseller.settings.administration.showPriceExplanationParcels}
                    onChange={this.onChangeChecked.bind(this, 'showPriceExplanationParcels')}
                />

                <Toggle
                    label='Factureer ritten met prijs €0,00'
                    checked={reseller.settings.administration.invoicePriceZero}
                    onChange={this.onChangeChecked.bind(this, 'invoicePriceZero')}
                />

                <Toggle
                    label='Factureer zendingen met prijs €0,00'
                    checked={reseller.settings.administration.invoiceParcelsPriceZero}
                    onChange={this.onChangeChecked.bind(this, 'invoiceParcelsPriceZero')}
                />

                <br />
                <br />
                <H6>XML export</H6>
                <Divider />

                <Toggle
                    label='Actief'
                    checked={reseller.settings.administration.xmlExport}
                    onChange={this.onChangeChecked.bind(this, 'xmlExport')}
                />

                {reseller.settings.administration.xmlExport &&
                    <Editor
                        label='XML export template'
                        tags={['debtorCode', 'customerName', 'timeStamp', 'startDate', 'endDate', 'productCode', 'quantity']}
                        value={reseller.settings.administration.xmlExportTemplate}
                        onChange={this.onSaveValue.bind(this, 'xmlExportTemplate')}
                    />

                }
            </Panel>
        )
    }
}
