import {z} from 'zod'
import {AddressModel} from './address.js'
import {PackageModel} from './package.js'

const OrderAddress = AddressModel.extend({
    index: z.number().optional(),
    type: z.enum(['pickup', 'delivery']),

    startTime: z.string().regex(/^([01]\d|2[0-3]):[0-5]\d$/),
    endTime: z.string().regex(/^([01]\d|2[0-3]):[0-5]\d$/),

    gpsRequired: z.boolean().optional(),
    scanRequired: z.boolean().optional(),
    signatureRequired: z.boolean().optional(),
    signatureNeighbors: z.boolean().optional(),
    statedAddressOnly: z.boolean().optional(),
    nameRequired: z.boolean().optional(),
    imageRequired: z.boolean().optional(),
    mailboxDelivery: z.boolean().optional(),
    minimumAge: z.literal(16).or(z.literal(18)).or(z.literal(21)).or(z.literal(false)).optional(),
    idCheck: z.boolean().optional(),
    verificationCode: z.string().regex(/^[A-Z,0-9]+$/).max(100).or(z.literal('')).optional(),
    ready: z.boolean().optional(),
    underway: z.boolean().optional(),
    hasArrived: z.boolean().optional(),
    completed: z.boolean().optional(),
    success: z.boolean().optional(),

    timeOfArrival: z.string().regex(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/).or(z.literal('')).optional(),
    estimatedTimeOfArrival: z.string().regex(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/).or(z.literal('')).optional(),
    plannedTimeOfArrival: z.string().regex(/^([0-1][0-9]|2[0-3]):[0-5][0-9]$/).or(z.literal('')).optional(),
    readyEstimatedTimeOfArrival: z.string().optional(),
    nrOfAttempts: z.number().optional(),

    order: z.number().optional(),
    priority: z.number().optional(),
    stopNumber: z.string().optional(),
    locked: z.boolean().optional(),
    distance: z.number().optional(),
    duration: z.any(), // TODO: Define type
    geometry: z.string().optional(),

    // deprecated
    pickupStatus: z.number().int().min(0).max(99).optional(),
    deliveryStatus: z.number().int().min(0).max(99).optional(),
    comment: z.string().optional(),
    signee: z.string().optional(),
    nrNeighbor: z.string().optional(),
    documentId: z.string().optional(),
    signature: z.string().optional(),
    image: z.string().optional(),
    location: z.object({
        lat: z.number(),
        lng: z.number(),
        accuracy: z.number()
    }).optional(),
    scannable: z.array(z.any()).optional(),
    scanned: z.array(z.string()).optional()
})

const ColloType = z.enum(['', 'roll container', 'pallet', 'box', 'crate', 'other'])

const Collo = z.object({
    index: z.number(),
    type: ColloType.optional(),
    description: z.string().max(400).optional(),
    length: z.number().min(0).max(999999).optional(),
    width: z.number().min(0).max(999999).optional(),
    height: z.number().min(0).max(999999).optional(),
    weight: z.number().min(0).max(999999).optional(),
    pickupAddressIndex: z.number().min(0).optional(),
    deliveryAddressIndex: z.number().min(0).optional(),
    barcode: z.string().optional(),
    status: z.string(),
    deliveredAtNeighbors: z.boolean().optional(),
    deliveredInMailbox: z.boolean().optional(),
    nrNeighbor: z.string().optional(),
    reason: z.string().optional(),
    reasonSpecification: z.string().optional(),
    comment: z.string().optional()
})

const PriceExplanation = z.object({
    overruledPrice: z.string().optional(),
    startRate: z.string().optional(),
    feeExtraStop: z.object({
        nrOfStops: z.number(),
        fee: z.string(),
        subtotal: z.string(),
        nrOfStopsIncluded: z.number().optional()
    }).optional(),
    feeForPickupAddress: z.object({
        fee: z.string(),
        nrOfAddresses: z.number(),
        subtotal: z.string()
    }).optional(),
    feeForDeliveryAddress: z.object({
        fee: z.string(),
        nrOfAddresses: z.number(),
        subtotal: z.string()
    }).optional(),
    feeForExtraCollo: z.object({
        fee: z.string(),
        nrOfColli: z.number(),
        subtotal: z.string(),
        nrOfColliIncluded: z.number().optional()
    }).optional(),
    pickupAndDeliveryOptions: z.array(z.object({
        fee: z.string(),
        option: z.string(),
        nrOfAddresses: z.number(),
        subtotal: z.string()
    })).optional(),
    distanceFee: z.object({
        distance: z.number(),
        fee: z.string(),
        subtotal: z.string(),
        distanceIncluded: z.number().optional()
    }).optional(),
    fuelFee: z.object({
        type: z.string(),
        fee: z.string(),
        percentage: z.string(),
        subtotal: z.string(),
        distance: z.number().optional()
    }).optional(),
    zoneFees: z.object({
        fee: z.string(),
        zones: z.array(z.string()),
        subtotal: z.string()
    }).optional(),
    fees: z.array(z.object({
        fee: z.string(),
        name: z.string(),
        subtotal: z.string()
    })).optional(),
    minimumRate: z.string().optional(),
    rounding: z.object({
        rounding: z.number(),
        before: z.string(),
        subtotal: z.string()
    }).optional()
})

const NewOrderModel = z.object({
    reseller: z.string(),
    handledBy: z.enum(['', 'reseller', 'partner', 'external']),
    carrierName: z.string().optional(),
    partnerId: z.string().optional(),
    partnerOrderId: z.string().optional(),
    partnerCustomerId: z.string().optional(),
    externalCarrierId: z.string().optional(),
    externalOrderId: z.string().optional(),
    previousId: z.string().optional(),
    previousReseller: z.string().optional(),
    nextId: z.string().optional(),
    nextReseller: z.string().optional(),
    retourId: z.string().optional(),
    retourReseller: z.string().optional(),
    templateId: z.string().optional(),
    originalTemplateDate: z.string().optional(),
    externId: z.string().optional(),
    createdByCustomer: z.boolean().optional(),
    type: z.string().optional(),
    date: z.string().regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/),
    rrule: z.string().optional(),
    shift: z.string().optional(),
    routeId: z.string().optional(),
    customer: z.string(),
    originalCustomer: z.string().optional(),
    customerAddress: AddressModel,
    contact: z.string(),
    email: z.string(),
    phone: z.string(),
    senderAddress: AddressModel.optional(),
    addresses: z.array(OrderAddress),
    colli: z.array(Collo),
    packagingIds: z.array(z.string()).optional(),
    scanAtHub: z.boolean().optional(),
    addressOrder: z.string(),
    nrOfDeliveries: z.number().int().min(0).max(99),
    autoRetour: z.boolean(),
    retourAddress: OrderAddress.partial().optional(),
    nrOfDaysBeforeRetour: z.number().int().min(0).max(99),
    allowAddCollo: z.enum(['', 'pickup', 'deliver', 'yes']).optional(),
    monitorTemperature: z.boolean().optional(),
    sensorId: z.string().optional(),
    minTemperature: z.number().optional(),
    maxTemperature: z.number().optional(),
    temperatureData: z.array(z.any()).optional(),
    lowestTemperature: z.number().optional(),
    highestTemperature: z.number().optional(),
    priceTableId: z.string().optional(),
    price: z.string().regex(/^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,2}$/),
    priceExplanation: PriceExplanation.optional(),
    feeIds: z.array(z.string()).optional(),
    fees: z.array(z.string()),
    distance: z.number().optional(),
    customDistance: z.boolean().optional().default(false),
    productCodes: z.array(z.string()),
    invoiceDate: z.string().regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/).optional(),
    invoicedAmount: z.string().regex(/^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,2}$/).optional(),
    dontInvoice: z.boolean().optional(),
    checked: z.boolean().optional(),
    invoiced: z.boolean().optional(),
    reference: z.string(),
    notes: z.string(),
    notSuppliedPossible: z.boolean().optional(),
    atHubPossible: z.boolean().optional(),
    reschedulePossible: z.boolean().optional(),
    onHoldPossible: z.boolean().optional(),
    returnPossible: z.boolean().optional(),
    mancoPossible: z.boolean().optional(),
    cancelPossible: z.boolean().optional(),
    status: z.number().int().min(0).max(99),
    history: z.array(z.object({
        type: z.string().optional(),
        correction: z.any(),
        correctionIndex: z.any(),
        timestamp: z.string().optional(),
        date: z.string().optional(),
        status: z.number().optional(),
        routeId: z.string().optional(),
        addressIndex: z.number().optional(),
        colli: z.array(z.object({
            index: z.number(),
            type: z.string().optional(),
            description: z.string().optional(),
            barcode: z.string(),
            pickupAddressIndex: z.number().optional(),
            deliveryAddressIndex: z.number().optional(),
            status: z.string(),
            deliveredAtNeighbors: z.boolean().optional(),
            deliveredInMailbox: z.boolean().optional(),
            nrNeighbor: z.string().optional(),
            reason: z.string().optional(),
            reasonSpecification: z.string().optional()
        })).optional().nullable(),
        description: z.string(),
        scanned: z.array(z.string()).optional().nullable(),
        signee: z.string().optional(),
        documentId: z.string().optional(),
        signatureId: z.string().optional().nullable(),
        imageId: z.string().optional().nullable(),
        location: z.object({
            lat: z.number(),
            lng: z.number(),
            accuracy: z.number()
        }).optional().nullable(),
        priceExplanation: PriceExplanation.optional(),
        comment: z.string().optional().nullable(),
        who: z.string().optional(),
        verificationCode: z.string().optional(),
        image: z.string().optional(),
        signature: z.string().optional(),
        packages: z.array(PackageModel).optional(),

        // deprecated
        time: z.string().optional(),
        pickupStatus: z.number().optional(),
        deliveryStatus: z.number().optional(),
        nrNeighbor: z.string().optional()
    })),
    anonymized: z.boolean().optional(),
    removed: z.boolean().optional(),
    trackTrace: z.string().min(7).max(30),
    labelId: z.string().optional(),
    noTrackTrace: z.boolean().optional(),
    noEmailNotifications: z.boolean().optional(),
    label: z.string().optional(),
    webhookURL: z.string().max(1000).optional(),
    additionalProps: z.record(z.any()).optional(),
    plugin: z.string().optional(),

    // deprecated
    messenger: z.string().optional()
})

const OrderModel = NewOrderModel.extend({
    _id: z.string()
})

export {OrderModel, NewOrderModel, OrderAddress, Collo, ColloType}
