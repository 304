import React from 'react'

import moment from 'moment'

import OrderActions from '../../actions/OrderActions.js'
import ParcelActions from '../../actions/ParcelActions.js'
import dbCodes from '../../../server/dbCodes.js'

import {Alert, Button, CarrierLogo, Colors, IconButton, Modal, H1, H4, S1, S2, P, ScrollView, PrintPopup, PrintModal, Popup, ListItem, Badge, Card, Row, Column, H6, Link, H3, H2} from '../UI/index.js'
import {Input, Check, Spinner} from '../UI/index.js'
import _ from 'lodash'

export default class OrderScanModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            warningNotToday: !!localStorage.getItem('warningNotToday'),
            barcode: '',
            order: null,
            collo: null,
            packaging: null,
            address: null,
            parcel: null,
            externalResponse: null,
            loading: false,
            message: '',
            warning: '',
            error: ''
        }

        this.successAudio = new Audio('../scan.wav')
        this.warningAudio = new Audio('../error.wav')
        this.errorAudio = new Audio('../error.wav')

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open() {
        this.setState({modalIsOpen: true}, () => {
            this.input.focus()
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onSubmit(event) {
        event.preventDefault()
        const {barcode} = this.state

        let warning = ''

        this.setState({loading: true, order: null, packaging: null, parcel: null, warning: '', error: '', externalResponse: ''})

        ParcelActions.scan(barcode, (err, parcel, externalResponse) => {
            if (parcel) {
                if (parcel.status === dbCodes.parcelStatus.geannuleerd()) {
                    this.warningAudio.play()
                    warning = 'Zending is geannuleerd.'
                } else if (externalResponse && (externalResponse.variant === 'error' || externalResponse.variant === 'warning')) {
                    this.warningAudio.play()
                } else {
                    this.successAudio.play()
                }

                this.setState({barcode: '', loading: false, parcel, warning, externalResponse})
            } else {
                OrderActions.findByBarcode(barcode, async (err, orders, packages) => {
                    let id = orders?.[0]?._id

                    if (orders?.length > 1) {
                        const option = await this.popup.openOptions('Er zijn meerdere zendingen gevonden voor deze barcode', 'Selecteer de zending die je wilt scannen', orders.map((order) => {
                            const collo = _.find(order.colli, (collo) => collo.barcode === barcode)
                            const deliveryAddress = order.addresses[collo?.deliveryAddressIndex]

                            return (
                                <ListItem key={order._id} onClick={() => this.popup.onClickOption({value: order._id})}>
                                    <S1 style={{width: 100}}>{moment(order.date).format('YYYY-MM-DD')}</S1>
                                    <P style={{flex: 2, marginLeft: 12}}>
                                        {deliveryAddress ?
                                            `${(deliveryAddress.isBusiness && deliveryAddress.name) ?
                                                deliveryAddress.name :
                                                `${deliveryAddress.street} ${deliveryAddress.nr}${deliveryAddress.addition}`}` :
                                            order.trackTrace
                                        }
                                    </P>
                                    <Badge style={{flex: 1, marginLeft: 12}}>{dbCodes.status[order.status]}</Badge>
                                </ListItem>
                            )
                        }))

                        id = option.value
                    }

                    if (!orders?.length && packages?.length) {
                        id = packages[0]._id

                        if (packages.length > 1) {
                            const option = await this.popup.openOptions('Er zijn meerdere verpakkingen gevonden voor deze barcode', 'Selecteer de verpakking die je wilt scannen', packages.map((packaging) => {
                                return (
                                    <ListItem key={packaging._id} onClick={() => this.popup.onClickOption({value: packaging._id})}>
                                        <S1 style={{width: 100}}>{moment(packaging.events[packaging.events.length - 1].timestamp).format('YYYY-MM-DD')}</S1>
                                        <P style={{flex: 2, marginLeft: 12}}>
                                            {packaging.barcode}
                                        </P>
                                        <Badge style={{flex: 1, marginLeft: 12}}>{dbCodes.packagingStatus[packaging.status]}</Badge>
                                    </ListItem>
                                )
                            }))

                            id = option.value
                        }
                    }

                    if (id) {
                        OrderActions.scan(id, barcode, (err, order, packaging) => {
                            console.log(packaging)
                            if (order) {
                                const collo = _.find(order.colli, (collo) => collo.barcode === barcode)

                                const address = order.addresses[collo.deliveryAddressIndex ?? collo.pickupAddressIndex]

                                let dateText = moment(order.date).locale('nl').format('dddd D MMMM')

                                if (order.date === moment().format('YYYY-MM-DD')) {
                                    dateText = 'vandaag'
                                } else if (order.date === moment().add(1, 'day').format('YYYY-MM-DD')) {
                                    dateText = 'morgen'
                                }

                                if (order.status === dbCodes.status.inBewaring()) {
                                    warning = `Rit is in bewaring tot ${moment(order.date).locale('nl').format('D MMMM')}.`
                                    this.warningAudio.play()
                                } else if (order.status === dbCodes.status.afgerond() || order.status === dbCodes.status.nietAfgerond() || order.status === dbCodes.status.onderweg() || order.status === dbCodes.status.geannuleerd()) {
                                    warning = `Rit heeft status: ${dbCodes.status[order.status]}.`
                                    this.warningAudio.play()
                                } else if (order.date !== moment().format('YYYY-MM-DD') && this.state.warningNotToday) {
                                    warning = `Rit voor ${dateText}.`
                                    this.warningAudio.play()
                                } else if (externalResponse && (externalResponse.variant === 'error' || externalResponse.variant === 'warning')) {
                                    this.warningAudio.play()
                                } else {
                                    this.successAudio.play()
                                }

                                if ((order.status === dbCodes.status.verwacht() || order.status === dbCodes.status.inSortering() || order.status === dbCodes.status.gereed() || order.status === dbCodes.status.onderweg())) {
                                    this.setState({showRouteDetails: true})
                                } else {
                                    this.setState({showRouteDetails: false})
                                }

                                this.setState({barcode: '', loading: false, order, collo, address, externalResponse, warning})
                            } else if (packaging) {
                                this.successAudio.play()

                                this.setState({barcode: '', loading: false, packaging})
                            } else {
                                this.errorAudio.play()
                                this.setState({barcode: '', loading: false, error: `Geen resultaat voor barcode ${barcode}`, externalResponse})
                            }
                        })
                    } else {
                        this.errorAudio.play()
                        this.setState({barcode: '', loading: false, error: `Geen resultaat voor barcode ${barcode}`, externalResponse})
                    }
                })
            }
        })
    }

    onClickGotoTrackTrace() {
        const {order, parcel} = this.state

        if (order) {
            window.open(`/track&trace/${order.trackTrace}`)
        } else if (parcel) {
            window.open(parcel.trackTrace)
        }
    }

    onClickShowOnMap() {
        const {order} = this.state
        const {filteredOrders} = this.props

        if (order) {
            if (filteredOrders[order._id]) {
                this.close()
                OrderActions.setView('routes', true)
                OrderActions.setSelected([order._id])
            } else {
                this.setState({error: 'Deze rit kan niet worden weergeven op de kaart omdat deze niet in de huidige selectie zit, pas de datumselectie of filters aan.'})
            }
        }
    }

    onClickPrintLabels() {
        const {order, collo, parcel} = this.state
        const {printerPageSize} = this.props

        const handlePrintLabel = (err, pdf, errors) => {
            if (err) {
                this.printPopup.setError(err)
            } else {
                if (errors.length) {
                    this.printPopup.setErrors(errors)
                } else {
                    this.printPopup.close()
                }

                if (pdf) {
                    this.printModal.open(pdf)
                }
            }
        }

        this.printPopup.open('Labels printen', '', printerPageSize, (nrOfEmptyLabels) => {
            if (order) {
                OrderActions.printLabels([order._id], nrOfEmptyLabels, null, collo?.index, handlePrintLabel)
            } else if (parcel) {
                ParcelActions.printLabels([parcel._id], nrOfEmptyLabels, handlePrintLabel)
            }
        })
    }

    render() {
        const {modalIsOpen, warningNotToday, barcode, order, collo, address, packaging, parcel, externalResponse, showRouteDetails, loading, message, warning, error} = this.state
        const {user, users, routes, carriers, reseller, history} = this.props

        const {statusColor, status, subStatus} = Colors.orderStatusColor(order)

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                    <H4>Scannen</H4>

                    <div style={{flex: 1}} />

                    <IconButton
                        onClick={this.onClickPrintLabels.bind(this)}
                        disabled={!order && !parcel}
                    >
                        <i className='mdi mdi-printer' />
                    </IconButton>

                    <div style={{flex: 1}} />

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close' />
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)} style={{display: 'flex'}}>
                    <Input
                        label='Barcode'
                        value={barcode}
                        onChange={(event) => this.setState({barcode: event.target.value.toUpperCase().trim()})}
                        ref={(ref) => this.input = ref}
                    />

                    <Button style={{visibility: 'hidden', position: 'absolute'}} type='submit' />
                </form>

                <Check
                    label='Waarschuwing indien rit niet voor vandaag'
                    checked={warningNotToday}
                    onChange={(event) => {
                        localStorage.setItem('warningNotToday', event.target.checked ? 'true' : '')
                        this.setState({warningNotToday: event.target.checked})
                    }}
                />
                <br />

                {loading &&
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 330}}>
                        <Spinner name='ball-clip-rotate' fadeIn='none' />
                    </div>
                }

                {!loading && !order && !packaging && !parcel && !externalResponse &&
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <i style={{fontSize: 250, color: Colors.grey20}} className='mdi mdi-barcode-scan' />
                    </div>
                }

                {message && <Alert style={{marginBottom: 12}} variant='success'>{message}</Alert>}
                {warning && <Alert style={{marginBottom: 12}} variant='warning'>{warning}</Alert>}
                {error && <Alert style={{marginBottom: 12}} variant='danger'>{error}</Alert>}

                {order && user?.reseller !== order?.reseller &&
                    <Alert style={{marginBottom: 12}} variant='danger'>{`Sorteerfout, pakket bestemd voor ${order?.reseller}`}</Alert>
                }

                {externalResponse && externalResponse.message &&
                    <Alert style={{marginBottom: 12}} variant={externalResponse.variant}>{`Dabba melding: ${externalResponse.message}`}</Alert>
                }

                {order && user?.reseller === order?.reseller &&
                    <>
                        <Card style={{marginBottom: 24}}>
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-package-variant-closed' />
                                    <P>{`Collo ${collo.index + 1} van ${order.colli.length} (${collo.barcode})`}</P>
                                </Column>

                                <Column>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', background: statusColor, height: 42, marginLeft: 6, marginRight: 6, marginBottom: 6}}>
                                        <S1>{status}</S1>

                                        {subStatus &&
                                            <P>{subStatus}</P>
                                        }
                                    </div>
                                </Column>
                            </Row>

                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-web' />
                                    {order.noTrackTrace ?
                                            <S1>{order.trackTrace}</S1> :
                                            <Link onClick={() => this.onClickGotoTrackTrace()}>{order.trackTrace}</Link>
                                    }
                                </Column>

                                <Column>
                                    <Button
                                        style={{width: '100%'}}
                                        variant='outline'
                                        label='Rit info'
                                        icon='mdi mdi-eye'
                                        onClick={() => {
                                            history.push('/empty')
                                            setTimeout(() => history.replace('/planning', {orderId: order._id, fromScan: true}), 1)
                                        }}
                                    />

                                    {!!address.position?.lat &&
                                        <Button
                                            style={{width: '100%'}}
                                            variant='outline'
                                            label='Kaart'
                                            icon='mdi mdi-map'
                                            tooltip='Toon rit op kaart'
                                            onClick={this.onClickShowOnMap.bind(this)}
                                        />
                                    }
                                </Column>
                            </Row>
                        </Card>

                        <Card style={{marginBottom: 12, padding: '12px 6px 6px 6px'}}>
                            <Row style={{marginBottom: 12}}>

                                <Column style={{flexDirection: 'column', alignItems: 'center', marginRight: 12}}>
                                    {showRouteDetails &&
                                        <>
                                            <H2 style={{color: order.routeColor || routes[order.routeId]?.color || Colors.textDark, marginBottom: 6, fontWeight: 400, fontSize: 36}}>
                                                {`${order.routeName || routes[order.routeId]?.name || 'Geen route'} ${order.routeStartTime || routes[order.routeId]?.plannedStartTime ? `(${order.routeStartTime || routes[order.routeId]?.plannedStartTime})` : ''}`}
                                            </H2>
                                            <H3 style={{color: order.routeColor || routes[order.routeId]?.color || Colors.textDark, fontWeight: 400}}>
                                                {users[order.routeMessengerId || routes[order.routeId]?.messengerId]?.name || ''}
                                            </H3>
                                            <H1 style={{color: order.routeColor || routes[order.routeId]?.color || Colors.textDark}}>
                                                {address.stopNumber ? address.stopNumber : '-'}
                                            </H1>
                                        </>
                                    }
                                </Column>

                                <Column>
                                    <div style={{flex: 1, marginLeft: 12, minWidth: 0}}>

                                        <S2>{`${address.type === 'pickup' ? 'Ophaaladres' : 'Bezorgadres'}`}</S2>

                                        <P>{`${address.name || ''}`}</P>
                                        <P>{address.attention}</P>
                                        <P>{`${address.street} ${address.nr}${address.addition}`}</P>
                                        <P>{address.street2}</P>
                                        <P>{`${address.postalCode} ${address.city}`}</P>
                                        <P>{address.instructions}</P>

                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {address.gpsRequired &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-map-marker' />
                                            }
                                            {address.signatureRequired &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-fountain-pen-tip' />
                                            }
                                            {address.nameRequired && !address.signatureRequired &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-signature-text' />
                                            }
                                            {address.statedAddressOnly &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-home' />
                                            }
                                            {address.mailboxDelivery &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-mailbox-open-up' />
                                            }
                                            {address.scanRequired &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-barcode-scan' />
                                            }
                                            {address.imageRequired &&
                                                <i style={{fontSize: 20}} className='mdi mdi-camera' />
                                            }
                                            {address.minimumAge &&
                                                <div style={{fontSize: 14, fontWeight: 500, marginRight: 6}}>{`${address.minimumAge}+`}</div>
                                            }
                                            {address.idCheck &&
                                                <i style={{fontSize: 20, marginRight: 3}} className='mdi mdi-card-account-details-outline' />
                                            }
                                            {address.verificationCode &&
                                                <i style={{fontSize: 20}} className='mdi mdi-shield-key-outline' />
                                            }
                                        </div>
                                    </div>

                                    <div style={{flex: 1}}>
                                        <div style={{display: 'flex'}}>
                                            {address.hasArrived && address.timeOfArrival &&
                                                <div style={{flex: 1}}>
                                                    <S2>Aankomsttijd</S2>
                                                    <P>{`${address.timeOfArrival.replace(':', '.')} uur`}</P>
                                                </div>
                                            }

                                            {!address.hasArrived && address.estimatedTimeOfArrival &&
                                                <div style={{flex: 1}}>
                                                    <S2>Verwachte aankomsttijd</S2>
                                                    <P>{`${address.estimatedTimeOfArrival.replace(':', '.')} uur`}</P>
                                                </div>
                                            }

                                            {!address.hasArrived && !address.estimatedTimeOfArrival &&
                                                <div style={{flex: 1}}>
                                                    <S2>Tijdvak</S2>
                                                    <P>{`${address.startTime.replace(':', '.')} - ${address.endTime.replace(':', '.')} uur`}</P>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        label='Datum'
                                        value={moment(order.date).format('DD-MM-YYYY')}
                                        readOnly
                                    />
                                </Column>
                                <Column>
                                    <Input
                                        label='Referentie'
                                        value={order.reference}
                                        readOnly
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        label='Afzender'
                                        value={`${order.senderAddress?.name || ''}${order.senderAddress?.name ? ` (${order.customerAddress?.name})` : order.customerAddress?.name}`}
                                        readOnly
                                    />
                                </Column>
                                <Column>
                                    <Input
                                        label='Opmerkingen'
                                        value={order.notes}
                                        readOnly
                                    />
                                </Column>
                            </Row>
                        </Card>

                        <H6>Historie</H6>

                        <ScrollView style={{maxHeight: 250}}>
                            {[...order.history].reverse().map((entry, index) => {
                                return (
                                    <Row key={`history${index}`} style={{marginBottom: 3}}>

                                        <i className='mdi mdi-circle-small' style={{marginRight: 6, fontSize: 16, color: Colors.grey60}} />

                                        <Column style={{flex: 1, flexDirection: 'column'}}>

                                            <Row style={{alignItems: 'center'}}>
                                                {entry.type === 'invoice' ?
                                                        <Link onClick={() => history.push('/facturatie', {invoiceId: entry.invoiceId})}>{entry.description}</Link> :
                                                        <P>{entry.description || (typeof entry.status === 'number' ? dbCodes.status[entry.status] : '')}</P>
                                                }
                                            </Row>

                                            {entry.colli?.length > 0 &&
                                                <>
                                                    {entry.colli.map((collo, index) => {
                                                        const {status, reason, statusColor} = Colors.colloStatusColor(entry.type, collo)
                                                        const isScanned = entry?.scanned?.findIndex((barcode) => barcode === collo.barcode) > -1

                                                        return (
                                                            <Row key={`collo${index}`}>
                                                                <Column>
                                                                    <i className='mdi mdi-circle' style={{marginRight: 12, marginTop: 4, fontSize: 10, color: statusColor}} />
                                                                    {isScanned && <i className='mdi mdi-barcode-scan ' style={{marginRight: 6}} />}
                                                                    <P>{`${reason || status}${collo.reasonSpecification ? ` - ${collo.reasonSpecification}` : ''}: Collo ${collo.index + 1}${collo.barcode ? ` - ${collo.barcode}` : ''}`}</P>
                                                                </Column>
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            }

                                            {entry.comment &&
                                                <S2 style={{}}>{entry.comment}</S2>
                                            }
                                        </Column>

                                        <Column style={{width: 200}}>
                                            <S2>{`${entry.who} | ${entry.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2>
                                        </Column>
                                    </Row>
                                )
                            })}
                        </ScrollView>
                    </>
                }

                {packaging &&
                    <>
                        <Card style={{marginBottom: 24}}>
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-package-variant-closed' />
                                    <P>{packaging.barcode}</P>
                                </Column>

                                <Column>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', background: statusColor, height: 42, marginLeft: 6, marginRight: 6, marginBottom: 6}}>
                                        <S1>{dbCodes.packagingStatus[packaging.status]}</S1>
                                    </div>
                                </Column>
                            </Row>
                        </Card>

                        <H6>Historie</H6>

                        <ScrollView style={{maxHeight: 250}}>
                            {[...packaging.events].reverse().map((event, index) => {
                                return (
                                    <Row key={`history${index}`} style={{marginBottom: 3}}>

                                        <i className='mdi mdi-circle-small' style={{marginRight: 6, fontSize: 16, color: Colors.grey60}} />

                                        <Column style={{flex: 1, flexDirection: 'column'}}>

                                            <Row style={{alignItems: 'center'}}>
                                                <P>{event.description || dbCodes.packagingStatus[event.status]}</P>
                                            </Row>
                                        </Column>

                                        <Column style={{width: 200}}>
                                            <S2>{`${event.who || ''} | ${moment(event.timestamp).format('DD-MM-YYYY HH:mm:ss')}`}</S2>
                                        </Column>
                                    </Row>
                                )
                            })}
                        </ScrollView>
                    </>
                }

                {parcel &&
                    <>
                        <Card style={{marginBottom: 24}}>
                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <i style={{fontSize: 16, marginRight: 12}} className='mdi mdi-web' />
                                    <Link onClick={() => this.onClickGotoTrackTrace()}>{parcel.trackTrace}</Link>
                                </Column>

                                <Column>
                                    <Button
                                        variant='outline-white'
                                        label='Open zending info'
                                        style={{width: '100%'}}
                                        onClick={() => {
                                            history.push('/empty')
                                            setTimeout(() => history.replace('/zendingen', {parcelId: parcel._id}), 1)
                                        }}
                                    />
                                </Column>
                            </Row>
                        </Card>

                        <Card style={{marginBottom: 12, padding: '12px 6px 6px 6px'}}>
                            <Row>
                                <P
                                    style={{
                                        height: 36,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: 4,
                                        marginBottom: 48,
                                        backgroundColor: reseller.settings.parcels.carriers?.[parcel.carrier]?.useCustomScanColor ? (reseller.settings.parcels.carriers?.[parcel.carrier]?.customScanColor || '') : '',
                                        ...(reseller.settings.parcels.carriers?.[parcel.carrier]?.useCustomScanColor && {
                                            color: Colors.isDarkBackground(reseller.settings.parcels.carriers?.[parcel.carrier]?.customScanColor || '#ffffff') ? 'white' : 'black'
                                        })
                                    }}
                                >
                                    {carriers.find((carrier) => carrier.name === parcel.carrier)?.displayName || parcel.carrier}
                                </P>
                            </Row>
                            <Row style={{marginBottom: 12}}>
                                <Column style={{flexDirection: 'column', alignItems: 'center'}}>
                                    <CarrierLogo
                                        size={100}
                                        carriers={carriers}
                                        value={parcel.carrier || parcel.reseller}
                                    />
                                </Column>

                                <Column style={{flexDirection: 'column', marginBottom: 12}}>
                                    <S2>Bezorgadres</S2>
                                    <P>{parcel.address.name}</P>
                                    <P>{parcel.address.attention}</P>
                                    <P>{`${parcel.address.street} ${parcel.address.nr}${parcel.address.addition}`}</P>
                                    <P>{parcel.address.street2}</P>
                                    <P>{`${parcel.address.postalCode} ${parcel.address.city}`}</P>
                                    <P>{parcel.address.country}</P>

                                    <P style={{marginTop: 12}}>{parcel.address.email}</P>
                                    <P>{parcel.address.phone}</P>
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        label='Datum'
                                        value={moment(parcel.date).format('DD-MM-YYYY')}
                                        readOnly
                                    />
                                </Column>
                                <Column>
                                    <Input
                                        label='Referentie'
                                        value={parcel.reference}
                                        readOnly
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        label='Afzender'
                                        value={parcel.customerAddress?.name}
                                        readOnly
                                    />
                                </Column>
                                <Column>

                                </Column>
                            </Row>
                        </Card>

                        <H6>Historie</H6>

                        <ScrollView style={{maxHeight: 250}}>
                            {[...parcel.history].reverse().map((entry, index) => {
                                return (
                                    <div key={`history${index}`} style={{display: 'flex', alignItems: 'center', padding: 5}}>
                                        <div style={{height: 15, width: 15, borderRadius: '50%', marginRight: 15, background: !entry.status || entry.status === 1 ? Colors.grey20 : Colors.parcelStatus[entry.status]}} />
                                        <div style={{flex: 1}}>
                                            <P>{entry.description || (typeof entry.status === 'number' ? dbCodes.parcelStatus[entry.status] : '')}</P>
                                        </div>
                                        <S2 style={{marginRight: 15}}>{`${entry.who} | ${entry.timestamp ? moment(entry.timestamp).format('DD-MM-YYYY HH:mm:ss') : entry.time}`}</S2>
                                    </div>
                                )
                            })}
                        </ScrollView>
                    </>
                }

                <PrintPopup ref={(ref) => this.printPopup = ref} />
                <PrintModal ref={(ref) => this.printModal = ref} />
                <Popup ref={(ref) => this.popup = ref} />
            </Modal>
        )
    }
}
