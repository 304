import React from 'react'
import _ from 'lodash'

import OrderActions from '../../actions/OrderActions.js'

import {Alert, IconButton, Input, Select, TextArea, S1, CurrencyInput, Price, Toggle, Row, DatePicker, Column, MetricInput, Card, P} from '../UI/index.js'
import {Order} from '../../../server/functions/orders/orders.types.js'
import {Reseller} from '../../../server/functions/resellers/resellers.types.js'

interface AdministrationProps {
    order: Order
    reseller: Reseller
    onUpdateOrder: (order: Order) => void
}

interface AdministrationState {
    order: Order
    dontInvoice: boolean
    invoiceDate: string
    priceTableId: string
    distance: number
    customDistance: boolean
    feeIds: string[]
    reference: string
    price: string
    priceExplanation: Order['priceExplanation']
    fees: Order['fees']
    edit: boolean
    expanded: boolean
    priceLoading: boolean
    loading: boolean
    error: string
    message: string
}

class Administration extends React.PureComponent<AdministrationProps, AdministrationState> {
    initialState: AdministrationState
    price: Price

    constructor(props) {
        super(props)

        this.initialState = {
            order: null,
            dontInvoice: true,
            invoiceDate: null,
            priceTableId: '',
            distance: 0,
            customDistance: false,
            feeIds: [],
            reference: '',
            price: '0,00',
            priceExplanation: {},
            fees: [],
            edit: false,
            expanded: false,
            priceLoading: false,
            loading: false,
            error: '',
            message: ''
        }

        this.state = structuredClone(this.initialState)
    }

    componentDidMount() {
        this.setState({
            order: structuredClone(this.props.order),
            dontInvoice: !!this.props.order.dontInvoice,
            invoiceDate: this.props.order.invoiceDate,
            priceTableId: this.props.order.priceTableId,
            distance: this.props.order.distance,
            customDistance: this.props.order.customDistance,
            feeIds: [...(this.props.order.feeIds || [])],
            reference: this.props.order.reference,
            price: this.props.order.price,
            priceExplanation: this.props.order.priceExplanation,
            fees: [...(this.props.order.fees || [])]
        })
    }

    componentDidUpdate(prevProps) {
        const {edit} = this.state
        if (!edit) {
            const {order} = this.props
            if (!_.isEqual(order, prevProps.order)) {
                this.setState({
                    order: structuredClone(order),
                    dontInvoice: !!order.dontInvoice,
                    invoiceDate: order.invoiceDate,
                    priceTableId: order.priceTableId,
                    distance: order.distance,
                    customDistance: order.customDistance,
                    feeIds: [...(order.feeIds || [])],
                    reference: order.reference,
                    price: order.price,
                    fees: [...(order.fees || [])]
                })
            }
        }
    }

    onChangePrice(price, fees) {
        this.setState({
            priceTableId: '',
            feeIds: [],
            price,
            fees
        })
    }

    onChangePricetableId(event) {
        const {reseller} = this.props
        const priceTable = _.find(reseller.settings.orders.prices, {id: event.target.value})

        this.setState({priceTableId: priceTable?.id || '', feeIds: [], price: '0,00'}, () => {
            if (priceTable?.id) {
                this.calculateDistanceAndPrice()
            }
        })
    }

    onChangeFees(event) {
        this.setState({feeIds: [...event.target.values]}, () => {
            this.calculateDistanceAndPrice()
        })
    }

    onChangeDistance(event) {
        this.setState({priceLoading: true, error: ''})

        this.setState({distance: event.target.value}, () => {
            const {order, priceTableId, feeIds, customDistance} = this.state

            order.priceTableId = priceTableId
            order.feeIds = feeIds
            order.distance = event.target.value
            order.customDistance = customDistance

            OrderActions.getPrice(order, (err, price, _, priceExplanation) => {
                if (!err) {
                    this.setState({priceLoading: false, price, priceExplanation})
                } else {
                    this.setState({priceLoading: false, error: err})
                }
            })
        })
    }

    calculateDistanceAndPrice() {
        const {order, priceTableId, feeIds, distance, customDistance} = this.state

        this.setState({priceLoading: true, error: ''})

        order.priceTableId = priceTableId
        order.feeIds = feeIds
        if (distance) {
            order.distance = distance
        }
        order.customDistance = customDistance

        OrderActions.getPrice(order, (err, price, distance, priceExplanation) => {
            if (!err) {
                this.setState({priceLoading: false, price, distance, priceExplanation})
            } else {
                this.setState({priceLoading: false, error: err})
            }
        })
    }

    getPickupAndDeliveryOptionsSubtotal() {
        const {priceExplanation} = this.state

        return priceExplanation.pickupAndDeliveryOptions.reduce((acc, option) => acc + parseFloat(option.subtotal.replace(',', '.')), 0).toFixed(2).replace('.', ',')
    }

    save() {
        const {dontInvoice, invoiceDate, priceTableId, distance, customDistance, feeIds, reference, price, priceExplanation, fees} = this.state
        const {order} = this.props

        this.setState({loading: true, error: ''})

        OrderActions.editAdministration(order._id, dontInvoice, invoiceDate, priceTableId, distance, customDistance, feeIds, reference, price, priceExplanation, fees, (err, order) => {
            if (err) {
                this.setState({loading: false, error: err})
            } else {
                this.setState({loading: false, edit: false, error: ''})

                this.props.onUpdateOrder(order)
            }
        })
    }

    render() {
        const {order, dontInvoice, invoiceDate, priceTableId, distance, customDistance, feeIds, reference, price, priceExplanation, fees, expanded, edit, priceLoading, error} = this.state
        const {reseller} = this.props

        const priceTable = _.find(reseller.settings.orders.prices, {id: priceTableId})

        return (
            <div style={{marginBottom: 24, width: '100%'}}>
                <Row style={{alignItems: 'center', minHeight: 36}}>
                    <Row style={{cursor: 'pointer'}} onClick={() => this.setState({expanded: !expanded})}>
                        <S1>Administratie</S1>
                        <i style={{marginLeft: 6}}className={expanded ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                    </Row>

                    <div style={{flex: 1}} />

                    {!edit && expanded &&
                        <IconButton
                            icon='mdi mdi-pencil'
                            onClick={() => this.setState({edit: !edit})}
                        />
                    }

                    {edit &&
                        <IconButton
                            icon='mdi mdi-check'
                            onClick={this.save.bind(this)}
                        />
                    }
                </Row>

                {expanded &&
                    <div style={{marginBottom: 12, width: '100%', padding: 6}}>
                        <Row>
                            <Column>
                                <Toggle
                                    label='Rit factureren'
                                    checked={!dontInvoice}
                                    onChange={(event) => this.setState({dontInvoice: !event.target.checked})}
                                    disabled={!edit || order.invoiced}
                                />
                            </Column>

                            <Column>
                                <DatePicker
                                    label='Factuurdatum'
                                    value={invoiceDate}
                                    onChange={(event) => {
                                        if ('target' in event) {
                                            this.setState({invoiceDate: event.target.value})
                                        }
                                    }}
                                    readOnly={!edit}
                                    disabled={dontInvoice || order.invoiced}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <Select
                                    loading={priceLoading}
                                    label='Tarieftabel'
                                    allowEmptyValue
                                    value={priceTableId}
                                    onChange={this.onChangePricetableId.bind(this)}
                                    readOnly={!edit}
                                    disabled={dontInvoice}

                                >
                                    {(reseller.settings.orders.prices || []).map((priceTable) => {
                                        let disabled = false

                                        if (priceTable?.distanceFeeType === 'distance' && !priceTable.startAtReseller && order?.addresses.length === 1) {
                                            disabled = true
                                        }
                                        if (priceTable.distanceFeeType === 'zones' && order?.addresses.length > 2) {
                                            disabled = true
                                        }

                                        return (
                                            <option key={priceTable.id} value={priceTable.id} disabled={disabled}>{priceTable.name}</option>
                                        )
                                    })}
                                </Select>
                            </Column>

                            <Column>
                                <Select
                                    style={{flex: 1, marginLeft: 6}}
                                    label='Toeslag'
                                    values={feeIds}
                                    options={_.keys(priceTable?.fees || {}).map((id) => {
                                        const fee = priceTable.fees[id]
                                        return {value: id, title: fee.name}
                                    })}
                                    onChange={this.onChangeFees.bind(this)}
                                    disabled={!priceTableId || !priceTable || dontInvoice}
                                    readOnly={!edit}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <TextArea
                                    style={{marginRight: 6}}
                                    label='Referentie'
                                    value={reference}
                                    onChange={(event) => this.setState({reference: event.target.value})}
                                    disabled={dontInvoice}
                                    readOnly={!edit}
                                />
                            </Column>

                            <Column>
                                {priceTableId && priceTable?.distanceFeeType === 'distance' && typeof distance === 'number' &&
                                    <>
                                        {customDistance ?
                                                <>
                                                    <MetricInput
                                                        label='Afstand'
                                                        value={distance}
                                                        onChange={this.onChangeDistance.bind(this)}
                                                        precision={2}
                                                        append='km'
                                                        disabled={dontInvoice}
                                                        readOnly={!edit}
                                                    />
                                                    {edit &&
                                                        <IconButton
                                                            icon='mdi mdi-refresh'
                                                            onClick={() => {
                                                                this.setState({customDistance: false}, () => {
                                                                    this.calculateDistanceAndPrice()
                                                                })
                                                            }}
                                                            tooltip='Afstand herberekenen'
                                                            disabled={dontInvoice}
                                                        />
                                                    }
                                                </> :
                                                <>
                                                    <Input
                                                        style={{marginLeft: 6}}
                                                        label='Afstand'
                                                        value={`${distance.toString().replace('.', ',')} km`}
                                                        readOnly
                                                    />
                                                    {edit &&
                                                        <IconButton
                                                            icon='mdi mdi-pencil'
                                                            onClick={() => {
                                                                this.setState({customDistance: true})
                                                            }}
                                                        />
                                                    }
                                                </>
                                        }
                                    </>

                                }

                                {reseller.settings.orders.useNewPrices ?
                                        <CurrencyInput
                                            style={{marginLeft: 6}}
                                            label='Prijs'
                                            value={price}
                                            onChange={(event) => this.setState({price: event.target.value})}
                                            disabled={dontInvoice}
                                            readOnly={priceTableId || !edit}
                                        /> :
                                        <Price
                                            style={{marginLeft: 6}}
                                            label='Prijs'
                                            price={price}
                                            fees={fees}
                                            // @ts-expect-error priceTables is deprecated
                                            priceTables={reseller.settings.priceTables || []}
                                            onChange={this.onChangePrice.bind(this)}
                                            disabled={dontInvoice}
                                            readOnly={!edit}
                                            ref={(ref) => this.price = ref}
                                        />
                                }
                            </Column>
                        </Row>

                        {priceExplanation &&
                            <Row>
                                <Column>
                                    <Card>
                                        <S1 style={{marginBottom: 12}}>Prijsopbouw</S1>

                                        {priceExplanation.overruledPrice && priceExplanation.overruledPrice !== '0,00' &&
                                            <Row style={{width: '50%', marginBottom: 12}}>
                                                <P style={{flex: 1}}>Prijs</P>
                                                <P>€ {priceExplanation.overruledPrice}</P>
                                            </Row>
                                        }

                                        {priceExplanation.minimumRate && priceExplanation.minimumRate !== '0,00' ?
                                                <Row style={{width: '50%'}}>
                                                    <P style={{flex: 1}}>Starttarief</P>
                                                    <P>€ {priceExplanation.minimumRate}</P>
                                                </Row> :
                                                <>
                                                    {priceExplanation.startRate && priceExplanation.startRate !== '0,00' &&
                                                        <Row style={{width: '50%', marginBottom: 12}}>
                                                            <P style={{flex: 1}}>Starttarief</P>
                                                            <P>€ {priceExplanation.startRate}</P>
                                                        </Row>
                                                    }

                                                    {priceExplanation.feeExtraStop && priceExplanation.feeExtraStop.subtotal !== '0,00' &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>{priceExplanation.feeExtraStop.nrOfStops} stop{priceExplanation.feeExtraStop.nrOfStops > 1 ? 's' : ''}</P>
                                                                <P>€ {priceExplanation.feeExtraStop.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.feeForPickupAddress && priceExplanation.feeForPickupAddress.subtotal !== '0,00' &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>{priceExplanation.feeForPickupAddress.nrOfAddresses} ophaaladres{priceExplanation.feeForPickupAddress.nrOfAddresses > 1 ? 'sen' : ''}</P>
                                                                <P>€ {priceExplanation.feeForPickupAddress.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.feeForDeliveryAddress && priceExplanation.feeForDeliveryAddress.subtotal !== '0,00' &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>{priceExplanation.feeForDeliveryAddress.nrOfAddresses} bezorgadres{priceExplanation.feeForDeliveryAddress.nrOfAddresses > 1 ? 'sen' : ''}</P>
                                                                <P>€ {priceExplanation.feeForDeliveryAddress.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.feeForExtraCollo && priceExplanation.feeForExtraCollo.subtotal !== '0,00' &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>{priceExplanation.feeForExtraCollo.nrOfColli} colli</P>
                                                                <P>€ {priceExplanation.feeForExtraCollo.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }
                                                    {priceExplanation.pickupAndDeliveryOptions?.length > 0 && this.getPickupAndDeliveryOptionsSubtotal() !== '0,00' ?
                                                            <Row style={{marginBottom: 12}}>
                                                                <Row style={{width: '50%'}}>
                                                                    <P style={{flex: 1}}>{priceExplanation.pickupAndDeliveryOptions.length} Ophaal- / bezorgoptie{priceExplanation.pickupAndDeliveryOptions.length > 1 ? 's' : ''}</P>
                                                                    <P>€ {this.getPickupAndDeliveryOptionsSubtotal()}</P>
                                                                </Row>
                                                            </Row> :
                                                        null
                                                    }
                                                    {priceExplanation.distanceFee &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>Kilometerprijs {(priceExplanation.distanceFee.distance - (priceExplanation.distanceFee.distanceIncluded || 0)).toFixed(2).replace('.', ',')}km à €{priceExplanation.distanceFee.fee}</P>
                                                                <P>€ {priceExplanation.distanceFee.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.zoneFees &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>Bezorggebied {priceExplanation.zoneFees.zones[0]}{priceExplanation.zoneFees.zones?.[1] ? ` - ${priceExplanation.zoneFees.zones[1]}` : ''}</P>
                                                                <P>€ {priceExplanation.zoneFees.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.fuelFee?.subtotal &&
                                                        <Row style={{marginBottom: 12}}>
                                                            <Row style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>Brandstoftoeslag {priceExplanation.fuelFee.percentage}%</P>
                                                                <P>€ {priceExplanation.fuelFee.subtotal}</P>
                                                            </Row>
                                                        </Row>
                                                    }

                                                    {priceExplanation.fees?.length > 0 ?
                                                        priceExplanation.fees.map((fee) => (
                                                            <Row key={fee.name} style={{width: '50%'}}>
                                                                <P style={{flex: 1}}>Handmatige toeslag {fee.name}</P>
                                                                <P>€ {fee.fee}</P>
                                                            </Row>
                                                        )) :
                                                        null
                                                    }
                                                    {priceExplanation.rounding &&
                                                        <Row style={{width: '50%'}}>
                                                            <P style={{flex: 1, fontWeight: 700}}>Afronding {[
                                                                {value: 1, title: 'Hele euro\'s'},
                                                                {value: 2.5, title: 'Veelvoud van €2,50'},
                                                                {value: 5, title: 'Veelvoud van €5,00'},
                                                                {value: 10, title: 'Veelvoud van €10,00'},
                                                                {value: 5.05, title: 'Veelvoud van €5,00 - €0,05'},
                                                                {value: 10.05, title: 'Veelvoud van €10,00 - €0,05'}
                                                            ].find((r) => r.value === priceExplanation.rounding.rounding)?.title}: €{priceExplanation.rounding.before}
                                                            </P>
                                                            <P style={{fontWeight: 700}}>€ {priceExplanation.rounding.subtotal}</P>
                                                        </Row>
                                                    }
                                                </>
                                        }
                                    </Card>
                                </Column>
                            </Row>
                        }

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </div>
                }
            </div>

        )
    }
}

export default Administration
