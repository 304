import React from 'react'
import moment from 'moment'

import OrderActions from '../../actions/OrderActions.js'
import {withVehiclesHook} from '../../stores/VehicleStore.js'

import {ColorPicker, H6, FlexRow, FlexColumn, IconButton, Colors, P, Panel, ListItem, Icon, SortableList, S1, Caption, Popover, Card, Popup, Button, ToolTip} from '../UI/index.js'
import time from '../../../server/utils/time.js'
import _ from 'lodash'
import {formatDuration} from '../../../utils/utils.js'

class RoutePanel extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidUpdate(prevProps) {
        if (this.props.route !== prevProps.route && this.props.route) {
            console.log(this.props.route)
        }
    }

    isAddressToLate(date, address) {
        return !address.hasArrived && moment(date).hour(address.endTime.split(':')[0]).minute(address.endTime.split(':')[1]).diff(moment(), 'minutes') < 10
    }

    isOrderDisrupted(order, addresses, draggedAddress, newIndex) {
        const {route} = this.props

        // Find the current index of the dragged address in the route
        const currentDraggedAddressIndex = route.addresses.findIndex((address) => address.orderId === draggedAddress.orderId && address.index === draggedAddress.index)

        return order.addresses.some((address) => {
            if (address.index === draggedAddress.index) {
                return false
            }

            // Check if the dragged address is before or after the current address in the order
            const draggedAddressIsBeforeInOrder = draggedAddress.index < address.index
            const draggedAddressIsAfterInOrder = draggedAddress.index > address.index

            // Find the old and new index of the current address in the route
            const addressRouteOldIndex = route.addresses.findIndex((routeAddress) => routeAddress.orderId === order._id && routeAddress.index === address.index)
            const addressRouteIndex = addresses.findIndex((routeAddress) => routeAddress.orderId === order._id && routeAddress.index === address.index)

            if (addressRouteIndex === -1) {
                return false
            }

            // Check if the dragged address is before or after the current address in the new route
            const draggedAddressIsBeforeInRoute = newIndex < addressRouteIndex
            const draggedAddressIsAfterInRoute = newIndex > addressRouteIndex

            // Check if the dragged address was before or after the current address in the old route
            const draggedAddressWasBeforeInRoute = currentDraggedAddressIndex < addressRouteOldIndex
            const draggedAddressWasAfterInRoute = currentDraggedAddressIndex > addressRouteOldIndex

            if (draggedAddressIsBeforeInOrder && draggedAddressIsAfterInRoute) {
                return draggedAddressWasBeforeInRoute
            }

            if (draggedAddressIsAfterInOrder && draggedAddressIsBeforeInRoute) {
                return draggedAddressWasAfterInRoute
            }
        })
    }

    async onDragChange(addresses, newIndex) {
        const {route, orders} = this.props

        const draggedAddress = addresses[newIndex]
        const order = orders[draggedAddress.orderId]

        if (order.addressOrder === 'fixed' && this.isOrderDisrupted(order, addresses, draggedAddress, newIndex)) {
            await this.popup.open('Afwijken van vaste volgorde.', 'Je gaat afwijken van de vaste volgorde van een rit. Weet je zeker dat je door wil gaan?')
            this.popup.close()
        }

        OrderActions.planRoute(route._id, false, false, addresses)
    }

    render() {
        const {usersObject, route, focusedRouteId, viewOrder, onClose, vehicles, reseller, routes} = this.props

        route?.addresses?.map((address) => {
            if (this.isAddressToLate(route?.date, address)) {
                address.toLate = true
            }
        })

        const vehicle = vehicles.find((vehicle) => vehicle._id === route?.vehicleId)
        const vehicleType = reseller.settings.vehicleTypes.find((vehicleType) => vehicleType._id === route?.vehicleTypeId)
        const vehicleName = vehicle?.name || vehicleType?.name || ''

        return (
            <FlexColumn style={{overflow: 'hidden', height: '100%', width: 415, borderLeft: `${route ? 1 : 0}px solid ${Colors.grey40}`}}>
                <Panel style={{border: 'none', background: Colors.backgroundNeutral, padding: 6}}>
                    <FlexRow style={{marginBottom: 12}}>
                        <FlexRow style={{justifyContent: 'center', alignItems: 'center', flex: 4, marginLeft: 36}}>
                            <ColorPicker
                                value={route?.color}
                                colors={Colors.routeColors}
                                onChange={(event) => OrderActions.updateRoute(route?._id, {color: event.target.value})}
                            />

                            <H6 style={{marginBottom: 0, maxWidth: 240}}>{route?.name || ''}</H6>

                            {(route?.plannedStartTime || route?.startTime) &&
                                <H6 style={{marginBottom: 0, marginLeft: 6}}>{`${route?.plannedStartTime || route.startTime ? ` (${route?.plannedStartTime || route.startTime})` : ''}`}</H6>
                            }
                        </FlexRow>

                        <IconButton
                            icon='mdi mdi-close'
                            onClick={onClose}
                        />
                    </FlexRow>

                    <Card style={{width: 398, marginBottom: 24}}>
                        <FlexRow>
                            <FlexColumn>
                                <P>{usersObject[route?.messengerId]?.name}</P>
                                <P>{route?.vehicleType === 'car' ? 'Auto' : route?.vehicleType === 'walk' ? 'Lopen' : 'Fiets'}{route?.customSpeed ? `, ${route?.speedDifference > 0 ? '+' : ''}${route?.speedDifference} %` : ''}</P>
                                <P>{vehicleName}</P>
                                <P>{`Stoptijd: ${route?.stopTime}s`}</P>
                            </FlexColumn>

                            <FlexColumn style={{maxWidth: 175}}>
                                <P ellipsis>{`Start: ${route?.startPoint?.street} ${route?.startPoint?.nr}${route?.startPoint?.addition}`}</P>
                                <P ellipsis>{`Eind: ${route?.endPoint?.street} ${route?.endPoint?.nr}${route?.endPoint?.addition}`}</P>
                            </FlexColumn>
                        </FlexRow>

                        {route?.instructions &&
                            <FlexRow style={{marginTop: 12}}>
                                <Icon style={{marginRight: 6}} icon='mdi mdi-message-outline' />

                                <P ellipsis>{route?.instructions}</P>
                            </FlexRow>
                        }

                        <FlexRow style={{marginTop: 24, gap: 36}}>
                            <FlexColumn>
                                <FlexRow>
                                    <Icon style={{marginRight: 6}} icon='mdi mdi-clock-outline' />

                                    <S1 ellipsis>Starttijd</S1>
                                </FlexRow>

                                <P style={{marginLeft: 24}} ellipsis>Gepland</P>
                                <P style={{marginLeft: 24}} ellipsis>Uitgevoerd</P>
                            </FlexColumn>

                            <FlexColumn style={{width: 50, alignItems: 'flex-end'}}>
                                <S1 ellipsis>{route?.startTime ? formatDuration(moment(route?.startTime, 'HH:mm').diff(moment(route?.plannedStartTime, 'HH:mm'), 'seconds') || 0, true) : '-'}</S1>

                                <P ellipsis>{route?.plannedStartTime || '-'}</P>

                                <P ellipsis>{route?.startTime || '-'}</P>

                            </FlexColumn>

                            <FlexColumn>
                                <FlexRow>
                                    <Icon style={{marginRight: 6}} icon='mdi mdi-timer-sand' />

                                    <S1 ellipsis>Duur</S1>
                                </FlexRow>

                                <P style={{marginLeft: 24}} ellipsis>Gepland</P>
                                <P style={{marginLeft: 24}} ellipsis>Uitgevoerd</P>
                            </FlexColumn>

                            <FlexColumn style={{width: 50, alignItems: 'flex-end'}}>
                                <S1 ellipsis>{route?.endTime ? formatDuration(moment(route?.endTime, 'HH:mm').diff(moment(route?.startTime, 'HH:mm'), 'seconds') - route.plannedDuration, true) : '-'}</S1>

                                <P ellipsis>{route?.plannedDuration ? formatDuration(route.plannedDuration) : '-'}</P>

                                <P ellipsis>{route?.endTime ? formatDuration(moment(route?.endTime, 'HH:mm').diff(moment(route?.startTime, 'HH:mm'), 'seconds')) : '-'}</P>
                            </FlexColumn>
                        </FlexRow>
                    </Card>

                    {!route?.completed &&
                        <FlexRow style={{justifyContent: 'space-between', marginTop: 12}}>
                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                tooltip={focusedRouteId === route?._id ? 'Reset focus' : 'Focus route'}
                                icon={focusedRouteId === route?._id ? 'mdi mdi-image-filter-center-focus-weak' : 'mdi mdi-image-filter-center-focus-strong'}
                                onClick={() => OrderActions.setRouteFocus(route?._id)}
                            />

                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                icon='mdi mdi-map-marker-path'
                                tooltip='Route optimaliseren'
                                disabled={route?.loading || route?.ready}
                                onClick={() => OrderActions.planRoute(route._id, true)}
                            />

                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                icon='mdi mdi-swap-vertical'
                                tooltip='Route omdraaien'
                                disabled={route?.loading || route?.ready || !route?.reversible}
                                onClick={() => OrderActions.planRoute(route._id, false, true)}
                            />

                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                tooltip={route?.ready ? 'Route wijzigen' : 'Route gereed'}
                                icon={route?.ready ? 'mdi mdi-pencil' : 'mdi mdi-check'}
                                disabled={route?.loading}
                                onClick={async () => {
                                    if (route.ready) {
                                        await this.popup.open('Route wijzigen', 'Weet je zeker dat je deze route wilt wijzigen? ')
                                    } else {
                                        const uniqueColli = route.colli?.filter((c, index, self) => c.barcode ? index === self.findIndex((collo) => collo.barcode === c.barcode) : true)

                                        const amountOfColli = uniqueColli?.length || 0

                                        const vehicleType = reseller.settings.vehicleTypes.find((v) => v._id === route.vehicleTypeId)

                                        const vehicle = vehicles.find((vehicle) => vehicle._id === route.vehicleId)

                                        const tooMuchColli = vehicleType?.payloadType === 'colli' && vehicleType.payload > 0 && amountOfColli > vehicleType.payload

                                        let totalWeight = 0

                                        uniqueColli?.forEach((collo) => {
                                            totalWeight += collo.weight
                                        })

                                        const tooHeavy = vehicleType?.payloadType === 'weight' && vehicleType.payload > 0 && totalWeight > vehicleType.payload

                                        const tooFar = vehicleType?.range && vehicleType.range > 0 && route.distance > vehicleType.range

                                        const vehicleRoutes = Object.values(routes).filter((r) => vehicle && r.vehicleId === vehicle?._id && r._id !== route._id && (!r.completed && !route.completed))

                                        const thisRouteStart = route.plannedStartTime || route.startTime
                                        const thisRouteEnd = route.estimatedEndTime || route.endTime

                                        const routeOverlaps = vehicleRoutes.some((r) => {
                                            const startTime = r.plannedStartTime || r.startTime
                                            const endTime = r.estimatedEndTime || r.endTime

                                            return moment(thisRouteStart, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '[)') || moment(thisRouteEnd, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '(]')
                                        })

                                        const messengerRoutes = Object.values(routes).filter((r) => route.messengerId && r.messengerId === route.messengerId && r._id !== route._id && (!r.completed && !route.completed))

                                        const messengerOverlaps = messengerRoutes.some((r) => {
                                            const startTime = r.plannedStartTime || r.startTime
                                            const endTime = r.estimatedEndTime || r.endTime

                                            return moment(thisRouteStart, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '[)') || moment(thisRouteEnd, 'HH:mm').isBetween(moment(startTime, 'HH:mm'), moment(endTime, 'HH:mm'), 'minute', '(]')
                                        })

                                        const warnings = []
                                        if (tooMuchColli) {
                                            warnings.push('Deze route bevat meer colli dan het laadvermogen van het geselecteerde voertuig.')
                                        }

                                        if (tooHeavy) {
                                            warnings.push('Deze route bevat colli die zwaarder zijn dan het laadvermogen van het geselecteerde voertuig.')
                                        }

                                        if (tooFar) {
                                            warnings.push('Deze route is langer dan het bereik van het geselecteerde voertuig.')
                                        }

                                        if ((route.plannedStartTime || route.startTime) && !route.inTimeSlots) {
                                            warnings.push('Deze route haalt niet alle stops binnen het aangegeven tijdvak.')
                                        }

                                        if (routeOverlaps) {
                                            warnings.push('Deze route heeft een dubbel geboekt voertuig.')
                                        }

                                        if (messengerOverlaps) {
                                            warnings.push('Deze route heeft een dubbel geboekte koerier.')
                                        }

                                        if (warnings.length) {
                                            this.popup.setWarnings(warnings)
                                            await this.popup.open('Route gereed maken', 'Weet je zeker dat je door wil gaan?')
                                        }
                                    }

                                    OrderActions.setRouteReady(route._id, !route.ready, (err) => {
                                        if (err) {
                                            this.popup.setError(err)
                                        } else {
                                            this.popup.close()
                                        }
                                    })
                                }}
                            />

                            <Popover
                                placement='left'
                                content={
                                    <div style={{width: 'fit-content'}}>
                                        <ListItem
                                            size='sm'
                                            icon={route?.hidden ?
                                                'mdi mdi-map-marker' :
                                                'mdi mdi-map-marker-off'
                                            }
                                            onClick={() => OrderActions.setRouteHidden(route._id)}
                                        >

                                            {`${route?.hidden ? 'Toon' : 'Verberg'} route`}
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon='mdi mdi-tune'
                                            onClick={() => this.props.onEditRoute(route)}
                                        >
                                            Route instellingen
                                        </ListItem>

                                        <ListItem
                                            size='sm'
                                            icon='mdi mdi-calendar'
                                            disabled={route?.loading}
                                            onClick={async () => {
                                                this.popup.setInput({type: 'date', value: time.tomorrow(), label: 'Datum'})
                                                const date = await this.popup.open('Route verplaatsen', 'Weet je zeker dit zeker?')

                                                OrderActions.rescheduleRoute(route._id, date, (err) => {
                                                    if (err) {
                                                        this.popup.setError(err)
                                                    } else {
                                                        this.popup.close()
                                                    }
                                                })
                                            }}
                                        >
                                            Route verplaatsen
                                        </ListItem>

                                        <ListItem
                                            style={{color: route?.ready || route?.started || route?.completed ? undefined : Colors.errorBright}}
                                            size='sm'
                                            icon='mdi mdi-delete'
                                            disabled={route?.loading || route?.ready || route?.started || route?.completed}
                                            onClick={async () => {
                                                if (route.addresses?.length) {
                                                    await this.popup.open('Route verwijderen', 'Deze route bevat stops, weet je zeker dat je deze route wilt verwijderen?')
                                                }

                                                OrderActions.removeRoute(route._id, (err) => {
                                                    if (err) {
                                                        this.popup.setError(err)
                                                    } else {
                                                        this.popup.close()
                                                    }
                                                })
                                            }}
                                        >
                                            Route verwijderen
                                        </ListItem>
                                    </div>
                                }
                            >
                                <Button
                                    style={{flex: 1}}
                                    variant='outline-white'
                                    icon='mdi mdi-dots-vertical'
                                />
                            </Popover>
                        </FlexRow>
                    }

                    {route?.completed &&
                        <FlexRow style={{justifyContent: 'center', gap: 12}}>
                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                tooltip={route.hidden ? 'Toon route' : 'Verberg route'}
                                icon={route.hidden ?
                                    'mdi mdi-map-marker' :
                                    'mdi mdi-map-marker-off'
                                }
                                onClick={() => OrderActions.setRouteHidden(route._id)}
                            />

                            <Button
                                style={{flex: 1}}
                                variant='outline-white'
                                tooltip={focusedRouteId === route?._id ? 'Reset focus' : 'Focus route'}
                                icon={focusedRouteId === route?._id ? 'mdi mdi-image-filter-center-focus-weak' : 'mdi mdi-image-filter-center-focus-strong'}
                                onClick={() => OrderActions.setRouteFocus(route?._id)}
                            />

                            {localStorage.admin &&
                                <Button
                                    style={{flex: 1}}
                                    variant='outline-white'
                                    icon='mdi mdi-cached'
                                    tooltip='Route duur herberekenen'
                                    disabled={route?.loading}
                                    onClick={() => OrderActions.planRoute(route._id)}
                                />
                            }
                        </FlexRow>
                    }
                </Panel>

                {!route?.ready && !route?.completed && _.some(route?.addresses, (address) => address.locked) &&
                    <FlexRow style={{justifyContent: 'center'}}>
                        <Button
                            variant='text'
                            label='Ontgrendel alle stopnummers'
                            icon='mdi mdi-lock-open-variant-outline'
                            disabled={route?.loading}
                            onClick={() => OrderActions.setRouteLocked(route._id, false)}
                        />
                    </FlexRow>
                }

                <FlexColumn style={{flex: '1 0px', marginTop: 6}}>

                    <SortableList
                        items={[...(route?.completedAddresses || []), ...(route?.addresses || [])]}
                        onChange={this.onDragChange.bind(this)}
                        isDisabled={(address) => address.hasArrived || address.locked}
                        renderItem={(address) => {
                            let beforeTimeSlot = false
                            let afterTimeSlot = false

                            if (address.estimatedTimeOfArrival) {
                                afterTimeSlot = moment(address.estimatedTimeOfArrival, 'HH:mm').isAfter(moment(address.endTime, 'HH:mm'))
                            } else if (address.duration) {
                                afterTimeSlot = moment().add(address.duration || 0, 'seconds').isAfter(moment(address.endTime, 'HH:mm'))
                            }

                            if (address.timeOfArrival) {
                                afterTimeSlot = moment(address.timeOfArrival, 'HH:mm').isAfter(moment(address.endTime, 'HH:mm'))
                            }

                            if (!afterTimeSlot) {
                                if (address.estimatedTimeOfArrival) {
                                    beforeTimeSlot = moment(address.estimatedTimeOfArrival, 'HH:mm').isBefore(moment(address.startTime, 'HH:mm'))
                                } else if (address.duration) {
                                    beforeTimeSlot = moment().add(address.duration || 0, 'seconds').isBefore(moment(address.startTime, 'HH:mm'))
                                }

                                if (address.timeOfArrival) {
                                    beforeTimeSlot = moment(address.timeOfArrival, 'HH:mm').isBefore(moment(address.startTime, 'HH:mm'))
                                }
                            }

                            const inTimeSlot = !beforeTimeSlot && !afterTimeSlot

                            let diff
                            let formattedDiff = ''

                            if (address.timeOfArrival && address.plannedTimeOfArrival) {
                                diff = moment(address.timeOfArrival, 'HH:mm').diff(moment(address.plannedTimeOfArrival, 'HH:mm'), 'seconds')
                                formattedDiff = formatDuration(diff, true)
                            }

                            let formattedDuration = ''

                            if (address.duration && !address.estimatedTimeOfArrival) {
                                formattedDuration = formatDuration(address.duration)
                            }

                            return (
                                <Card
                                    shadow='true'
                                    style={{display: 'flex', alignItems: 'center', marginLeft: 3, marginRight: 3, padding: 0, marginBottom: 6}}
                                    onClick={() => viewOrder(address.orderId, address.index)}
                                >
                                    <FlexColumn style={{width: 36, alignItems: 'center', justifyContent: 'space-between'}}>
                                        {address.stopNumber &&
                                            <S1>{address.stopNumber}</S1>
                                        }

                                        {!address.hasArrived && address.stopNumber &&
                                            <IconButton
                                                icon={address.locked ? 'mdi mdi-lock' : 'mdi mdi-lock-open-variant-outline'}
                                                onClick={async (event) => {
                                                    event.stopPropagation()

                                                    if (route.ready && address.locked) {
                                                        await this.popup.open('Route wijzigen', 'Je ontgrendeld stopnummers van een route die gereed is. Weet je zeker dat je de route wil wijzigen?')

                                                        await new Promise((resolve) => {
                                                            OrderActions.setRouteReady(route._id, false, (err) => {
                                                                if (err) {
                                                                    this.popup.setError(err)
                                                                } else {
                                                                    resolve()
                                                                }
                                                            })
                                                        })
                                                    }

                                                    OrderActions.setAddressLocked(address.orderId, address.index, !address.locked, (err) => {
                                                        if (err) {
                                                            this.popup.setError(err)
                                                        } else {
                                                            this.popup.close()
                                                        }
                                                    })
                                                }}
                                            />
                                        }
                                    </FlexColumn>

                                    <div
                                        style={{
                                            background: Colors[address.type],
                                            opacity: address.isExpected ? 0.5 : 1,
                                            width: 4,
                                            height: 69
                                        }}
                                    />

                                    <FlexColumn style={{overflow: 'hidden', paddingLeft: 6, paddingRight: 6}}>
                                        <FlexRow style={{alignItems: 'center', height: 24}}>

                                            <Caption ellipsis style={{flex: 1}}>{address.senderAddress?.name || address.customerAddress?.name}</Caption>

                                            {!address.hasArrived &&
                                                <Caption ellipsis>{`${address.startTime} - ${address.endTime}`}</Caption>
                                            }

                                            {!address.hasArrived && formattedDuration &&
                                                <>
                                                    <Icon
                                                        size='md'
                                                        style={{marginLeft: 24, color: afterTimeSlot ? Colors.errorBright : undefined}}
                                                        icon={inTimeSlot ? 'mdi mdi-timer-sand' : 'mdi mdi-timer-sand-complete'}
                                                    />
                                                    <P>{formattedDuration}</P>
                                                </>
                                            }

                                            {!address.hasArrived && address.estimatedTimeOfArrival &&
                                                <ToolTip text='Verwachte aankomsttijd' tooltipStyle={{marginLeft: -127}}>
                                                    <FlexRow style={{marginLeft: 24}}>
                                                        <Icon
                                                            size='md'
                                                            style={{color: afterTimeSlot ? Colors.errorBright : beforeTimeSlot ? Colors.warningBright : undefined}}
                                                            icon={inTimeSlot ? 'mdi mdi-clock-outline' : 'mdi mdi-clock-alert-outline'}
                                                        />
                                                        <P>{address.estimatedTimeOfArrival}</P>
                                                    </FlexRow>
                                                </ToolTip>
                                            }

                                            {address.hasArrived &&
                                                <ToolTip text='Aankomsttijd'>
                                                    <FlexRow style={{marginLeft: 24}}>
                                                        <Icon
                                                            size='md'
                                                            style={{color: afterTimeSlot ? Colors.errorBright : beforeTimeSlot ? Colors.warningBright : undefined}}
                                                            icon={inTimeSlot ? 'mdi mdi-clock-check-outline' : 'mdi mdi-clock-alert-outline'}
                                                        />

                                                        <P>{address.timeOfArrival}</P>
                                                    </FlexRow>
                                                </ToolTip>
                                            }

                                            {formattedDiff &&
                                                <ToolTip text='Afwijking ETA' tooltipStyle={{marginLeft: -70}}>
                                                    <P style={{marginLeft: 24}}>{formattedDiff}</P>
                                                </ToolTip>
                                            }
                                        </FlexRow>

                                        <FlexRow style={{alignItems: 'center', height: 24}}>
                                            {!address.position &&
                                                <Icon size='md' icon='mdi mdi-alert' />
                                            }

                                            <P ellipsis style={{flex: 1}}>{`${address.street} ${address.nr}${address.addition} ${address.postalCode} ${address.city}`}</P>

                                            {address.hasArrived &&
                                                <Icon
                                                    size='sm'
                                                    style={{width: 24, color: address.success ? Colors.successBright : Colors.warningBright}}
                                                    icon='mdi mdi-circle'
                                                />
                                            }
                                        </FlexRow>

                                        <FlexRow style={{alignItems: 'center', height: 21}}>
                                            <Icon size='md' style={{color: Colors.textMedium}} icon={address.isBusiness ? 'mdi mdi-store-outline' : 'mdi mdi-account'} />
                                            <Caption ellipsis style={{flex: 1}}>{address.name}</Caption>

                                        </FlexRow>
                                    </FlexColumn>
                                </Card>
                            )
                        }}
                    />
                </FlexColumn>

                <Popup ref={(ref) => this.popup = ref} />
            </FlexColumn>
        )
    }
}

export default withVehiclesHook(RoutePanel)
