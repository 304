import React from 'react'


import {Colors} from './index.js'

class HTMLEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {focused: false}
    }

    onClickToolbar(command, value, event) {
        if (command == 'h1' || command == 'h2' || command == 'p') {
            document.execCommand('formatBlock', false, command)
        } else if (command == 'createlink') {
            const url = prompt('Vul hieronder de link in: ', 'https://')
            document.execCommand(command, false, url)
        } else {
            document.execCommand(command, false, null)
        }
    }

    onFocus() {
        this.setState({focused: true})
    }

    onBlur() {
        this.setState({focused: false})
        this.props.onChange({target: {value: this.editor.innerHTML}})
    }

    render() {
        const {style, value} = this.props
        const {focused} = this.state

        const defaultStyle = {minHeight: 200, height: 'auto', width: 560, marginBottom: 12}

        style && Object.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                <div style={{display: 'flex'}}>
                    <a href='#' key='bold' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'bold')}><i className='mdi mdi-format-bold'/></a>
                    <a href='#' key='italic' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'italic')}><i className='mdi mdi-format-italic'/></a>
                    <a href='#' key='underline' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'underline')}><i className='mdi mdi-format-underline'/></a>
                    <a href='#' key='link' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'createlink')}><i className='mdi mdi-link-variant'/></a>
                    <a href='#' key='h1' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'h1')}><i className='mdi mdi-format-header-1'/></a>
                    <a href='#' key='h2' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'h2')}><i className='mdi mdi-format-header-2'/></a>
                    <a href='#' key='h3' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'h3')}><i className='mdi mdi-format-header-3'/></a>
                    <a href='#' key='p' style={toolbarStyle} onClick={this.onClickToolbar.bind(this, 'p')}><i className='mdi mdi-format-paragraph'/></a>
                </div>


                <div
                    contentEditable={true}
                    style={{fontSize: 16, color: Colors.textDark, paddingLeft: 10, paddingRight: 10, outline: 'none', borderWidth: 1, borderStyle: 'solid', borderColor: focused ? Colors.buttonSolid : Colors.grey20}}
                    dangerouslySetInnerHTML={{__html: value}}
                    onFocus={this.onFocus.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    ref={(ref) => this.editor = ref}
                />
            </div>
        )
    }
}

const toolbarStyle ={padding: 5, fontSize: 18, color: Colors.textDark, cursor: 'pointer', border: '1px solid white'}


export default (HTMLEditor)
