import React from 'react'

import moment from 'moment'
import aSync from 'async'
import _ from 'underscore'
import {parse} from 'csv-parse/browser/esm'
import jschardet from 'jschardet'
import FileSaver from 'file-saver'

import LoginActions from '../../actions/LoginActions.js'
import OrderActions from '../../actions/OrderActions.js'

import {Alert, Button, Colors, Modal, S2, ProgressBar, Link, Row, Column, Input, Toggle, H5, P, H6, TextArea, DatePicker, Card} from '../UI/index.js'
import {Select, TimePicker, CurrencyInput} from '../UI/index.js'
import NewOrder from '../../../server/functions/orders/NewOrder.js'
import splitAddress from '../../utils/splitAddress.js'
import EditAddress from './EditAddress.js'

export default class ImportOrders extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            customerId: '',
            contact: '',
            email: '',
            phone: '',
            date: moment().format('YYYY-MM-DD'),
            notes: '',
            startTime: '',
            endTime: '',
            priceTableId: '',
            feeIds: [],
            price: '0,00',
            reference: '',
            pickupOptions: [],
            deliveryOptions: [],
            showAdministration: false,
            showMoreOptions: false,
            nrOfDeliveries: 0,
            autoRetour: false,
            nrOfDaysBeforeRetour: 7,
            retourAddress: {
                startTime: '09:00',
                endTime: '17:00',
                name: '',
                attention: '',
                street: '',
                nr: '',
                addition: '',
                street2: '',
                postalCode: '',
                city: '',
                country: 'NL',
                instructions: '',
                gpsRequired: false,
                signatureRequired: false,
                signatureNeighbors: false,
                statedAddressOnly: false,
                nameRequired: false,
                scanRequired: false,
                imageRequired: false,
                mailboxDelivery: false,
                minimumAge: false,
                idCheck: false
            },
            orderType: 'delivery',
            ignoreFirstLine: false,
            addColloWithBarcode: false,
            columns: [],
            lines: [],
            customerInvalid: false,
            loading: false,
            progressNow: 0,
            progressMax: null,
            message: '',
            errors: [],
            errorLines: []
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open() {
        const {user} = this.props

        let {customerId, columns} = this.state

        if (user.isCustomer) {
            customerId = user.name

            if (user.settings.orders?.csvColumns) {
                columns = [...user.settings.orders.csvColumns]
            }
        }


        this.setState({modalIsOpen: true, customerId, columns})
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeCustomer(event) {
        let {columns, priceTableId} = this.state
        const {reseller, customers} = this.props

        const customer = customers[event.target.value]

        if (customer) {
            const contactObject = {name: '', email: '', phone: ''}

            if (reseller.settings.orders.csvColumns[customer._id]) {
                const customerColumns = [...reseller.settings.orders.csvColumns[customer._id]]

                customerColumns.map((value, index) => {
                    return columns[index] = value
                })
            }

            const customerId = customer._id || ''
            const contact = contactObject.name || ''
            const email = customer.email || ''
            const phone = customer.phone || ''

            if (customer.settings.orders.defaultPriceTable) {
                priceTableId = customer.settings.orders.defaultPriceTable
            }

            this.setState({customerId, contact, email, phone, priceTableId, columns, customerInvalid: false})
        }
    }

    onChangeContact(event) {
        let {customerId, email, phone} = this.state
        const {customers} = this.props
        const customer = customers[customerId]


        const contact = event.target.value

        const contactObject = _.find(customer?.contacts, {name: contact})


        if (contactObject) {
            email = contactObject.email || ''
            phone = contactObject.phone || ''
        }

        this.setState({contact, email, phone})
    }

    onChangePricetableId(event) {
        const {reseller} = this.props
        const priceTable = _.find(reseller.settings.orders.prices, {id: event.target.value})

        this.setState({priceTableId: priceTable?.id || '', feeIds: [], price: '0,00'})
    }


    onClickOpenFile(event) {
        event.preventDefault()
        document.getElementById('csv').value = null
        document.getElementById('csv').click()
    }

    onLoadFile() {
        const file = document.getElementById('csv').files[0]

        if (file) {
            const reader = new FileReader()
            reader.readAsBinaryString(file)

            reader.onerror = (event) => {
                if (event.target.error.name == 'NotReadableError') {
                    alert('Can\'t read file !')
                }
            }
            reader.onload = (event) => {
                const encoding = jschardet.detect(event.target.result, {minimumThreshold: 0}).encoding
                const reader = new FileReader()
                reader.readAsText(file, encoding)

                reader.onerror = (event) => {
                    if (event.target.error.name == 'NotReadableError') {
                        alert('Can\'t read file !')
                    }
                }
                reader.onload = (event) => {
                    let delimiter = ';'
                    let commaCount = 0
                    let semicolonCount = 0
                    let tabCount = 0

                    event.target.result.split('').slice(0, 500).map((char) => {
                        commaCount += char === ',' ? 1: 0
                        semicolonCount += char === ';' ? 1: 0
                        tabCount += char === '\t' ? 1: 0
                    })

                    if (commaCount > tabCount && commaCount > semicolonCount) {
                        delimiter =','
                    } else if (tabCount > semicolonCount) {
                        delimiter = '\t'
                    }

                    parse(event.target.result, {delimiter, relax_column_count: true, skip_empty_lines: true, trim: true}, (err, lines) => {
                        if (err) {
                            alert('Geen geldig .csv bestand')
                        } else {
                            const {columns} = this.state

                            let nrOfColumns = 0

                            const nonEmptyLines = []

                            lines.map((line) => {
                                if (line.length > nrOfColumns) {
                                    nrOfColumns = line.length
                                }

                                if (line.join('') !== '') {
                                    nonEmptyLines.push(line)
                                }
                            })

                            const columnDiff = nrOfColumns - columns.length
                            if (columnDiff > 0) {
                                for (let i = 0; i < columnDiff; i++) {
                                    columns.push('')
                                }
                            } else {
                                columns.splice(nrOfColumns)
                            }

                            this.setState({lines: nonEmptyLines, columns, error: ''})
                        }
                    })
                }
            }
        }
    }

    onChangeColumn(index, event) {
        const {columns} = this.state
        columns[index] = event.target.value
        this.setState({columns})
    }

    onClickCreateOrders() {
        const {lines, columns, customerId, contact, email, phone, startTime, endTime, priceTableId, feeIds, price, pickupOptions, deliveryOptions, orderType, ignoreFirstLine, addColloWithBarcode} = this.state
        const {reseller, user, customers, addressBook} = this.props
        const customer = customers[customerId]
        const customerFromCSV = columns.indexOf('customer') > -1 || columns.indexOf('debtorCode') > -1

        if (!customer && !customerFromCSV) {
            this.setState({customerInvalid: true})
            this.selectCustomer.focus()
            return
        }

        let success = 0
        const errors = []
        const errorLines = []

        this.setState({loading: true, progressMax: orderType === 'multiOrder' ? 1 : ignoreFirstLine ? lines.length - 1 : lines.length, progressNow: orderType === 'multiOrder' ? 0 : 1})


        if (ignoreFirstLine) {
            lines.shift()
        }

        let order
        if (orderType === 'multiOrder') {
            order = NewOrder(reseller)
        }

        aSync.eachOfSeries(lines, (line, index, next) => {
            if (this.state.stopOperation) return
            if (orderType !== 'multiOrder') {
                order = NewOrder(reseller)
            }

            order.date = this.state.date
            order.customer = customer?._id
            order.contact = contact
            order.email = email
            order.phone = phone
            order.notes = this.state.notes


            if (priceTableId) {
                order.priceTableId = priceTableId
                order.feeIds = feeIds
            } else {
                order.price = price
            }

            order.reference = this.state.reference

            order.nrOfDeliveries = this.state.nrOfDeliveries

            order.autoRetour = this.state.autoRetour

            order.allowAddCollo = customer.settings.orders.allowAddCollo === 'default' ?
                (reseller.settings.allowAddCollo || '') :
                (customer.settings.orders.allowAddCollo || '')

            if (order.autoRetour) {
                order.nrOfDaysBeforeRetour = this.state.nrOfDaysBeforeRetour
                order.retourAddress = this.state.retourAddress
            }

            if (orderType !== 'multiOrder') {
                order.addresses = order.addresses.map((address) => {
                    if (startTime) {
                        address.startTime = startTime
                    }
                    if (endTime) {
                        address.endTime = endTime
                    }

                    if (address.type === 'pickup') {
                        pickupOptions.map((option) => {
                            address[option] = true
                        })
                    }

                    if (address.type === 'delivery') {
                        deliveryOptions.map((option) => {
                            if (option === 'minimumAge +16') {
                                address.minimumAge = 16
                            } else if (option === 'minimumAge +18') {
                                address.minimumAge = 18
                            } else if (option === 'minimumAge +21') {
                                address.minimumAge = 21
                            } else {
                                address[option] = true
                            }
                        })
                    }

                    return address
                })
            } else if (index === 0) {
                order.addresses = []
            }

            if (orderType === 'multiOrder') {
                const newAddress = {
                    type: 'delivery',
                    name: '',
                    attention: '',
                    street: '',
                    nr: '',
                    addition: '',
                    street2: '',
                    postalCode: '',
                    city: '',
                    country: 'NL',
                    isBusiness: false,
                    instructions: '',
                    email: '',
                    phone: '',
                    gpsRequired: false,
                    signatureRequired: false,
                    signatureNeighbors: false,
                    statedAddressOnly: false,
                    nameRequired: false,
                    scanRequired: false,
                    imageRequired: false,
                    mailboxDelivery: false,
                    minimumAge: false,
                    idCheck: false,
                    startTime: startTime || reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                        reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].from :
                        moment().format('HH:mm'),
                    endTime: endTime || reseller?.settings?.account?.openingHours[moment().format('dddd').toLowerCase()] ?
                        reseller.settings.account.openingHours[moment().format('dddd').toLowerCase()].till :
                        moment().format('HH:mm')
                }
                order.addresses.push(newAddress)
            }

            line.map((value, lineIndex) => {
                if (columns[lineIndex]) {
                    const key = columns[lineIndex].split('.')

                    value = value.replace(/"/g, '')

                    if (key[0] === 'addresses' && key[2]) {
                        let addressIndex = parseInt(key[1])

                        if (orderType === 'multiOrder') {
                            addressIndex = order.addresses.length - 1
                        }

                        if (key[2] === 'name') {
                            order.addresses[addressIndex].name = `${order.addresses[addressIndex].name || ''} ${value}`.trim()
                        } else if (key[2] === 'attention') {
                            order.addresses[addressIndex].attention = `${order.addresses[addressIndex].attention || ''} ${value}`.trim()
                        } else if (key[2] === 'street') {
                            order.addresses[addressIndex].street = `${order.addresses[addressIndex].street || ''} ${value}`.trim()
                        } else if (key[2] === 'streetNr') {
                            const address = splitAddress(value)
                            order.addresses[addressIndex].street = address.street
                            order.addresses[addressIndex].nr = address.nr
                            order.addresses[addressIndex].addition = address.addition
                        } else if (key[2] === 'nrAddition') {
                            const address = splitAddress(`straat ${value}`)
                            order.addresses[addressIndex].nr = address.nr
                            order.addresses[addressIndex].addition = address.addition
                        } else if (key[2] === 'street2') {
                            order.addresses[addressIndex].street2 = `${order.addresses[addressIndex].street2 || ''} ${value}`.trim()
                        } else if (key[2] === 'postalCode') {
                            order.addresses[addressIndex].postalCode = `${order.addresses[addressIndex].postalCode || ''} ${value.toUpperCase().replace(/  +/g, ' ')}`.trim()

                            if (/^[0-9]{4}[A-Z]{2}$/.test(order.addresses[addressIndex].postalCode)) {
                                order.addresses[addressIndex].postalCode = `${order.addresses[addressIndex].postalCode.substr(0, 4)} ${order.addresses[addressIndex].postalCode.substr(4, 2)}`
                            }
                        } else if (key[2] === 'country') {
                            order.addresses[addressIndex].country = value.toUpperCase().trim()

                            if (order.addresses[addressIndex].country === 'NLD') {
                                order.addresses[addressIndex].country = 'NL'
                            }
                        } else if (key[2] === 'position') {
                            order.addresses[addressIndex].position = order.addresses[addressIndex].position || {lat: null, lng: null}
                            order.addresses[addressIndex].position.lat = parseFloat(value.split(',')[0])
                            order.addresses[addressIndex].position.lng = parseFloat(value.split(',')[1])
                        } else if (key[2] === 'lat') {
                            order.addresses[addressIndex].position = order.addresses[addressIndex].position || {lat: null, lng: null}
                            order.addresses[addressIndex].position.lat = parseFloat(value)
                        } else if (key[2] === 'lng') {
                            order.addresses[addressIndex].position = order.addresses[addressIndex].position || {lat: null, lng: null}
                            order.addresses[addressIndex].position.lng = parseFloat(value)
                        } else if (key[2] === 'signatureRequired' || key[2] === 'statedAddressOnly' || key[2] === 'mailboxDelivery') {
                            order.addresses[addressIndex][key[2]] = !!value
                        } else if (key[2] === 'instructions') {
                            order.addresses[addressIndex].instructions = `${order.addresses[addressIndex].instructions || ''} ${value}`.trim()
                        } else if (key[2] === 'addressTypePickup' && !!value) {
                            order.addresses[addressIndex].type = 'pickup'
                            order.addresses[addressIndex] = _.omit(order.addresses[addressIndex], ['gpsRequired', 'signatureRequired', 'signatureNeighbors', 'statedAddressOnly', 'nameRequired', 'scanRequired', 'imageRequired', 'mailboxDelivery', 'minimumAge', 'idCheck'])
                        } else {
                            order.addresses[addressIndex][key[2]] = value.trim()
                        }
                    }


                    if (key.length === 1) {
                        if (key[0] === 'debtorCode') {
                            const csvCustomer = _.find(customers, (customer) => customer.debtorCode.toLowerCase() === value.trim().toLowerCase())

                            if (csvCustomer) {
                                order.customer = csvCustomer._id
                                order.contact = ''
                            } else {
                                order.customer = ''
                                order.customerId = value
                            }
                        } else if (key[0] === 'customer') {
                            const csvCustomer = _.find(customers, (customer) => customer.name.toLowerCase() === value.trim().toLowerCase())

                            if (csvCustomer) {
                                order.customer = csvCustomer._id
                                order.contact = ''
                            } else {
                                order.customer = ''
                                order.customerId = value
                            }
                        } else if (key[0] === 'date') {
                            if (/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(value)) {
                                order.date = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD')
                            } else {
                                order.date = value
                            }
                        } else if (key[0] === 'price') {
                            if (value.trim()) {
                                order.price = value.trim()
                            } else if (price) {
                                order.price = price
                            } else {
                                order.price = value.trim() // this will trigger the error "price is not allowed to be empty"
                            }
                        } else if (key[0] === 'reference') {
                            order.reference = `${order.reference || ''} ${value}`.trim()
                        } else if (key[0] === 'notes') {
                            order.notes = `${order.notes || ''} ${value}`.trim()
                        } else {
                            order[key[0]] = value.trim()
                        }
                    }

                    if (key.length === 2 && value) {
                        order[key[0]] = order[key[0]] || {}
                        order[key[0]][key[1]] = value.trim()
                    }
                }
            })

            if (orderType === 'pickup') {
                order.addresses.splice(1, 1)
            }
            if (orderType === 'delivery') {
                order.addresses.splice(0, 1)
            }

            order.addresses = order.addresses.map((address) => {
                const bookAddress = addressBook.find((bookAddress) => address.name === bookAddress.name && address.street === bookAddress.street && address.nr === bookAddress.nr && address.addition === bookAddress.addition && address.postalCode === bookAddress.postalCode && address.city === bookAddress.city && address.country === bookAddress.country)

                if (bookAddress) {
                    address.isBusiness = !!bookAddress.isBusiness

                    if (bookAddress?.position) {
                        address.position = bookAddress.position
                    }

                    if (!address.instructions && bookAddress?.instructions) {
                        address.instructions = bookAddress.instructions
                    }

                    if (bookAddress?.attachments) {
                        address.attachments = bookAddress.attachments || []
                    }
                }

                return address
            })

            if (order.content || order.barcode || addColloWithBarcode) {
                order.colli = [{
                    description: order.content?.description || '',
                    weight: order.content?.weight || 0,
                    length: order.content?.length || 0,
                    width: order.content?.width || 0,
                    height: order.content?.height || 0,
                    barcode: (order.barcode || '').toUpperCase() || ''
                }]

                const quantity = order.content?.quantity || 1

                if (quantity > 1) {
                    for (let i = 1; i < quantity; i++) {
                        order.colli.push({
                            type: '',
                            description: '',
                            weight: 0,
                            length: 0,
                            width: 0,
                            height: 0,
                            barcode: ''
                        })
                    }
                }
            }

            if (!order.customer) {
                this.setState({progressNow: this.state.progressNow+1})
                errors.push(`Regel ${index+1}: Klant ${order.customerId} kan niet worden gevonden.`)
                errorLines.push(`${line.join(';')};Klant ${order.customerId} kan niet worden gevonden.`)
                next()
            } else {
                if (orderType !== 'multiOrder') {
                    OrderActions.create(order, (err) => {
                        this.setState({progressNow: this.state.progressNow+1})

                        if (err) {
                            errors.push(`Regel ${index+1}: ${err}`)
                            errorLines.push(`${line.join(';')};${err.replace(/"/g, '')}`)
                        } else {
                            success += 1
                        }

                        next()
                    })
                } else {
                    next()
                }
            }
        }, () => {
            if (orderType === 'multiOrder') {
                if (this.state.stopOperation) return
                OrderActions.create(order, (err) => {
                    this.setState({progressNow: this.state.stopOperation ? this.state.progressMax : 1})

                    if (err) {
                        errors.push(`${err}`)
                        errorLines.push(`${err.replace(/"/g, '')}`)
                    } else {
                        success += 1
                        const message = `${success} nieuwe ${success === 1 ? 'rit' : 'ritten'} aangemaakt.`
                        this.setState({loading: false, message, errors, errorLines})
                    }
                })
            } else {
                const message = `${success} nieuwe ${success === 1 ? 'rit' : 'ritten'} aangemaakt.`
                this.setState({loading: false, message, errors, errorLines})
            }
        })

        if (user.isCustomer) {
            user.settings.orders.csvColumns = columns
            LoginActions.updateUserSettings(user)
        } else if (customer) {
            reseller.settings.orders.csvColumns[customer._id] = columns
            LoginActions.updateResellerSettings('orders.csvColumns', reseller.settings.orders.csvColumns)
        }
    }

    onClickDownloadErrors() {
        const {errorLines} = this.state
        const blob = new Blob([errorLines.join('\n')], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, 'foutieve_ritten.csv')
    }

    render() {
        const {modalIsOpen, customerId, contact, email, phone, startTime, endTime, priceTableId, feeIds, price, pickupOptions, deliveryOptions, orderType, ignoreFirstLine, addColloWithBarcode, columns, lines, customerInvalid, loading, progressNow, progressMax, message, errors, errorLines} = this.state
        const {reseller, user, customers} = this.props

        const customer = customers[customerId]
        const priceTable = _.find(reseller.settings.orders.prices, {id: priceTableId})


        return (
            <Modal
                style={{width: 'auto', minWidth: 800, maxWidth: '90vw'}}
                closeButton
                title='Ritten importeren'
                show={modalIsOpen} onClose={this.close.bind(this)}
            >

                {!user.isCustomer && !progressMax &&
                <>
                    <H5>Klantgegevens</H5>

                    <Row style={{width: 788}}>
                        <Column>
                            <Select
                                openAfter={1}
                                label='Klant'
                                options={_.values(customers).filter((customer) => !customer.isArchived).map((customer) => ({value: customer._id, title: customer.name}))}
                                value={customerId}
                                isInvalid={customerInvalid}
                                onChange={this.onChangeCustomer.bind(this)}
                                ref={(ref) => this.selectCustomer = ref}
                            />

                            <Input
                                id='contact'
                                label='Contactpersoon'
                                suggestions={customer ? customer.contacts?.map((contact) => contact.name) : []}
                                suggestionFilter
                                value={contact}
                                onChange={this.onChangeContact.bind(this)}
                            />
                        </Column>

                        <Column>
                            <DatePicker
                                label='Datum'
                                value={this.state.date}
                                onChange={(event) => this.setState({date: event.target.value})}
                            />
                        </Column>
                    </Row>

                    <Row style={{width: 788}}>
                        <Column>
                            <Input
                                type='email '
                                label='Emailadres'
                                value={email}
                                onChange={(event) => this.setState({email: event.target.value})}
                            />

                            <Input
                                type='phone'
                                label='Telefoon'
                                value={phone}
                                onChange={(event) => this.setState({phone: event.target.value})}
                            />
                        </Column>

                        <Column>
                            <TextArea
                                label='Opmerkingen'
                                value={this.state.notes}
                                onChange={(event) => this.setState({notes: event.target.value})}
                            />
                        </Column>
                    </Row>
                </>
                }

                { !progressMax &&
                <>
                    <H5 style={{marginTop: 36}}>Adressen</H5>

                    <Row style={{width: 788}}>
                        <Column>
                            <Select
                                label='Type rit'
                                value={orderType}
                                onChange={(event) => this.setState({orderType: event.target.value})}
                            >
                                <option value='delivery'>Alleen bezorgen</option>
                                <option value='pickup'>Alleen ophalen</option>
                                <option value='pickupDelivery'>Ophalen en bezorgen</option>
                                <option value='multiOrder'>Multirit, addressen binnen één rit</option>
                            </Select>
                        </Column>
                        <Column>
                            <TimePicker
                                label="Starttijd"
                                time={startTime}
                                onChange={(event) => this.setState({startTime: event.target.value})}
                            />
                            <TimePicker
                                label='Eindtijd'
                                time={endTime}
                                onChange={(event) => this.setState({endTime: event.target.value})}
                            />

                        </Column>
                    </Row>


                    <Row style={{width: 788}}>
                        <Column>
                            <Select
                                label='Ophaalopties'
                                values={pickupOptions}
                                disabled={orderType === 'delivery'}
                                options={[
                                    {value: 'gpsRequired', title: 'GPS verplicht'},
                                    {value: 'scanRequired', title: 'Scan verplicht'},
                                    {value: 'imageRequired', title: 'Foto verplicht'}
                                ]}
                                onChange={(event) => this.setState({pickupOptions: event.target.values})}
                            />
                        </Column>

                        <Column>
                            <Select
                                label='Bezorgopties'
                                values={deliveryOptions}
                                disabled={orderType === 'pickup'}
                                options={[
                                    {value: 'gpsRequired', title: 'GPS verplicht'},
                                    {value: 'scanRequired', title: 'Scan verplicht'},
                                    {value: 'imageRequired', title: 'Foto verplicht'},
                                    {value: 'signatureRequired', title: 'Handtekening'},
                                    {value: 'signatureNeighbors', title: 'Handtekening buren'},
                                    {value: 'nameRequired', title: 'Naam verplicht'},
                                    {value: 'statedAddressOnly', title: 'Niet bij buren'},
                                    {value: 'minimumAge +16', title: 'Leeftijdscheck +16'},
                                    {value: 'minimumAge +18', title: 'Leeftijdscheck +18'},
                                    {value: 'minimumAge +21', title: 'Leeftijdscheck +21'},
                                    {value: 'idCheck', title: 'ID check'},
                                    {value: 'mailboxDelivery', title: 'Brievenbus'}
                                ]}
                                onChange={(event) => this.setState({deliveryOptions: event.target.values})}
                            />
                        </Column>
                    </Row>
                    <Row style={{width: 788}}>
                        <Column>
                            <Toggle
                                label='Collo met barcode toevoegen aan adressen'
                                checked={addColloWithBarcode}
                                onChange={(event) => this.setState({addColloWithBarcode: event.target.checked})}
                            />
                        </Column>
                        <Column/>
                    </Row>

                    <Row style={{width: 788}}>
                        <Column>
                            <Toggle
                                label='Negeer 1e regel van CSV bestand'
                                checked={ignoreFirstLine}
                                onChange={(event) => this.setState({ignoreFirstLine: event.target.checked})}
                            />
                        </Column>
                        <Column/>
                    </Row>

                    {!!lines.length && !progressMax &&
                    <>
                        <div style={{minHeight: 500, maxHeight: 500, overflow: 'auto'}}>
                            <Row style={{flexShrink: 0, width: 'max-content'}}>
                                {columns.map((value, index) => {
                                    const possibleColumnsWithAddresses = {...possibleColumns}

                                    if (orderType === 'pickup' || orderType === 'pickupDelivery') {
                                        possibleColumnsWithAddresses['addresses.0.name'] = 'Ophaaladres: Naam'
                                        possibleColumnsWithAddresses['addresses.0.attention'] = 'Ophaaladres: T.a.v.'
                                        possibleColumnsWithAddresses['addresses.0.street'] = 'Ophaaladres: Straat'
                                        possibleColumnsWithAddresses['addresses.0.nr'] = 'Ophaaladres: Huisnummer'
                                        possibleColumnsWithAddresses['addresses.0.addition'] = 'Ophaaladres: Toevoeging'
                                        possibleColumnsWithAddresses['addresses.0.streetNr'] = 'Ophaaladres: Straat + Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.0.nrAddition'] = 'Ophaaladres: Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.0.street2'] = 'Ophaaladres: 2e adres regel'
                                        possibleColumnsWithAddresses['addresses.0.postalCode'] = 'Ophaaladres: Postcode'
                                        possibleColumnsWithAddresses['addresses.0.city'] = 'Ophaaladres: Plaats'
                                        possibleColumnsWithAddresses['addresses.0.country'] = 'Ophaaladres: Landcode'
                                        possibleColumnsWithAddresses['addresses.0.position'] = 'Ophaaladres: Coördinaten'
                                        possibleColumnsWithAddresses['addresses.0.lat'] = 'Ophaaladres: Latitude'
                                        possibleColumnsWithAddresses['addresses.0.lng'] = 'Ophaaladres: Longitude'
                                        possibleColumnsWithAddresses['addresses.0.startTime'] = 'Ophaaladres: Starttijd'
                                        possibleColumnsWithAddresses['addresses.0.endTime'] = 'Ophaaladres: Eindtijd'
                                        possibleColumnsWithAddresses['addresses.0.instructions'] = 'Ophaaladres: Instructies'
                                        possibleColumnsWithAddresses['addresses.0.email'] = 'Ophaaladres: Emailadres'
                                        possibleColumnsWithAddresses['addresses.0.phone'] = 'Ophaaladres: Telefoon'
                                    }

                                    if (orderType === 'delivery' || orderType === 'pickupDelivery') {
                                        possibleColumnsWithAddresses['addresses.1.name'] = 'Bezorgadres: Naam'
                                        possibleColumnsWithAddresses['addresses.1.attention'] = 'Bezorgadres: T.a.v.'
                                        possibleColumnsWithAddresses['addresses.1.street'] = 'Bezorgadres: Straat'
                                        possibleColumnsWithAddresses['addresses.1.nr'] = 'Bezorgadres: Huisnummer'
                                        possibleColumnsWithAddresses['addresses.1.addition'] = 'Bezorgadres: Toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.streetNr'] = 'Bezorgadres: Straat + Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.nrAddition'] = 'Bezorgadres: Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.street2'] = 'Bezorgadres: 2e adres regel'
                                        possibleColumnsWithAddresses['addresses.1.postalCode'] = 'Bezorgadres: Postcode'
                                        possibleColumnsWithAddresses['addresses.1.city'] = 'Bezorgadres: Plaats'
                                        possibleColumnsWithAddresses['addresses.1.country'] = 'Bezorgadres: Landcode'
                                        possibleColumnsWithAddresses['addresses.1.position'] = 'Bezorgadres: Coördinaten'
                                        possibleColumnsWithAddresses['addresses.1.lat'] = 'Bezorgadres: Latitude'
                                        possibleColumnsWithAddresses['addresses.1.lng'] = 'Bezorgadres: Longitude'
                                        possibleColumnsWithAddresses['addresses.1.startTime'] = 'Bezorgadres: Starttijd'
                                        possibleColumnsWithAddresses['addresses.1.endTime'] = 'Bezorgadres: Eindtijd'
                                        possibleColumnsWithAddresses['addresses.1.instructions'] = 'Bezorgadres: Instructies'
                                        possibleColumnsWithAddresses['addresses.1.email'] = 'Bezorgadres: Emailadres'
                                        possibleColumnsWithAddresses['addresses.1.phone'] = 'Bezorgadres: Telefoon'
                                        possibleColumnsWithAddresses['addresses.1.signatureRequired'] = 'Bezorgadres: Handtekening'
                                        possibleColumnsWithAddresses['addresses.1.statedAddressOnly'] = 'Bezorgadres: Niet bij buren'
                                        possibleColumnsWithAddresses['addresses.1.mailboxDelivery'] = 'Bezorgadres: Brievenbuspakket'
                                    }

                                    if (orderType === 'multiOrder') {
                                        possibleColumnsWithAddresses['addresses.1.name'] = 'Naam'
                                        possibleColumnsWithAddresses['addresses.1.attention'] = 'T.a.v.'
                                        possibleColumnsWithAddresses['addresses.1.street'] = 'Straat'
                                        possibleColumnsWithAddresses['addresses.1.nr'] = 'Huisnummer'
                                        possibleColumnsWithAddresses['addresses.1.addition'] = 'Toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.streetNr'] = 'Straat + Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.nrAddition'] = 'Huisnummer + toevoeging'
                                        possibleColumnsWithAddresses['addresses.1.street2'] = '2e adres regel'
                                        possibleColumnsWithAddresses['addresses.1.postalCode'] = 'Postcode'
                                        possibleColumnsWithAddresses['addresses.1.city'] = 'Plaats'
                                        possibleColumnsWithAddresses['addresses.1.country'] = 'Landcode'
                                        possibleColumnsWithAddresses['addresses.1.startTime'] = 'Starttijd'
                                        possibleColumnsWithAddresses['addresses.1.endTime'] = 'Eindtijd'
                                        possibleColumnsWithAddresses['addresses.1.instructions'] = 'Instructies'
                                        possibleColumnsWithAddresses['addresses.1.email'] = 'Emailadres'
                                        possibleColumnsWithAddresses['addresses.1.phone'] = 'Telefoon'
                                        possibleColumnsWithAddresses['addresses.1.addressTypeDelivery'] = 'Type adres: Bezorgadres'
                                        possibleColumnsWithAddresses['addresses.1.addressTypePickup'] = 'Type adres: Ophaaladres'
                                    }

                                    return (
                                        <Select
                                            key={index}
                                            style={{width: 200}}
                                            allowEmptyValue
                                            placeholder='Negeer kolom'
                                            value={value}
                                            onChange={this.onChangeColumn.bind(this, index)}
                                        >
                                            {_.keys(possibleColumnsWithAddresses).map((key) => {
                                                return <option key={key} value={key}>{possibleColumnsWithAddresses[key]}</option>
                                            })}
                                        </Select>
                                    )
                                })}
                            </Row>

                            {lines.map((line, index) => {
                                if ((index > 0 || !ignoreFirstLine) && index < 20) {
                                    return (
                                        <Row
                                            key={index}
                                            style={{
                                                flexShrink: 0,
                                                width: 'max-content',
                                                paddingTop: 6,
                                                paddingBottom: 6,
                                                background: 'white',
                                                borderBottom: '1px solid',
                                                borderColor: Colors.grey40
                                            }}
                                        >
                                            {line.map((value, index) => {
                                                return (
                                                    <Column
                                                        key={index}
                                                        style={{
                                                            width: 212,
                                                            flexShrink: 0
                                                        }}
                                                    >
                                                        <P ellipsis>{value}</P>
                                                    </Column>
                                                )
                                            })}
                                        </Row>
                                    )
                                }
                            })}
                        </div>
                    </>
                    }

                    {!!lines.length && !progressMax &&
                        <Row style={{justifyContent: 'flex-end', marginTop: 6}}>
                            <S2>{`${ignoreFirstLine ? lines.length - 1 : lines.length} regels`}</S2>
                        </Row>
                    }

                    {!progressMax &&
                        <Button
                            style={{marginTop: 12}}
                            variant='outline-white'
                            label='Selecteer CSV bestand'
                            icon='mdi mdi-folder-open'
                            onClick={this.onClickOpenFile.bind(this)}
                        />
                    }

                    <H5
                        style={{cursor: 'pointer', marginTop: 36, marginBottom: 12}}
                        onClick={() => this.setState({showAdministration: !this.state.showAdministration})}
                    >
                        {'Administratie'}
                        <i className={this.state.showAdministration ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>
                    </H5>

                    {this.state.showAdministration &&
                    <>

                        <Row style={{width: 788}}>
                            <Column>
                                <Select
                                    label='Tarieftabel'
                                    allowEmptyValue
                                    value={priceTableId}
                                    onChange={this.onChangePricetableId.bind(this)}

                                >
                                    {(reseller.settings.orders.prices || []).map((priceTable) => {
                                        return (
                                            <option key={priceTable.id} value={priceTable.id}>{priceTable.name}</option>
                                        )
                                    })}
                                </Select>

                                <Select
                                    style={{flex: 1, marginLeft: 12}}
                                    label='Toeslag'
                                    values={feeIds}
                                    options={_.keys(priceTable?.fees || {}).map((id) => {
                                        const fee = priceTable.fees[id]
                                        return {value: id, title: fee.name}
                                    })}
                                    onChange={(event) => this.setState({feeIds: event.target.values})}
                                    disabled={!priceTableId || !priceTable}
                                />
                            </Column>

                            <Column style={{height: 42}}>
                                <CurrencyInput
                                    style={{marginLeft: 12}}
                                    label='Prijs'
                                    value={price}
                                    onChange={(event) => this.setState({price: event.target.value})}
                                    disabled={priceTableId}
                                />
                            </Column>
                        </Row>

                        <Row style={{width: 788}}>
                            <Column>
                                <Input
                                    label='Referentie'
                                    value={this.state.reference}
                                    onChange={(event) => this.setState({reference: event.target.value})}
                                />
                            </Column>

                            <Column/>

                        </Row>
                    </>
                    }

                    <H5
                        style={{cursor: 'pointer', marginTop: 36, marginBottom: 12}}
                        onClick={() => this.setState({showOtherOptions: !this.state.showOtherOptions})}
                    >
                        {'Overige opties'}
                        <i className={this.state.showOtherOptions ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'}/>
                    </H5>

                    {this.state.showOtherOptions &&
                    <>

                        {!reseller.isShop &&
                        <Row style={{width: 788}}>
                            <Column>
                                <Input
                                    label='Aantal bezorgpogingen'
                                    info='Ritten worden automatisch verplaatst naar de volgende werkdag na een mislukte bezorgpoging tot het maximaal aantal bezorgpogingen is bereikt. Vul 0 in om ritten nooit automatisch te verplaatsen.'
                                    type='number'
                                    min={0}
                                    value={this.state.nrOfDeliveries}
                                    onChange={(event) => this.setState({nrOfDeliveries: parseInt(event.target.value)})}
                                />
                            </Column>

                            <Column>
                                <Toggle
                                    label='Automatisch retour'
                                    checked={this.state.autoRetour}
                                    onChange={(event) => this.setState({autoRetour: event.target.checked})}
                                />
                            </Column>
                        </Row>
                        }

                        <Row style={{width: 788}}>
                            <Column>
                                {!reseller.isShop && this.state.autoRetour && this.state.nrOfDeliveries > 0 &&
                                <Input
                                    label='Bewaartijd'
                                    info='Hoeveel dagen blijft de rit in bewaring na de laatste bezorgpoging. Vul 0 in om na de laatste bezorgpoging meteen een retourrit aan te maken.'
                                    type='number'
                                    value={this.state.nrOfDaysBeforeRetour}
                                    onChange={(event) => this.setState({nrOfDaysBeforeRetour: event.target.value})}
                                />
                                }
                            </Column>

                            <Column/>
                        </Row>

                        {!reseller.isShop && this.state.autoRetour &&
                            <Row style={{width: 788}}>
                                <Column style={{flexDirection: 'column'}}>
                                    <H6>Retouradres</H6>

                                    <Card style={{width: '100%', background: Colors.grey20}}>
                                        <div style={{height: 10}}/>
                                        <EditAddress
                                            id='address-retour'
                                            address={this.state.retourAddress}
                                            onChange={(address) => this.setState({retourAddress: address})}
                                            allowAttachments={true}
                                            ref={(ref) => this.retourAddress = ref}
                                        />
                                    </Card>

                                </Column>
                            </Row>
                        }
                    </>
                    }
                </>
                }

                <br/>
                <br/>
                {progressMax &&
                    <ProgressBar now={progressNow} max={progressMax}/>
                }

                {message &&
                    <Alert variant='success'>{message}</Alert>
                }

                {errors.length > 0 &&
                    <div style={{maxHeight: 300, overflow: 'auto'}}>
                        {errors.map((error, index) => {
                            return <Alert key={`error${index}`} variant='danger'>{error}</Alert>
                        })}
                    </div>

                }

                <br/>

                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {!!errorLines.length &&
                        <Link
                            style={{flex: 1}}
                            onClick={this.onClickDownloadErrors.bind(this)}
                        >
                            Download foutieve ritten .csv
                        </Link>
                    }

                    {loading &&
                        <Button
                            variant='outline-white'
                            onClick={() => this.setState({stopOperation: true, loading: false, progressNow: progressMax})}
                        >
                            Afbreken
                        </Button>
                    }

                    {progressMax && progressNow >= progressMax ?
                        <Button
                            onClick={this.close.bind(this)}
                        >
                            Sluiten
                        </Button> :
                        <Button
                            loading={loading}
                            disabled={!lines.length}
                            onClick={() => this.setState({stopOperation: false}, this.onClickCreateOrders)}
                        >
                            Importeer
                        </Button>
                    }
                </div>

                <input
                    type='file' id='csv' accept='.csv'
                    style={{visibility: 'hidden', height: 0}}
                    onChange={this.onLoadFile.bind(this)}
                />
            </Modal>

        )
    }
}

const possibleColumns = {
    customer: 'Klant',
    debtorCode: 'Debiteurnummer',
    date: 'Datum',
    price: 'Prijs',
    reference: 'Referentie',
    barcode: 'Barcode',
    notes: 'Opmerkingen',
    nrOfDeliveries: 'Aantal bezorgpogingen',
    'content.quantity': 'Inhoud: Aantal',
    'content.description': 'Inhoud: omschrijving',
    'content.weight': 'Inhoud: Gewicht (g)',
    'content.length': 'Inhoud: Lengte (mm)',
    'content.width': 'Inhoud: Breedte (mm)',
    'content.height': 'Inhoud: Hoogte (mm)'
}

