import React, {useEffect, useRef, useState} from 'react'
import _ from 'underscore'
import {VariableSizeList as List} from 'react-window'

import OrderCard from '../orders/OrderCard.js'
import dbCodes from '../../../server/dbCodes.js'

export default function OrderList({date, filteredOrders, viewOrder}) {
    const listRef = useRef()
    const [height, setHeight] = useState(100)

    const activeOrders = Object.values(filteredOrders).filter((order) => order.handledBy === 'reseller' && order.date === date && !order.routeId && [dbCodes.status.verwacht(), dbCodes.status.inSortering(), dbCodes.status.gereed(), dbCodes.status.onderweg()].includes(order.status))

    const sortedOrders = _.sortBy(activeOrders, (order) => {
        const hasInvalidPosition = order.addresses.some((address) => !address.position)
        if (hasInvalidPosition) {
            return '-1'
        }

        let endTime = '24:00'

        order.addresses.map((address) =>{
            if (address.endTime < endTime) {
                endTime = address.endTime
            }
        })

        return endTime
    })


    const Row = ({index, style}) => {
        const order = sortedOrders[index]

        return (
            <div style={style}>
                <OrderCard
                    key={order._id}
                    order={order}
                    onClick={() => viewOrder(order._id, true)}
                />
            </div>
        )
    }

    const getItemSize = (index) => {
        const order = sortedOrders[index]

        let height = 30 // customer name + padding

        height += order.addresses.length === 1 ? 45 : 90 // pickup and delivery address

        if (order.addresses.length > 2) {
            height += 21
        }

        if (order.notes) {
            height += 21 // notes
        }

        return height
    }

    const containerRef = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setHeight(event[0].contentBoxSize[0].blockSize)
        })

        if (containerRef) {
            resizeObserver.observe(containerRef.current)
        }

        return () => {
            resizeObserver.unobserve(containerRef.current)
        }
    })

    useEffect(() => {
        listRef.current.resetAfterIndex(0, false)
    }, [sortedOrders])

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: '1 0px', overflowY: 'auto'}} ref={containerRef}>
            <List
                height={height}
                itemCount={sortedOrders.length}
                itemSize={getItemSize}
                width={345}
                ref={listRef}
            >
                {Row}
            </List>
        </div>
    )
}

