import {z} from 'zod'

import {AddressModel} from './address.js'

const ParcelProductsModel = z.enum(['Aangetekend', 'Aangetekende brief', 'Afhaalpunt', 'Antwoordnummer', 'Adreslabel', 'Avondlevering', 'Brievenbuspakket', 'Brievenbuspakket zonder Track & Trace', 'Envelop', 'Handtekening voor ontvangst', 'Leeftijdscheck 18+', 'Niet bij buren', 'Pick & Ship', 'Pick & Return', 'Postbus', 'Px', 'Retour', 'Retour brievenbuspakket', 'Retour bij niet thuis', 'Same Day', 'Spoed levering', 'Standaard zending', 'Verspakket', 'Verzekerd', 'Zondaglevering', 'Zonder Track & Trace'])

const PickupPoint = z.object({
    id: z.string(),
    carrier: z.string(),
    description: z.string(),
    distance: z.number(),
    address: z.object({
        name: z.string(),
        street: z.string(),
        nr: z.string(),
        addition: z.string(),
        postalCode: z.string(),
        city: z.string(),
        country: z.string()
    }),
    openingHours: z.object({
        monday: z.string().optional(),
        tuesday: z.string().optional(),
        wednesday: z.string().optional(),
        thursday: z.string().optional(),
        friday: z.string().optional(),
        saturday: z.string().optional(),
        sunday: z.string().optional()
    }).optional(),
    location: z.object({
        lat: z.number(),
        lng: z.number()
    }).optional()
})

const NewParcelModel = z.object({
    date: z.string().optional(),
    customer: z.string(),
    reseller: z.string().optional(),
    resellerId: z.string().optional(),
    createdByCustomer: z.boolean().optional(),
    createdBy: z.string().optional(),
    contact: z.string().optional(),
    customerAddress: AddressModel,
    address: AddressModel,
    pickupDate: z.string().regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/).optional(),
    pickupAddress: AddressModel.optional(),
    carrier: z.string(),
    emailTT: z.string().optional(),
    reference: z.string().optional(),
    comment: z.string().optional(),
    weight: z.number(),
    width: z.number().optional(),
    height: z.number().optional(),
    length: z.number().optional(),
    price: z.string().regex(/^[-]{0,1}[0-9]{0,5}$|^[-]{0,1}[0-9]{0,5}[,][0-9]{0,2}$/).or(z.literal('priceScale')),
    priceScaleRange: z.object({
        min: z.string(),
        max: z.string()
    }).optional(),
    priceCategoryId: z.string().optional(),
    priceExplanation: z.object({
        category: z.object({
            name: z.string().optional(),
            subtotal: z.string().or(z.object({
                min: z.string(),
                max: z.string()
            })).optional(),
            type: z.enum(['product', 'weight', 'fixed', 'fixedKg', 'fee', 'feepercent']).optional()
        }).optional(),
        priceTablesFees: z.array(z.object({
            description: z.string(),
            subtotal: z.string().or(z.object({
                min: z.string(),
                max: z.string()
            })).optional(),
            percentage: z.string().optional()
        })).optional(),
        fees: z.array(z.object({
            description: z.string(),
            subtotal: z.string().or(z.object({
                min: z.string(),
                max: z.string()
            })).optional()
        })).optional()
    }).optional(),
    options: z.array(z.string()),
    notes: z.string().optional(),
    productCodes: z.array(z.object({
        productCode: z.string().optional(),
        quantity: z.number()
    })),
    fees: z.array(z.object({
        // name: z.string(),
        price: z.string(),
        quantity: z.number(),
        automaticallyAdded: z.boolean().optional(),
        description: z.string(),
        productCode: z.string()
    })),
    history: z.array(z.object({
        type: z.enum(['resellerOnly', 'invoice']).optional(),
        time: z.string().optional(),
        timestamp: z.string(),
        description: z.string().optional(),
        who: z.string(),
        status: z.number().optional(),
        eventTime: z.string().optional(),
        externalStatus: z.union([z.number(), z.string()]).optional(),
        invoiceId: z.string().optional(),
        invoiceNumber: z.string().optional(),
        invoicedAmount: z.string().optional()
    })),
    content: z.array(z.object({
        orderLineId: z.string().optional(),
        itemId: z.string(),
        isSerialItem: z.boolean().optional(),
        isBatchItem: z.number().optional(),
        WarehouseCode: z.string().optional(),
        quantity: z.number(),
        description: z.string(),
        weight: z.number(),
        value: z.string(),
        hsCode: z.string().optional(),
        countryOrigin: z.string().optional(),
        comment: z.string().optional()
    })).optional(),
    customs: z.object({
        typeItem: z.string(),
        typeNumber: z.string(),
        number: z.string(),
        content: z.array(z.object({
            description: z.string(),
            quantity: z.number(),
            weight: z.number(),
            value: z.string(),
            hsCode: z.string(),
            countryOrigin: z.string(),
            comment: z.string().optional()
        }))
    }).optional(),
    plugin: z.string().optional(),
    timeConfirmed: z.string().optional(),
    externId: z.string().optional(),
    webshop: z.string().optional(),
    webshopId: z.string().optional(),
    phone: z.string().or(z.literal('')).optional(),
    deliveryTime: z.string().optional(),
    cover: z.number().min(0).max(9999).optional(),
    pickupPoint: PickupPoint.optional(),

    priceLocked: z.boolean().optional(),
    invoiceDate: z.string().optional(),
    invoiced: z.boolean().optional(),
    invoicedAmount: z.string().optional(),
    status: z.number().optional(),
    pxBarcode: z.string().max(100).or(z.literal('')).optional(),
    barcode: z.string().optional(),
    trackTrace: z.string().optional(),
    trackTraceLink: z.string().optional(),
    label: z.string().optional(),
    labelId: z.string().optional(),
    webhookURL: z.string().optional(),
    additionalProps: z.record(z.any()).optional(),
    confirmedEmailSent: z.boolean().optional(),
    shippedEmailSent: z.boolean().optional(),
    retourId: z.string().max(100).optional(),
    refundMethod: z.string().max(200).optional(),
    refundMethodComment: z.string().max(2000).optional(),
    molliePaymentId: z.string().optional(),
    paymentStatus: z.string().optional()
})

const ParcelModel = NewParcelModel.extend({
    _id: z.string()
})

export {ParcelModel, NewParcelModel, ParcelProductsModel}
