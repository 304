import React from 'react'
import moment from 'moment'

import InvoiceActions from '../../actions/InvoiceActions.js'

import {Alert, Button, Colors, Check, Link, TextArea, Modal, S2, P, Spinner, Row} from '../UI/index.js'
import {Customer} from '../../../server/functions/customers/customers.types.js'
import {Invoice, InvoiceAttachment} from '../../../server/functions/invoices/invoices.types.js'
import {Reseller} from '../../../server/functions/resellers/resellers.types.js'

interface InvoiceModalProps {
    history: any
    customers: {
        [customerId: string]: Customer
    }
    reseller: Reseller
    toPDF: (invoice: Invoice, callback: (err: string, response: InvoiceAttachment) => void) => void
    onClickSendInvoice: (invoice: Invoice, callback: (err: string) => void) => void
}

interface InvoiceModalState {
    modalIsOpen: boolean
    invoice: Invoice
    selected: number[]
    notes: string
    loading: boolean
    error: string
    invoiceLoading?: boolean
    invoiceError?: string
}

class InvoiceModal extends React.Component<InvoiceModalProps, InvoiceModalState> {
    initialState: InvoiceModalState

    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            invoice: {price: '0', invoiceLines: []},
            selected: [],
            notes: '',
            loading: false,
            error: ''
        }

        this.state = structuredClone(this.initialState)
    }

    open(id) {
        if (typeof id === 'object') {
            const invoice = id
            const selected = [...Array(invoice.invoiceLines.length).keys()]
            console.log(invoice)
            this.setState({modalIsOpen: true, invoice, selected, notes: invoice.notes || ''})
        } else {
            this.setState({modalIsOpen: true, invoiceLoading: true})

            InvoiceActions.getOne(id, (err, invoice) => {
                if (err) {
                    this.setState({invoiceLoading: false, invoiceError: err})
                } else {
                    console.log(invoice)
                    const selected = [...Array(invoice.invoiceLines.length).keys()]
                    this.setState({invoiceLoading: false, invoiceError: null, invoice, selected, notes: invoice.notes || ''})
                }
            })
        }
    }

    close() {
        const {invoice, notes} = this.state

        if (invoice.invoiced && notes !== invoice.notes) {
            InvoiceActions.editNotes(invoice._id, notes, (err) => {
                if (err) {
                    this.setState({error: err})
                } else {
                    this.setState(this.initialState)
                }
            })
        } else {
            this.setState(this.initialState)
        }
    }

    onClickSendInvoice() {
        const {onClickSendInvoice} = this.props
        const {invoice, selected} = this.state

        if (selected.length > 0) {
            const invoiceLines = invoice.invoiceLines.filter((invoiceLine, index) => {
                return selected.indexOf(index) > -1
            })

            invoice.invoiceLines = invoiceLines
            onClickSendInvoice(invoice, (err) => {
                if (err) {
                    this.setState({loading: false, error: err})
                } else {
                    this.close()
                }
            })
        }
    }

    onClickOpenAttachment() {
        const {invoice} = this.state
        const {toPDF} = this.props

        toPDF(invoice, (err, response) => {
            const raw = window.atob(response.pdf)
            const rawLength = raw.length
            const array = new Uint8Array(new ArrayBuffer(rawLength))

            for (let i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i)
            }
            const blob = new Blob([array], {type: 'application/pdf'})
            const blobUrl = URL.createObjectURL(blob)
            window.open(blobUrl)
        })
    }

    onSelectInvoiceLine(i) {
        const {selected} = this.state
        const index = selected.indexOf(i)

        if (index > -1) {
            selected.splice(index, 1)
        } else {
            selected.push(i)
        }

        selected.sort((a, b) => (a - b)) // Sort numerically
        this.setState({selected})
    }

    onSelectAll() {
        let {invoice, selected} = this.state

        if (invoice.invoiceLines.length !== selected.length) {
            selected = [...Array(invoice.invoiceLines.length).keys()]
        } else {
            selected = []
        }

        this.setState({selected})
    }

    onChangeNotes(event) {
        this.setState({notes: event.target.value})
    }

    render() {
        const {modalIsOpen, invoiceLoading, invoiceError, invoice, selected, notes, loading, error} = this.state
        const {reseller, history, customers} = this.props

        let showPriceExplanationOrders = false

        if (reseller.settings.administration.showPriceExplanationOrders) {
            showPriceExplanationOrders = true
        }

        const customer = customers[invoice.customer]
        if (customer) {
            if (customer.settings.orders.showPriceExplanation === 'yes') {
                showPriceExplanationOrders = true
            }

            if (customer.settings.orders.showPriceExplanation === 'no') {
                showPriceExplanationOrders = false
            }
        }

        let showPriceExplanationParcels = false

        if (reseller.settings.administration.showPriceExplanationParcels) {
            showPriceExplanationParcels = true
        }

        if (customer) {
            if (customer.settings.parcels.showPriceExplanation === 'yes') {
                showPriceExplanationParcels = true
            }

            if (customer.settings.parcels.showPriceExplanation === 'no') {
                showPriceExplanationParcels = false
            }
        }

        return (
            <Modal
                show={modalIsOpen}
                title='Factuur info'
                closeButton
                onClose={this.close.bind(this)}
            >

                {invoiceLoading ?
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 250}}>
                            <Spinner color={Colors.textDark} />
                        </div> :
                        <>

                            <div style={{display: 'flex', marginBottom: 30}}>
                                <div style={{flex: 1}}>
                                    <S2>Datum</S2>
                                    <P>{moment(invoice.date).format('DD-MM-YYYY')}</P>
                                </div>
                                <div style={{flex: 1}}>
                                    <S2>Status</S2>
                                    <P>{invoice.invoiced ? 'Gefactureerd' : 'Concept'}</P>
                                </div>
                            </div>

                            <div style={{display: 'flex', marginBottom: 30}}>
                                <div style={{flex: 1}}>
                                    <S2>Klant</S2>
                                    <P>{customers[invoice.customer] ? customers[invoice.customer].name : ''}</P>
                                </div>

                                <div style={{flex: 1}}>
                                    <S2>Prijs</S2>
                                    <P>{`€ ${invoice.price}`}</P>
                                </div>
                            </div>

                            <div style={{maxHeight: 430, marginBottom: 30, overflowY: 'auto'}}>
                                <div style={{flex: 1}}>
                                    <table style={{width: '100%', textAlign: 'left'}}>
                                        <thead style={{color: Colors.textDark}}>
                                            <tr>
                                                {!invoice.invoiced &&
                                                    <th>
                                                        <Check
                                                            checked={selected.length === invoice.invoiceLines.length}
                                                            onChange={this.onSelectAll.bind(this)}
                                                        />
                                                    </th>
                                                }
                                                <th><S2>Datum</S2></th>
                                                <th><S2>Omschrijving</S2></th>
                                                <th><S2>Prijs</S2></th>
                                            </tr>
                                        </thead>
                                        <tbody style={{color: Colors.textDark}}>
                                            {invoice.invoiceLines.map((invoiceLine, index) => {
                                                return (
                                                    <tr key={`invoiceLine${index}`}>
                                                        {!invoice.invoiced &&
                                                            <td style={{verticalAlign: 'top'}}>
                                                                <Check
                                                                    checked={selected.indexOf(index) > -1}
                                                                    onChange={this.onSelectInvoiceLine.bind(this, index)}
                                                                    style={{marginTop: -6}}
                                                                />
                                                            </td>
                                                        }
                                                        <td style={{width: 100, verticalAlign: 'top'}}><P>{moment(invoiceLine.date).format('DD-MM-YYYY')}</P></td>
                                                        <td style={{verticalAlign: 'top'}}>
                                                            {invoiceLine.type === 'order' &&
                                                                <>
                                                                    <Link onClick={() => history.push('/planning', {orderId: invoiceLine._id})}>{invoiceLine.description}</Link>
                                                                    {showPriceExplanationOrders && invoiceLine.priceExplanation &&
                                                                        <div style={{marginLeft: 20}}>
                                                                            {invoiceLine.correctionInvoicedAmount &&
                                                                                <Row>
                                                                                    <P style={{flex: 1, fontWeight: 700}}>Gefactureerd bedrag</P>
                                                                                    <P style={{marginRight: 150, fontWeight: 700}}>€ {invoiceLine.correctionInvoicedAmount}</P>
                                                                                </Row>
                                                                            }
                                                                            {invoiceLine.priceExplanation.overruledPrice && invoiceLine.priceExplanation.overruledPrice !== '0,00' &&
                                                                                <Row>
                                                                                    <P style={{flex: 1}}>Prijs</P>
                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.overruledPrice}</P>
                                                                                </Row>
                                                                            }
                                                                            {invoiceLine.priceExplanation.minimumRate ?
                                                                                    <Row>
                                                                                        <P style={{flex: 1}}>Starttarief</P>
                                                                                        <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.minimumRate}</P>
                                                                                    </Row> :
                                                                                    <>
                                                                                        {invoiceLine.priceExplanation.startRate &&
                                                                                            <Row>
                                                                                                <P style={{flex: 1}}>Starttarief</P>
                                                                                                <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.startRate}</P>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.feeExtraStop && invoiceLine.priceExplanation.feeExtraStop.subtotal !== '0,00' &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>{invoiceLine.priceExplanation.feeExtraStop.nrOfStops} stop{invoiceLine.priceExplanation.feeExtraStop.nrOfStops > 1 ? 's' : ''}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.feeExtraStop.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.feeForPickupAddress && invoiceLine.priceExplanation.feeForPickupAddress.subtotal !== '0,00' &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>{invoiceLine.priceExplanation.feeForPickupAddress.nrOfAddresses} ophaaladres{invoiceLine.priceExplanation.feeForPickupAddress.nrOfAddresses > 1 ? 'sen' : ''}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.feeForPickupAddress.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.feeForDeliveryAddress && invoiceLine.priceExplanation.feeForDeliveryAddress.subtotal !== '0,00' &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>{invoiceLine.priceExplanation.feeForDeliveryAddress.nrOfAddresses} bezorgadres{invoiceLine.priceExplanation.feeForDeliveryAddress.nrOfAddresses > 1 ? 'sen' : ''}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.feeForDeliveryAddress.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.feeForExtraCollo && invoiceLine.priceExplanation?.feeForExtraCollo.subtotal !== '0,00' &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>{invoiceLine.priceExplanation.feeForExtraCollo.nrOfColli} colli</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.feeForExtraCollo.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }
                                                                                        {invoiceLine.priceExplanation.pickupAndDeliveryOptions?.length > 0 ?
                                                                                                <Row>
                                                                                                    <Row>
                                                                                                        <P style={{flex: 1}}>{invoiceLine.priceExplanation.pickupAndDeliveryOptions.length} Ophaal- / bezorgoptie{invoiceLine.priceExplanation.pickupAndDeliveryOptions.length > 1 ? 's' : ''}</P>
                                                                                                        <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.pickupAndDeliveryOptions.reduce((acc, option) => acc + parseFloat(option.subtotal.replace(',', '.')), 0).toFixed(2).replace('.', ',')}</P>
                                                                                                    </Row>
                                                                                                </Row> :
                                                                                            null
                                                                                        }
                                                                                        {invoiceLine.priceExplanation.distanceFee &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>Kilometerprijs {(invoiceLine.priceExplanation.distanceFee.distance - (invoiceLine.priceExplanation.distanceFee.distanceIncluded || 0)).toFixed(2).replace('.', ',')}km à €{invoiceLine.priceExplanation.distanceFee.fee}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.distanceFee.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.zoneFees &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>Bezorggebied {invoiceLine.priceExplanation.zoneFees.zones[0]}{invoiceLine.priceExplanation.zoneFees.zones?.[1] ? ` - ${invoiceLine.priceExplanation.zoneFees.zones[1]}` : ''}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.zoneFees.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.fuelFee?.subtotal &&
                                                                                            <Row>
                                                                                                <Row>
                                                                                                    <P style={{flex: 1}}>Brandstoftoeslag {invoiceLine.priceExplanation.fuelFee.percentage}%</P>
                                                                                                    <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.fuelFee.subtotal}</P>
                                                                                                </Row>
                                                                                            </Row>
                                                                                        }

                                                                                        {invoiceLine.priceExplanation.fees?.length > 0 ?
                                                                                            invoiceLine.priceExplanation?.fees.map((fee) => (
                                                                                                <Row key={fee.name}>
                                                                                                    <P style={{flex: 1}}>Handmatige toeslag {fee.name}</P>
                                                                                                    <P style={{marginRight: 150}}>€ {fee.fee}</P>
                                                                                                </Row>
                                                                                            )) :
                                                                                            null
                                                                                        }
                                                                                        {invoiceLine.priceExplanation.rounding &&
                                                                                            <Row>
                                                                                                <P style={{flex: 1}}>Afronding {[
                                                                                                    {value: 1, title: 'Hele euro\'s'},
                                                                                                    {value: 2.5, title: 'Veelvoud van €2,50'},
                                                                                                    {value: 5, title: 'Veelvoud van €5,00'},
                                                                                                    {value: 10, title: 'Veelvoud van €10,00'},
                                                                                                    {value: 5.05, title: 'Veelvoud van €5,00 - €0,05'},
                                                                                                    {value: 10.05, title: 'Veelvoud van €10,00 - €0,05'}
                                                                                                ].find((r) => r.value === invoiceLine.priceExplanation.rounding.rounding)?.title}: €{invoiceLine.priceExplanation.rounding.before}
                                                                                                </P>
                                                                                                <P style={{marginRight: 150}}>€ {invoiceLine.priceExplanation.rounding.subtotal}</P>
                                                                                            </Row>
                                                                                        }
                                                                                    </>
                                                                            }

                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {invoiceLine.type === 'parcel' &&
                                                                <>
                                                                    <Link onClick={() => history.push('/zendingen', {parcelId: invoiceLine._id})}>{invoiceLine.description}</Link>
                                                                    {showPriceExplanationParcels && invoiceLine.priceExplanation &&
                                                                        <div style={{marginLeft: 20}}>
                                                                            {invoiceLine.correctionInvoicedAmount &&
                                                                                <Row>
                                                                                    <P style={{flex: 1, fontWeight: 700}}>Gefactureerd bedrag</P>
                                                                                    <P style={{marginRight: 150, fontWeight: 700}}>€ {invoiceLine.correctionInvoicedAmount}</P>
                                                                                </Row>
                                                                            }
                                                                            {(typeof invoiceLine.priceExplanation.category?.subtotal !== 'string' || invoiceLine.priceExplanation.category?.subtotal !== '0,00') &&
                                                                                <Row>
                                                                                    <P style={{flex: 1}}>Zending tarief{invoiceLine.priceExplanation.category?.name ? `: ${invoiceLine.priceExplanation.category.name}` : ''}</P>
                                                                                    <P style={{marginRight: 150}}>€ {typeof invoiceLine.priceExplanation.category?.subtotal === 'string' ? invoiceLine.priceExplanation.category?.subtotal : `${invoiceLine.priceExplanation.category?.subtotal?.min} - € ${invoiceLine.priceExplanation.category?.subtotal?.max}`}</P>
                                                                                </Row>
                                                                            }

                                                                            {[...(invoiceLine.priceExplanation.priceTablesFees || []), ...(invoiceLine.priceExplanation.fees || [])]?.map((fee, index) => (
                                                                                <Row key={`priceExplanation-fee-${index}`}>
                                                                                    <P style={{flex: 1}}>Toeslag {fee.description}</P>
                                                                                    <P style={{marginRight: 150}}>€ {typeof fee.subtotal === 'string' ? fee.subtotal : `${fee.subtotal?.min} - € ${fee.subtotal?.max}`}</P>
                                                                                </Row>
                                                                            ))}
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                            {invoiceLine.type === 'subscription' &&
                                                                <Link onClick={() => history.push('/abonnementen', {subscriptionId: invoiceLine._id || invoiceLine.subscriptionId})}>{invoiceLine.description}</Link>
                                                            }

                                                        </td>
                                                        <td style={{width: 100, verticalAlign: 'top'}}><P>{`€ ${invoiceLine.price}`}</P></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <br />
                            {invoice.invoiced &&
                                <TextArea
                                    label='Notities'
                                    value={notes}
                                    onChange={this.onChangeNotes.bind(this)}
                                />
                            }

                            <br />
                            {error && <Alert variant='danger'>{error}</Alert>}

                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>

                                {!invoice.invoiced &&
                                    <Button loading={loading} onClick={this.onClickSendInvoice.bind(this)}>
                                        Factuur aanmaken
                                    </Button>
                                }

                                {invoice.invoiced &&
                                    <Button onClick={this.onClickOpenAttachment.bind(this)}>
                                        <i className='mdi mdi-download' />
                                    </Button>
                                }
                            </div>
                        </>
                }

                {invoiceError &&
                    <Alert variant='danger'>{invoiceError}</Alert>
                }
            </Modal>
        )
    }
}

export default (InvoiceModal)
