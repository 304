import React from 'react'

import Colors from './Colors.js'

export default class ToolTip extends React.Component {
    constructor(props) {
        super(props)

        this.state = {hover: false}

        this.timeout = null
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
    }

    onMouseEnter() {
        clearTimeout(this.timeout)
        const rect = this.child.getBoundingClientRect()
        this.timeout = setTimeout(() => this.setState({hover: true, offsetTop: rect.height, rect}), 800)
    }

    onMouseLeave() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.setState({hover: false}), 100)
    }

    render() {
        const {children, text='', visible, placement, style={}, tooltipStyle={}, fixedPosition = false} = this.props
        const {hover, offsetTop, rect} = this.state

        const defaultStyle = {
            position: 'relative',
            ...style
        }

        let tooltip = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'absolute',
            height: visible || hover ? 30 : 0,
            whiteSpace: 'nowrap',
            padding: '0px 12px 0px 12px',
            opacity: visible || hover ? 0.95 : 0,
            fontSize: 14,
            background: Colors.grey60,
            color: Colors.textLight,
            borderRadius: 4,
            top: placement !== 'top' ? (offsetTop || 0) + 3 : undefined,
            bottom: placement === 'top' ? (offsetTop || 0) + 3 : undefined,
            left: placement === 'left' ? 0 : '50%',
            marginLeft: placement === 'right' ? 0 : -(text?.length * 4) - 5,
            zIndex: 999999999999999,
            transition: visible || hover ? 'height 0ms 0ms, opacity 600ms 0ms' : 'height 0ms 400ms, opacity 400ms 0ms',
            dropShadow: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)'
        }

        if (fixedPosition && rect) {
            tooltip.position = 'fixed'
            tooltip.top = rect.top + offsetTop
            tooltip.left = rect.left + (rect.width)

            if (placement === 'top') {
                tooltip.top = rect?.top - 30
            }
        }

        tooltip = {
            ...tooltip,
            ...tooltipStyle
        }

        return (
            <div
                style={defaultStyle}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                ref={(ref) => this.child = ref}
            >
                {children}

                {text &&
                    <div style={tooltip}>{text}</div>
                }
            </div>
        )
    }
}
