import React, {CSSProperties, ReactNode} from 'react'

import {Colors} from '../index.js'

interface PProps {
    id?: string
    children: ReactNode
    disabled?: boolean
    style?: CSSProperties
    ellipsis?: boolean
    onDark?: boolean
    onClick?: () => void
    onMouseDown?: () => void
    onMouseUp?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

class P extends React.PureComponent<PProps> {
    render() {
        const {id, children, disabled, style = {}, ellipsis, onDark} = this.props

        const defaultStyle: CSSProperties = {
            fontSize: 14,
            fontWeight: 400, // normal
            letterSpacing: 0.4,
            color: disabled ? Colors.textMedium : (onDark ? 'white' : Colors.buttonSolid),
            lineHeight: 1.5,
            wordBreak: 'break-word',
            ...style
        }

        if (ellipsis) {
            defaultStyle.overflow = 'hidden'
            defaultStyle.whiteSpace = 'nowrap'
            defaultStyle.textOverflow = 'ellipsis'
        }

        return (
            <div
                id={id}
                style={defaultStyle}
                onClick={this.props.onClick || null}
                onMouseDown={this.props.onMouseDown || null}
                onMouseUp={this.props.onMouseUp || null}
                onMouseEnter={this.props.onMouseEnter || null}
                onMouseLeave={this.props.onMouseLeave || null}
            >
                {children}
            </div>
        )
    }
}

export default P
