import React from 'react'

import _ from 'underscore'

import {Colors} from './index.js'

class Toast extends React.Component {
    constructor(props) {
        super(props)
        this.state = {open: false}
    }

    showToast(variant, msg, time) {
        const {open} = this.state
        if (open) {
            this.setState({open: false})
            clearTimeout(this.state.timeout)
            setTimeout(() => {
                const timeout = setTimeout(() => this.setState({open: false}), time || 2500)
                this.setState({variant: variant, msg: msg, open: true, timeout})
            }, 200)
        } else {
            const timeout = setTimeout(() => this.setState({open: false}), time || 2500)
            this.setState({variant: variant, msg: msg, open: true, timeout})
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout)
    }

    render() {
        const {style} = this.props
        const {open, msg} = this.state

        const iconStyle = {height: 24, fontSize: 24, marginRight: 12, transform: 'translateY(-1px)'}


        const defaultStyle = {
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            bottom: 0,
            left: 'calc(50vw - 100px)',
            minWidth: 200,
            paddingLeft: 12,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 12,
            marginBottom: 12,
            background: Colors.buttonSolid,
            color: Colors.textLight,
            zIndex: 1000,
            pointerEvents: open ? 'auto' : 'none',
            opacity: open ? 1 : 0,
            transform: open ? 'scale(1)' : 'scale(0.95)',
            transition: 'all .2s ease',
            boxShadow: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
            borderRadius: 4
        }

        _.keys(style).map((key) => {
            defaultStyle[key] = style[key]
        })

        return (
            <div style={defaultStyle}>
                <i className='mdi mdi-checkbox-marked-circle-outline' style={iconStyle}></i>
                {msg}
            </div>
        )
    }
}

export default (Toast)
