// @ts-check
import React from 'react'

import {Icon, Button, H4, H6, Tabs, Tab, Map, Panel, Row, Toggle, S1, Colors, ColorPicker, P, Input, Select, MetricInput, Column} from '../UI/index.js'
import _ from 'lodash'

export default class PartnerSettingsPanel extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            tab: 'send',
            settingsChanged: false,
            partnerSettings: structuredClone(this.props.partnerSettings)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.partnerSettings !== this.props.partnerSettings) {
            this.setState({tab: 'send', settingsChanged: false, partnerSettings: structuredClone(this.props.partnerSettings)})
        }
    }

    /**
     * @param {string} path
     * @param {{target: HTMLInputElement}} event
     */
    onChangeValue(path, event) {
        const partnerSettings = {...this.state.partnerSettings}
        _.set(partnerSettings, path, event.target.value)
        this.setState({partnerSettings, settingsChanged: true})
    }

    /**
     * @param {string} path
     * @param {{target: HTMLInputElement}} event
     */
    onChangeChecked(path, event) {
        const partnerSettings = {...this.state.partnerSettings}
        _.set(partnerSettings, path, !!event.target.checked)
        this.setState({partnerSettings, settingsChanged: true})
    }

    /**
     * @param {string} path
     * @param {string} value
     */
    onChangeArray(path, value) {
        const partnerSettings = {...this.state.partnerSettings}

        const array = _.get(partnerSettings, path, [])

        const index = array.indexOf(value)

        if (index > -1) {
            array.splice(index, 1)
        } else {
            array.push(value)
        }

        _.set(partnerSettings, path, array)

        this.setState({partnerSettings, settingsChanged: true})
    }


    onSubmit() {
        this.props.onSave(this.state.partnerSettings)
    }

    render() {
        const {reseller, partners} = this.props
        const {partnerSettings} = this.state

        const partner = partners?.[partnerSettings._id]

        return (
            <Panel shadow='true' style={{width: 800, padding: 24, height: 'fit-content'}}>
                <Row style={{alignItems: 'center', marginBottom: 36}}>
                    <Column style={{justifyContent: 'center'}}>
                        <img src={partner?.logo} style={{height: 32, maxWidth: 256, objectFit: 'contain'}}/>
                    </Column>
                    <Column style={{justifyContent: 'center'}}>
                        <H4>{partner?.name}</H4>
                    </Column>
                </Row>


                <Row style={{alignItems: 'center', marginBottom: 12}}>
                    <Column>
                        <Toggle
                            label='Kleur tonen bij scannen'
                            info='Bij meerdere vervoerders met hetzelfde logo kan het handig zijn om tijdens het scannen een kleur te zien voor snelle herkenning'
                            checked={partnerSettings.useCustomScanColor}
                            onChange={(event) => this.onChangeChecked('useCustomScanColor', event)}
                            style={{marginBottom: 0}}
                        />
                    </Column>

                    <Column style={{alignItems: 'center'}}>
                        {partnerSettings.useCustomScanColor &&
                        <>
                            <ColorPicker
                                value={partnerSettings.customScanColor}
                                colors={Colors.routeColors}
                                onChange={(event) => this.onChangeValue('customScanColor', event)}
                            />

                            <P
                                style={{
                                    height: 36,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 4,
                                    backgroundColor: partnerSettings.customScanColor || '',
                                    color: Colors.isDarkBackground(partnerSettings.customScanColor || '') ? 'white' : 'black'
                                }}
                            >
                                {partner?.name}
                            </P>
                        </>
                        }
                    </Column>
                </Row>

                <Row>
                    <Column>
                        <Toggle
                            label='Toon route informatie aan partner'
                            info='De partner ziet de route informatie tijdens het scannen, hierdoor kunnen pakketten meteen op route gesorteerd worden.'
                            checked={partnerSettings.showRouteInfo}
                            onChange={(event) => this.onChangeChecked('showRouteInfo', event)}
                        />
                    </Column>
                    <Column/>
                </Row>

                <Input
                    label='Crediteurnummer'
                    info='Hier kun je het crediteurnummer invullen dat wordt gebruikt in je boekhouding voor deze vervoerder'
                    value={partnerSettings.creditorCode}
                    onChange={(event) => this.onChangeValue('creditorCode', event)}
                />

                <Tabs
                    style={{marginBottom: 24, marginTop: 48}}
                    value={this.state.tab}
                    onChange={(event) => this.setState({tab: event.target.value})}
                >
                    <Tab value='send'>{`Aanmelden bij ${partner?.name}`}</Tab>
                    <Tab value='receive'>{`Ontvangen van ${partner?.name}`}</Tab>
                </Tabs>


                {this.state.tab === 'send' &&
                    <>
                        <H6>{`Bezorggebied van ${partner?.name}`}</H6>

                        <div style={{height: 500, marginBottom: 24}}>
                            <Map layers={partner?.deliveryZones.filter((deliveryZone) => {
                                return !partnerSettings.sendSettings.useCustomDeliveryZones || partnerSettings.sendSettings.deliveryZones.includes(deliveryZone.id)
                            })}
                            />
                        </div>

                        <Toggle
                            label='Gebruik beperkt bezorggebied'
                            checked={partnerSettings.sendSettings.useCustomDeliveryZones}
                            onChange={(event) => this.onChangeChecked('sendSettings.useCustomDeliveryZones', event)}
                        />


                        {partnerSettings.sendSettings.useCustomDeliveryZones && partner?.deliveryZones.map((deliveryZone) => {
                            return (
                                <Row key={deliveryZone.id}style={{alignItems: 'center', marginBottom: 12}}>
                                    <Icon icon='mdi mdi-circle' style={{fontSize: 24, color: deliveryZone.color}}/>

                                    <S1 style={{flex: 1}}>{deliveryZone.name}</S1>

                                    <Toggle
                                        style={{marginBottom: 0, flex: 0}}
                                        checked={partnerSettings.sendSettings.deliveryZones.includes(deliveryZone.id)}
                                        onChange={() => this.onChangeArray('sendSettings.deliveryZones', deliveryZone.id)}
                                    />
                                </Row>
                            )
                        })}

                        <Select
                            style={{marginTop: 24}}
                            label='Gebruik communicatie van'
                            value={partnerSettings.sendSettings.communication || 'default'}
                            options={[
                                {value: 'default', title: `Gebruik standaard instelling (${reseller.settings.defaultPartnerSettings.sendSettings.communication === 'reseller' ? reseller.settings.accountName || reseller.name : partner?.name})`},
                                {value: 'reseller', title: reseller.settings.accountName || reseller.name},
                                {value: 'partner', title: partner?.name}
                            ]}
                            onChange={(event) => this.onChangeValue('sendSettings.communication', event)}
                        />

                        <Select
                            label={`Mag ${partner?.name} de rit bewerken`}
                            value={partnerSettings.sendSettings.allowEditOrder || 'default'}
                            options={[
                                {value: 'default', title: `Gebruik standaard instelling (${reseller.settings.defaultPartnerSettings.sendSettings.allowEditOrder === 'yes' ? 'Ja' : reseller.settings.defaultPartnerSettings.sendSettings.allowEditOrder === 'addressOnly' ? 'Alleen adres' : 'Nee'})`},
                                {value: 'yes', title: 'Ja'},
                                {value: 'addressOnly', title: 'Alleen adres'},
                                {value: 'no', title: 'Nee'}
                            ]}
                            onChange={(event) => this.onChangeValue('sendSettings.allowEditOrder', event)}
                        />
                    </>
                }

                {this.state.tab === 'receive' &&
                    <>

                        <H6>Bezorggebied</H6>

                        <Select
                            label='Eigen bezorggebied'
                            value={partnerSettings.receiveSettings.customDeliveryZones || 'default'}
                            options={[
                                {value: 'default', title: 'Gebruik standaard instelling'},
                                {value: 'custom', title: 'Afwijkend bezorggebied'}
                            ]}
                            onChange={(event) => this.onChangeValue('receiveSettings.customDeliveryZones', event)}
                        />

                        {partnerSettings.receiveSettings.customDeliveryZones === 'custom' &&
                        <>
                            <div style={{height: 500, marginBottom: 24}}>
                                <Map layers={reseller.settings.deliveryZones.filter((deliveryZone) => {
                                    return partnerSettings.receiveSettings.deliveryZones.includes(deliveryZone.id)
                                })}
                                />
                            </div>

                            {reseller.settings.deliveryZones.map((deliveryZone) => {
                                return (
                                    <Row key={deliveryZone.id}style={{alignItems: 'center', marginBottom: 12}}>
                                        <Icon icon='mdi mdi-circle' style={{fontSize: 24, color: deliveryZone.color}}/>

                                        <S1 style={{flex: 1}}>{deliveryZone.name}</S1>

                                        <Toggle
                                            style={{marginBottom: 0, flex: 0}}
                                            checked={partnerSettings.receiveSettings.deliveryZones.includes(deliveryZone.id)}
                                            onChange={() => this.onChangeArray('receiveSettings.deliveryZones', deliveryZone.id)}
                                        />
                                    </Row>
                                )
                            })}
                        </>
                        }

                        <H6 style={{marginTop: 36}}>Maximum dimensies</H6>

                        <Row>
                            <MetricInput
                                label='Maximale lengte'
                                placeholder={reseller.settings.defaultPartnerSettings.receiveSettings.maxLength}
                                value={partnerSettings.receiveSettings.maxLength}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxLength', event)}
                                append='cm'
                            />

                            <MetricInput
                                label='Maximale breedte'
                                placeholder={reseller.settings.defaultPartnerSettings.receiveSettings.maxWidth}
                                value={partnerSettings.receiveSettings.maxWidth}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxWidth', event)}
                                append='cm'
                            />

                            <MetricInput
                                label='Maximale hoogte'
                                placeholder={reseller.settings.defaultPartnerSettings.receiveSettings.maxHeight}
                                value={partnerSettings.receiveSettings.maxHeight}
                                divisionFactor={10}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxHeight', event)}
                                append='cm'
                            />
                        </Row>

                        <Row>
                            <MetricInput
                                label='Maximaal gewicht'
                                placeholder={reseller.settings.defaultPartnerSettings.receiveSettings.maxWeight}
                                value={partnerSettings.receiveSettings.maxWeight}
                                divisionFactor={1000}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxWeight', event)}
                                append='kg'
                            />

                            <MetricInput
                                label='Maximaal volume'
                                placeholder={reseller.settings.defaultPartnerSettings.receiveSettings.maxVolume}
                                value={partnerSettings.receiveSettings.maxVolume}
                                onChange={(event) => this.onChangeValue('receiveSettings.maxVolume', event)}
                                append='dm3'
                            />
                        </Row>
                    </>
                }


                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 90}}>
                    {partnerSettings.status === 'active' &&
                        <Button variant='error' onClick={() => this.props.onRemove()}>
                            Deactiveer
                        </Button>
                    }

                    <div style={{flex: 1}}/>


                    <Button onClick={this.onSubmit.bind(this)} disabled={!this.state.settingsChanged}>
                        Opslaan
                    </Button>
                </div>
            </Panel>
        )
    }
}

