import React from 'react'
import {Colors} from './index.js'


export default class Panel extends React.Component {
    render() {
        const {style={}, shadow, children} = this.props

        const defaultStyle = {
            background: shadow ? Colors.backgroundNeutral : 'white',
            width: '100%',
            border: shadow ? 'none' : `1px solid ${Colors.border}`,
            boxShadow: shadow ? '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' : '',
            flexShrink: 0,
            ...style
        }

        return (
            <div
                {...this.props} style={defaultStyle} ref={(ref) => this.parent = ref}
            >
                {children}
            </div>

        )
    }
}
