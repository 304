import {z} from 'zod'
import {ParcelProductsModel} from './parcel.js'
import {ColloType} from './order.js'

const RouteProfile = z.enum(['car', 'bike', 'walk'])

const FuelType = z.enum(['green-electric', 'grey-electric', 'unknown-electric', 'bio-diesel', 'diesel', 'lpg', 'hydrogen', 'none', ''])

const PayloadType = z.enum(['colli', 'weight', ''])

const Address = z.object({
    name: z.string(),
    attention: z.string().optional(),
    street: z.string(),
    nr: z.string(),
    addition: z.string(),
    street2: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string(),
    email: z.string(),
    phone: z.string(),
    isBusiness: z.boolean().optional(),
    instructions: z.string().optional(),
    position: z.object({
        lat: z.number(),
        lng: z.number()
    }).optional(),
    attachments: z.object({
        fileId: z.string().optional(),
        type: z.string(),
        fileName: z.string(),
        data: z.string().optional()
    }).array().optional()
})

const DeliveryZone = z.object({
    id: z.string(),
    name: z.string(),
    color: z.string(),
    geoJSON: z.object({
        type: z.string(),
        geometry: z.object({
            type: z.string(),
            coordinates: z.array(z.array(z.array(z.number()))).optional().or(z.array(z.array(z.array(z.array(z.number())))).optional()),
            geometries: z.array(z.object({
                type: z.string(),
                coordinates: z.array(z.array(z.array(z.number()))).optional().or(z.array(z.array(z.array(z.array(z.number())))).optional())
            })).optional()
        })
    }),
    visible: z.boolean()
})

const VehicleType = z.object({
    _id: z.string().optional(),
    name: z.string().max(30, 'Naam mag maximaal 30 karakters bevatten'),
    icon: z.string(),
    routeProfile: RouteProfile,
    speedDifferenceEnabled: z.boolean().optional(),
    speedDifferenceMode: z.enum(['faster', 'slower']).optional(),
    speedDifference: z.number().optional(),
    fuelType: FuelType,
    /** Range of vehicle in meters. */
    range: z.number(),
    usage: z.number().optional(),
    payloads: z.array(z.object({
        payload: z.number(),
        payloadType: PayloadType,
        payloadColliType: ColloType.optional()
    })),
    hourlyRate: z.string(),
    /** Stop time in seconds. */
    stopTime: z.number(),
    /** Comment for messenger. */
    comment: z.string(),
    notes: z.string(),
    isArchived: z.boolean()
})

export const openingHoursDayObject = z.object({
    open: z.boolean(),
    from: z.string(),
    till: z.string()
})

const ParcelPriceTableProduct = z.object({
    usePriceScales: z.boolean().optional(),
    countryGroups: z.array(z.object({
        name: z.string().optional(),
        countries: z.string().array(),
        type: z.enum(['', 'product', 'weight', 'fixed', 'fixedKg', 'fee', 'feepercent']),
        price: z.string().optional(),
        fee: z.string().optional(),
        round: z.string().optional(),
        categories: z.array(z.object({
            name: z.string().optional(),
            price: z.string().optional(),
            maxWeight: z.string().optional(),
            length: z.number().optional(),
            width: z.number().optional(),
            height: z.number().optional(),
            volume: z.number().optional(),
            productCode: z.string().optional(),
            fee: z.string().optional(),
            priceScales: z.array(z.object({
                maxAmount: z.number().optional(),
                price: z.string()
            })).optional()
        })).optional(),
        values: z.record(z.number().or(z.string()), z.string()).optional(),
        priceScales: z.array(z.object({
            maxAmount: z.number().optional(),
            price: z.string()
        })).optional()
    })).optional()
})

const ParcelProductsCombination = z.union([
    ParcelProductsModel,
    z.string().refine((value) => {
        // Split by commas and ensure all parts are valid enum values
        return value
            .split(',')
            .map((part) => part.trim())
            .every((part) => ParcelProductsModel.options.includes(part as z.infer<typeof ParcelProductsModel>))
    }, {message: 'Invalid combination of parcel products'})
])

const ParcelPriceTable = z.record(
    z.string(), // carrierName
    z.record(ParcelProductsCombination.or(z.literal('usePriceScales')), ParcelPriceTableProduct.optional().or(z.boolean()))
    // z.intersection(
    //     z.object({
    //         usePriceScales: z.boolean().optional()
    //     }),
    // )
)

const OrderPriceTable = z.object({
    id: z.string(),
    name: z.string(),
    startRate: z.string(),
    minimumRate: z.string(),
    feeExtraStop: z.string(),
    nrOfStopsIncluded: z.number(),
    feeForPickupAddress: z.string(),
    feeForDeliveryAddress: z.string(),
    feeForExtraCollo: z.string(),
    nrOfColliIncluded: z.number(),
    pickupAndDeliveryOptions: z.array(z.object({
        fee: z.string(),
        option: z.string()
    })),
    distanceFeeType: z.string().optional(),
    startAtReseller: z.boolean().optional(),
    retour: z.boolean().optional(),
    distanceFee: z.string().optional(),
    detourFactorPercentage: z.string().optional(),
    startDistance: z.number().optional(),
    fuelFeeType: z.string().optional(),
    fuelFeePercent: z.string().optional(),
    deliveryZones: z.array(z.string()).optional(),
    zoneFees: z.record(z.string(), z.string()).optional(),
    rounding: z.number().or(z.literal('')).optional().nullable(),
    fees: z.record(z.string(), z.object({
        name: z.string(),
        price: z.string()
    })).optional()
})

const NewResellerModel = z.object({
    name: z.string(),
    isShop: z.boolean().optional(),
    orderPartners: z.string().array().optional(),
    isArchived: z.boolean().optional(),
    archivedTime: z.string().optional(),
    invoiceResellerId: z.string().optional().nullable(),
    startDate: z.string().optional(),
    noInvoice: z.boolean().optional(),
    debtorCode: z.string().optional(),
    accountType: z.enum(['shopPlus', 'carrier', 'carrierPlus']),
    groups: z.array(z.string()),
    notes: z.string().optional().nullable(),
    prices: z.object({
        branchManagement: z.boolean().optional(),
        orders: z.array(z.object({
            max: z.number(),
            price: z.string()
        })),
        parcels: z.array(z.object({
            max: z.number(),
            price: z.string()
        })),
        customPrices: z.boolean().optional()
    }),
    repeatedInvoiceRules: z.array(z.object({
        description: z.string(),
        price: z.number()
    })).optional().nullable(),
    permissions: z.object({
        orders: z.boolean(),
        parcels: z.boolean(),
        shifts: z.boolean(),
        paychecks: z.boolean(),
        subscriptions: z.boolean(),
        users: z.boolean(),
        customers: z.boolean(),
        invoices: z.boolean(),
        settings: z.boolean(),
        vehicles: z.boolean()
    }).partial(),
    ownerEmail: z.string().optional(),
    msgPostNLenabled: z.boolean().optional(),
    msgDHLenabled: z.boolean().optional(),
    qlsEnabled: z.boolean().optional(),
    reference: z.string().optional().nullable(),
    customBrandingEnabled: z.boolean().optional(),
    settings: z.object({
        account: z.object({
            openingHours: z.object({
                monday: openingHoursDayObject,
                tuesday: openingHoursDayObject,
                wednesday: openingHoursDayObject,
                thursday: openingHoursDayObject,
                friday: openingHoursDayObject,
                saturday: openingHoursDayObject,
                sunday: openingHoursDayObject
            })
        }),
        accountName: z.string().optional(),
        logo: z.string().optional(),
        logoBlack: z.string().optional(),
        logos: z.object({
            size_32: z.string(),
            size_128: z.string(),
            size_180: z.string(),
            size_192: z.string()
        }).partial().optional(),
        mainLogo: z.string().optional(),
        squareLogo: z.string().optional(),
        color: z.string(),
        domain: z.string().optional().nullable(),
        companyAddress: Address,
        deliveryZones: DeliveryZone.array(),
        useExpectedOrders: z.boolean(),
        pickupNotPossibleReasons: z.string().array(),
        pickupNotPossibleReasonsSpecifications: z.array(z.object({
            reason: z.string(),
            specification: z.string()
        })),
        deliveryNotPossibleReasons: z.string().array(),
        deliveryNotPossibleReasonsSpecifications: z.array(z.object({
            reason: z.string(),
            specification: z.string()
        })),
        defaultSigned: z.boolean().optional(),
        nameRequired: z.boolean().optional(),
        defaultStatedAddressOnly: z.boolean().optional(),
        gpsRequired: z.boolean().optional(),
        scanRequired: z.boolean().optional(),
        imageRequired: z.boolean().optional(),
        allowAddCollo: z.enum(['yes', 'deliver', 'pickup', '']).optional(),
        scanRequiredBeforeStart: z.boolean().optional(),
        deliveryRadiusWarning: z.boolean().optional(),
        maxDeliveryRadius: z.number().optional().nullable(),
        nextStopWarning: z.enum(['', 'warning', 'notallowed']).optional(),
        defaultVehicle: z.enum(['bike', 'car', 'walk']).or(z.string()).optional(),
        customSpeed: z.boolean().optional(),
        speedDifference: z.number().optional(),
        stopTime: z.number().optional(),
        optimizeForTimeslots: z.boolean().optional(),
        adjustableByMessenger: z.boolean().optional(),
        vehicleTypes: VehicleType.array(),
        useOwnSMTP: z.boolean().optional(),
        smtpUser: z.string().max(100).optional(),
        smtpPassword: z.string().max(100).optional(),
        smtpHost: z.string().max(100).optional(),
        smtpPort: z.string().max(100).optional().nullable(),
        orders: z.object({
            showNewOrderNotification: z.enum(['never', 'onlyToday', 'always']),
            emailNotifications: z.object({
                senderName: z.string(),
                replyToEmail: z.string(),
                defaultNotificationsReceiver: z.array(z.string()),
                defaultNotificationsCustomer: z.array(z.string())
            }),
            trackTrace: z.object({
                timeFrame: z.number(),
                email: z.string(),
                phone: z.string(),
                outForDeliveryStatusIcon: z.enum(['bike', 'cargo-bike', 'car']).optional()
            }),
            prices: z.array(OrderPriceTable),
            useNewPrices: z.boolean().default(true).optional()
        }),
        parcels: z.object({
            carriers: z.intersection(
                z.object({
                    partners: z.string().array(),
                    isVeloydPartner: z.boolean().optional()
                }),
                z.record(z.string(), z.any()) // TODO: type carriers settings
            ),
            trackTrace: z.object({
                senderName: z.string(),
                replyToEmail: z.string(),
                emailSubject: z.string(),
                emailBody: z.string(),
                sendTTMails: z.boolean()
            }),
            cancelEmail: z.object({
                sendCancelEmails: z.boolean(),
                senderName: z.string(),
                replyToEmail: z.string(),
                emailSubject: z.string(),
                emailBody: z.string()
            }),
            prices: z.record(z.string(), ParcelPriceTable), // PriceTables names
            fees: z.array(z.object({
                description: z.string(),
                price: z.string(),
                productCode: z.string(),
                auto: z.boolean().optional(),
                applyToAllCarriers: z.boolean().optional(),
                carriers: z.array(z.string()).optional(),
                applyToAllCountries: z.boolean().optional(),
                countries: z.array(z.string()).optional(),
                postalCodes: z.string().optional(),
                conditions: z.array(z.object({
                    variable: z.string(),
                    operator: z.string(),
                    value: z.number()
                })).optional(),
                matchAllConditions: z.boolean().optional(),
                quantity: z.number().optional()
            })).optional(),
            products: z.record(ParcelProductsCombination, z.record(
                z.string(), z.boolean() // Carrier names
            )),
            productCodes: z.record(z.string(), z.record(z.string(), z.array(z.object({
                name: z.string(),
                countries: z.array(z.string()),
                type: z.string().optional(),
                productCode: z.string().optional(),
                kgProductCode: z.string().optional(),
                categories: z.array(z.object({
                    minWeight: z.string().optional(),
                    maxWeight: z.string(),
                    length: z.number().optional(),
                    width: z.number().optional(),
                    height: z.number().optional(),
                    volume: z.number().optional(),
                    productCode: z.string()
                }))
            })))).optional(),
            preferences: z.record(z.string(), z.array(
                z.object({
                    name: z.string(),
                    countries: z.array(z.string())
                }).or(z.record(z.string(), z.string()))
            )
            )
        }),
        customers: z.object({
            senderName: z.string().optional().nullable(),
            replyToEmail: z.string().optional().nullable(),
            welcomeEmailSubject: z.string(),
            welcomeEmailBody: z.string()
        }),
        administration: z.object({
            ordersProductCode: z.string(),
            parcelsProductCode: z.string(),
            subscriptionsProductCode: z.string(),
            setPeriod: z.boolean(),
            api: z.string(),
            apiKey: z.string(),
            customerGroup: z.string(),
            checkConceptInvoices: z.boolean(),
            mergeOrders: z.boolean(),
            apiName: z.string().nullable(),
            clientId: z.string().nullable(),
            clientSecret: z.string(),
            apiInvoiceVATCode: z.string().nullable(),
            apiInvoiceDim: z.string().nullable(),
            apiPassword: z.string().nullable(),
            apiOrganisation: z.string().nullable(),
            apiOffice: z.string().nullable(),
            apiInvoiceType: z.string().nullable(),
            invoicePriceZero: z.boolean(),
            invoiceParcelsPriceZero: z.boolean(),
            checkOrders: z.boolean(),
            showPriceExplanationOrders: z.boolean().default(false),
            showPriceExplanationParcels: z.boolean().default(false),
            xmlExportTemplate: z.string().nullable(),
            xmlExport: z.boolean().nullable(),
            sendAttachment: z.boolean(),
            apiEnvironment: z.string().nullable(),
            noNewCustomers: z.boolean().nullable(),
            customersNotEditable: z.boolean().nullable(),
            afasCustomerGetConnector: z.string().nullable(),
            afasGUIDDebtorName: z.string(),
            afasGUIDDebtorId: z.string(),
            afasGUIDEmail: z.string(),
            afasGUIDPhone: z.string(),
            afasGUIDStreet: z.string(),
            afasGUIDNr: z.string(),
            afasGUIDAddition: z.string(),
            afasGUIDStreet2: z.string(),
            afasGUIDPostalCode: z.string(),
            afasGUIDCity: z.string(),
            afasGUIDCountryCode: z.string(),
            afasGUIDCountry: z.string().nullable(),
            afasAdministration: z.string().nullable(),
            afasTypeItem: z.string().nullable(),
            afasUnit: z.string().nullable(),
            afasWarehouse: z.string().nullable(),
            afasGUIDVeloydId: z.string(),
            afasGUIDCustomerReference: z.string().nullable(),
            afasGUIDTrackTrace: z.string().nullable(),
            afasGUIDCarrier: z.string().nullable(),
            afasGUIDOptions: z.string().nullable(),
            afasGUIDWeight: z.string().nullable(),
            afasGUIDInfo: z.string().nullable()
        }).partial(),
        partnerKey: z.string(),
        defaultPartnerSettings: z.object({
            sendSettings: z.object({
                communication: z.string(),
                allowPartnerEdit: z.boolean().optional()
            }),
            receiveSettings: z.object({
                deliveryZones: z.string().array(),
                maxWeight: z.number().optional(),
                maxVolume: z.number().optional(),
                maxLength: z.number().optional(),
                maxWidth: z.number().optional(),
                maxHeight: z.number().optional()
            })
        }),
        partnerSettings: z.object({
            _id: z.string(),
            status: z.enum(['inactive', 'requested', 'pending', 'active']),
            useCustomScanColor: z.boolean(),
            customScanColor: z.string(),
            creditorCode: z.string().optional(),
            sendSettings: z.object({
                communication: z.string().optional(),
                allowPartnerEdit: z.string().optional(),
                useCustomDeliveryZones: z.boolean(),
                deliveryZones: DeliveryZone.array()
            }),
            receiveSettings: z.object({
                customDeliveryZones: z.string().optional(),
                deliveryZones: z.string().array(),
                maxWeight: z.number().optional(),
                maxVolume: z.number().optional(),
                maxLength: z.number().optional(),
                maxWidth: z.number().optional(),
                maxHeight: z.number().optional()
            }),
            showRouteInfo: z.boolean().optional()
        }).array(),
        mfaWebEnabled: z.boolean().optional(),
        mfaAppEnabled: z.boolean().optional()
    })
})

const ResellerModel = NewResellerModel.extend({
    _id: z.string()
})

export {NewResellerModel, ResellerModel, VehicleType}
