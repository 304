import React from 'react'

import _ from 'lodash'

import CustomerActions from '../../actions/CustomerActions.js'
import AddressActions from '../../actions/AddressActions.js'
import LoginActions from '../../actions/LoginActions.js'
import UserActions from '../../actions/UserActions.js'

import {Address, Input, Select, NewSelect, TextArea, Toggle, Option, Alert, Button, Tabs, Tab, Popup, IconButton, Modal, H4, P, Row, Column, Colors, H5, ReasonsSettings, Card} from '../UI/index.js'
import ParcelSettings from './ParcelSettings.js'
import Contacts from './Contacts.js'
import NewCustomer from './NewCustomer.js'
import defaultEmailNotifications from '../../views/admin/components/resellers/defaultEmailNotifications.js'
const emailNotifications = defaultEmailNotifications()


const days = {
    0: 'M',
    1: 'D',
    2: 'W',
    3: 'D',
    4: 'V',
    5: 'Z',
    6: 'Z'
}


class CustomerModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            customer: NewCustomer(),
            loading: false,
            message: '',
            error: '',
            mailLoading: false,
            mailMessage: '',
            mailError: '',
            tab: 'gegevens',
            validation: {}
        }
        this.state = structuredClone(this.initialState)
    }

    open(customer) {
        customer = customer ? structuredClone(customer) : this.state.customer

        console.log(customer)

        this.setState({
            modalIsOpen: true,
            customer
        })
    }

    close() {
        this.setState(this.initialState)
    }


    onChangeValue(path, event) {
        const {reseller} = this.props
        const customer = {...this.state.customer}

        if (path === 'email' || path === 'loginEmail') {
            event.target.value = event.target.value.toLowerCase().replace(/\s+/g, '')
        }

        if (path === 'settings.webhookURL' || path === 'settings.parcelWebhookURL') {
            event.target.value = event.target.value.toLowerCase()
        }

        if (path === 'settings.orders.emailNotificationsCustomerOption' && event.target.value === 'other' && !customer.settings.orders.emailNotificationsCustomer.length) {
            customer.settings.orders.emailNotificationsCustomer = reseller.settings.orders.emailNotifications.defaultNotificationsCustomer
        }

        if (path === 'settings.orders.emailNotificationsReceiverOption' && event.target.value === 'other' && !customer.settings.orders.emailNotificationsReceiver.length) {
            customer.settings.orders.emailNotificationsReceiver = reseller.settings.orders.emailNotifications.defaultNotificationsReceiver
        }

        if (event.target.values) {
            _.set(customer, path, event.target.values)
        } else {
            _.set(customer, path, event.target.value)
        }

        this.setState({customer})
    }

    onChangeChecked(path, event) {
        const customer = {...this.state.customer}

        _.set(customer, path, event.target.checked)

        this.setState({customer})
    }

    onAddressChange(address) {
        const customer = {...this.state.customer}

        customer.name = address.name
        customer.address = address

        this.setState({customer})
    }

    onReasonsSettingsChange(customer) {
        this.setState({customer})
    }

    onRetourAddressChange(address) {
        const customer = {...this.state.customer}

        customer.settings.orders.retourAddress = address

        this.setState({customer})
    }

    onContactsChange(contacts) {
        const customer = {...this.state.customer}

        customer.contacts = contacts

        this.setState({customer})
    }


    onClickGenerateApiKey() {
        const customer = {...this.state.customer}

        if (!customer.settings.apiKey) {
            customer.settings.apiKey = generateUUID()
            this.setState({customer})
        } else {
            this.popup.open('Genereer nieuwe API key', 'De oude API key is hierna niet meer geldig! Bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                customer.settings.apiKey = generateUUID()
                this.setState({customer})
            })
        }
    }

    onOpeningHoursChange(day, event) {
        const customer = {...this.state.customer}

        customer.settings.openingHours[day].open = event.target.checked

        this.setState({customer})
    }

    onClickRemoveApiKey() {
        const customer = {...this.state.customer}

        if (customer.settings.apiKey) {
            this.popup.open('Verwijder API key', 'De API key wordt verwijderd, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                customer.settings.apiKey = ''
                customer.settings.webhookURL = ''
                customer.settings.parcelWebhookURL = ''
                customer.settings.useFTP = false
                this.setState({customer})
            })
        }
    }

    onClickGenerateHmacSecret() {
        const customer = {...this.state.customer}

        if (!customer.settings.hmacSecret) {
            customer.settings.hmacSecret = generateUUID()
            this.setState({customer})
        } else {
            this.popup.open('Genereer nieuwe HMAC key', 'De oude HMAC key is hierna niet meer geldig, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                customer.settings.hmacSecret = generateUUID()
                this.setState({customer})
            })
        }
    }

    onClickRemoveHmacSecret() {
        const customer = {...this.state.customer}

        if (customer.settings.hmacSecret) {
            this.popup.open('Verwijder HMAC key', 'De HMAC key wordt verwijderd, bestaande koppelingen werken hierdoor niet meer.', () => {
                this.popup.close()
                customer.settings.hmacSecret = ''
                this.setState({customer})
            })
        }
    }

    async onClickCopy(text) {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text)
            } catch (err) {
                console.log(err)
            }
        }
    }


    onSendWelcomeMail() {
        const customer = {...this.state.customer}
        this.setState({mailLoading: true, mailMessage: '', mailError: ''})

        let promise = Promise.resolve()

        if (!customer._id) {
            this.setState({loading: true, error: ''})

            promise = new Promise((resolve, reject) => {
                CustomerActions.create(customer, (err) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve()
                    }
                })
            })
        } else {
            this.setState({loading: true, error: ''})

            promise = new Promise((resolve, reject) => {
                CustomerActions.edit(customer, (err) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve()
                    }
                })
            })
        }

        promise.then(() => {
            LoginActions.sendCustomerWelcomeMail(customer, (err, response) => {
                if (err) {
                    this.setState({loading: false, mailLoading: false, mailError: err})
                } else {
                    this.setState({loading: false, mailLoading: false, mailMessage: response})
                }
            })
        }).catch((err) => {
            this.setState({loading: false, error: err, mailLoading: false})
        })
    }

    async onSubmit(event) {
        event.preventDefault()


        if (await this.validate()) {
            const customer = {...this.state.customer}
            this.setState({loading: true, error: '', message: ''})

            if (customer._id) {
                CustomerActions.edit(customer, this.response.bind(this))
            } else {
                CustomerActions.create(customer, this.response.bind(this))
            }

            if (customer.address.save) {
                AddressActions.update([customer.address])
            }
        }
    }

    response(err) {
        if (err) {
            this.setState({error: err, loading: false})
        } else {
            this.close()
        }
    }


    async validate() {
        const {id = '', reseller} = this.props
        const {customer} = this.state
        const validation = {}
        let valid = true
        let addressValid = true
        let focusId = ''
        let tab = ''
        let error = ''


        if (customer.login && !customer.loginEmail) {
            valid = false
            focusId = `loginEmail${id}`
            validation.loginEmail = true
            tab = 'gegevens'
        }

        const hasOpenDays = _.some(Object.keys(customer.settings.openingHours), (day) => customer.settings.openingHours[day].open)

        if (!hasOpenDays) {
            valid = false
            error = 'Er zijn geen openingsdagen ingesteld.'
            tab = 'gegevens'
        }

        const hasPickupDays = !customer.settings.parcels.autoPickupOrder || _.some(Object.keys(customer.settings.openingHours), (day) => reseller.settings.account.openingHours[day].open && customer.settings.openingHours[day].open && customer.settings.parcels.pickupDays[day])

        if (hasOpenDays && !hasPickupDays) {
            valid = false
            error = 'Er zijn geen ophaaldagen ingesteld.'
            tab = 'zendingen'
        }

        if (valid) {
            await new Promise((resolve) => {
                this.setState({tab: 'gegevens'}, () => {
                    tab = 'gegevens'
                    addressValid = this.address.validate()
                    resolve()
                })
            })
        }

        this.setState({validation, tab, error}, () => {
            if (focusId) {
                document.getElementById(focusId).focus()
            }
        })

        return addressValid && valid
    }

    onReset2FA() {
        const {customer} = this.state
        this.popup.open('Reset 2FA methode', 'De klant zal de 2FA methode opnieuw moeten activeren. Inloggen met de huidige methode zal niet meer mogelijk zijn. Weet je zeker dat je door wil gaan?', () => {
            UserActions.reset2FA({...customer, isCustomer: true}, (err, updatedUser) => {
                if (err) {
                    this.setState({error: err})
                } else {
                    this.setState({customer: updatedUser}, () => {
                        this.popup.close()
                    })
                }
            })
        })
    }


    render() {
        const {customer, message, error, loading, modalIsOpen, tab, mailLoading, mailMessage, mailError} = this.state
        const {reseller, carriers, user, id = ''} = this.props

        const notificationKeysReceiver = Object.keys(emailNotifications).filter((key) => !key.includes('customer') && emailNotifications[key].constructor === Object)
        const notificationKeysCustomer = Object.keys(emailNotifications).filter((key) => key.includes('customer') && emailNotifications[key].constructor === Object)

        const notificationsReceiver = notificationKeysReceiver.map((notificationKey) => ({
            value: notificationKey,
            title: emailNotifications[notificationKey].label
        }))

        const notificationsCustomer = notificationKeysCustomer.map((notificationKey) => ({
            value: notificationKey,
            title: emailNotifications[notificationKey].label
        }))

        const pickupNotPossibleReasonsHasDefault = customer?.settings?.orders?.pickupNotPossibleReasons?.includes('default')

        const pickupNotPossibleOptionsContainerStyles = pickupNotPossibleReasonsHasDefault ? {} : {paddingLeft: 12, paddingRight: 12, marginBottom: 12}

        const deliveryNotPossibleReasonsHasDefault = customer?.settings?.orders?.deliveryNotPossibleReasons?.includes('default')

        const deliveryNotPossibleOptionsContainerStyles = deliveryNotPossibleReasonsHasDefault ? {} : {paddingLeft: 12, paddingRight: 12, marginBottom: 12}

        return (
            <Modal show={modalIsOpen} onClose={this.close.bind(this)}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 24}}>
                    <H4>{customer._id ? 'Bewerk klant' : 'Nieuwe klant'}</H4>

                    <IconButton onClick={this.close.bind(this)}>
                        <i style={{color: Colors.buttonSolid}} className='mdi mdi-close'/>
                    </IconButton>
                </div>

                <form onSubmit={this.onSubmit.bind(this)}>
                    <Tabs value={tab} onChange={(event) => this.setState({tab: event.target.value})}>
                        <Tab value='gegevens'>Gegevens</Tab>

                        <Tab value='contactpersonen'>Contactpersonen</Tab>

                        {reseller.permissions.orders &&
                            <Tab value='ritten'>Ritten</Tab>
                        }

                        {reseller.permissions.parcels &&
                            <Tab value='zendingen'>Zendingen</Tab>
                        }

                        <Tab value='koppelingen'>Koppelingen</Tab>
                    </Tabs>

                    {tab === 'gegevens' &&
                        <>
                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='Debiteurnummer'
                                        info={customer._id && reseller.settings.administration.api ? 'Het debiteurnummer is niet aanpasbaar als er een koppeling is met een boekhoudsysteem.' : ''}
                                        value={customer.debtorCode}
                                        disabled={reseller.settings.administration.api}
                                        onChange={this.onChangeValue.bind(this, 'debtorCode')}
                                    />
                                </Column>
                                <Column/>
                            </Row>

                            {reseller.settings.administration.api === 'WeFact' &&
                                <Row>
                                    <Column>
                                        <Toggle
                                            label='Afwijkend debiteurnummer facturatie'
                                            info='Zet deze optie aan als voor deze klant op een ander debiteurnummer gefactureerd moet worden.'
                                            checked={customer.useOtherInvoiceDebtorCode}
                                            onChange={this.onChangeChecked.bind(this, 'useOtherInvoiceDebtorCode')}
                                        />
                                    </Column>

                                    <Column>
                                        {customer.useOtherInvoiceDebtorCode &&
                                            <Input
                                                label='Debiteurnummer facturatie'
                                                info={''}
                                                value={customer.invoiceDebtorCode}
                                                onChange={this.onChangeValue.bind(this, 'invoiceDebtorCode')}
                                            />
                                        }
                                    </Column>
                                </Row>
                            }


                            <Address
                                disabled={customer._id && reseller.settings.administration.api && reseller.settings.administration.customersNotEditable && !(customer.dontOverrideAddressOnSync && user.permissions.invoices)}
                                address={customer.address}
                                noTAV
                                businessCheckbox
                                allowAttachments={true}
                                onChange={this.onAddressChange.bind(this)}
                                ref={(ref) => this.address = ref}
                            />

                            {reseller.settings.administration.api && user.permissions.invoices &&
                                <Toggle
                                    style={{width: '50%'}}
                                    label={`Adres niet synchroniseren met ${reseller.settings.administration.api}`}
                                    checked={customer.dontOverrideAddressOnSync}
                                    onChange={this.onChangeChecked.bind(this, 'dontOverrideAddressOnSync')}
                                />
                            }

                            <Row style={{marginTop: 24}}>
                                <Column>
                                    <Input
                                        label='Telefoon'
                                        value={customer.phone}
                                        onChange={this.onChangeValue.bind(this, 'phone')}
                                    />
                                </Column>

                                <Column>
                                    <Input
                                        label='Emailadres'
                                        value={customer.email}
                                        onChange={this.onChangeValue.bind(this, 'email')}
                                    />
                                </Column>
                            </Row>


                            <Row style={{alignItems: 'center'}}>
                                <Column>
                                    <P style={{marginLeft: 6}}>Geopend op</P>
                                </Column>

                                <Column style={{marginBottom: 12}}>
                                    {_.keys(customer.settings.openingHours).map((key, index) => {
                                        return (
                                            <Option
                                                key={key}
                                                label={days[index]}
                                                checked={customer.settings.openingHours[key].open}
                                                onChange={this.onOpeningHoursChange.bind(this, key)}
                                            />
                                        )
                                    })}
                                </Column>
                            </Row>

                            {reseller.accountType === 'carrierPlus' &&
                                <>
                                    <Row>
                                        <Column>
                                            <Toggle
                                                label='Klant login'
                                                checked={customer.login}
                                                onChange={this.onChangeChecked.bind(this, 'login')}
                                            />
                                        </Column>

                                        <Column>
                                            {customer.login &&
                                                <Input
                                                    id={`loginEmail${id}`}
                                                    label='Emailadres klantlogin'
                                                    value={customer.loginEmail}
                                                    onChange={this.onChangeValue.bind(this, 'loginEmail')}
                                                    isInvalid={this.state.validation.loginEmail}
                                                />
                                            }
                                        </Column>
                                    </Row>
                                    {customer.login && customer.settings.mfaRequired && (
                                        <Row style={{marginLeft: 6, alignItems: 'baseline'}}>
                                            {user.isOwner ? (
                                                <>
                                                    <Column>
                                                        <P>Deze klant heeft tweefactorauthenticatie geactiveerd.</P>
                                                    </Column>
                                                    <Column>
                                                        <Button
                                                            style={{marginBottom: 24}}
                                                            onClick={this.onReset2FA.bind(this)}
                                                            variant='outline-white'
                                                        >
                                                            Reset 2FA
                                                        </Button>
                                                    </Column>
                                                </>
                                            ):
                                                <Column>
                                                    <P style={{marginBottom: 12}}>Deze klant heeft tweefactorauthenticatie geactiveerd. Het eigenaar-account van Veloyd kan de 2FA methode resetten. Het eigenaar-account is: {reseller.ownerEmail}.</P>
                                                </Column>
                                            }
                                        </Row>
                                    )}
                                </>
                            }
                            <Row>
                                <Column>
                                    <TextArea
                                        label="Notities"
                                        rows={4}
                                        value={customer.notes}
                                        onChange={this.onChangeValue.bind(this, 'notes')}
                                    />
                                </Column>
                            </Row>
                        </>
                    }

                    {tab === 'contactpersonen' &&
                        <Contacts
                            contacts={customer.contacts}
                            onChange={this.onContactsChange.bind(this)}
                            ref={(ref) => this.contacts = ref}
                        />
                    }


                    {tab === 'ritten' &&
                        <>
                            <H5 style={{marginBottom: 24}}>Ritopties</H5>

                            <Row>
                                <Column>
                                    <Select
                                        copy
                                        label='Bezorggebied'
                                        info='Dit bezorggebied wordt gebruikt voor het aanmelden van ritten via de API.'
                                        options={reseller.settings.deliveryZones.map((area) => {
                                            return {value: area.id, title: area.name}
                                        })}
                                        values={customer.settings.orders.deliveryZones || []}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.deliveryZones')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Select
                                        label='Standaard handtekening'
                                        value={customer.settings.orders.defaultSigned}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.defaultSigned')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.defaultSigned ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>

                                <Column>
                                    <Select
                                        label='Standaard naam voor ontvangst'
                                        value={customer.settings.orders.nameRequired}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.nameRequired')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.nameRequired ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Select
                                        label='Standaard niet bij buren'
                                        value={customer.settings.orders.defaultStatedAddressOnly}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.defaultStatedAddressOnly')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.defaultStatedAddressOnly ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>

                                <Column>
                                    <Select
                                        label='GPS verplicht'
                                        value={customer.settings.orders.gpsRequired}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.gpsRequired')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.gpsRequired ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Select
                                        label='Scan verplicht'
                                        value={customer.settings.orders.scanRequired}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.scanRequired')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.scanRequired ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>

                                <Column>
                                    <Select
                                        label='Foto verplicht'
                                        value={customer.settings.orders.imageRequired}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.imageRequired')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.imageRequired ? 'Ja' : 'Nee'})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>
                            </Row>

                            <div style={pickupNotPossibleOptionsContainerStyles}>
                                <Card
                                    style={{
                                        backgroundColor: pickupNotPossibleReasonsHasDefault ? '' : Colors.backgroundNeutral2,
                                        border: 'none',
                                        ...(pickupNotPossibleReasonsHasDefault ? {padding: '0 6px'} : {padding: '6px 0 0 0'})
                                    }}
                                >
                                    <Select
                                        label={'Redenen voor \'Ophalen niet mogelijk \''}
                                        options={[
                                            {
                                                value: 'default',
                                                title: 'Gebruik vervoerder instelling'
                                            },
                                            {
                                                value: 'custom',
                                                title: 'Aangepast'
                                            }
                                        ]}
                                        value={pickupNotPossibleReasonsHasDefault ? 'default' : 'custom'}
                                        onChange={(event) => {
                                            customer.settings.orders.pickupNotPossibleReasons = [
                                                ...event.target.value === 'default' ? ['default'] : []
                                            ]
                                            customer.settings.orders.pickupNotPossibleReasonsSpecifications = []
                                            this.onReasonsSettingsChange(customer)
                                        }}
                                    />
                                    {!pickupNotPossibleReasonsHasDefault &&
                                    <ReasonsSettings
                                        reseller={reseller}
                                        customer={customer}
                                        onChange={this.onReasonsSettingsChange.bind(this)}
                                        type='pickup'
                                    />
                                    }
                                </Card>
                            </div>

                            <div style={deliveryNotPossibleOptionsContainerStyles}>
                                <Card
                                    style={{
                                        backgroundColor: deliveryNotPossibleReasonsHasDefault ? '' : Colors.backgroundNeutral2,
                                        border: 'none',
                                        ...(deliveryNotPossibleReasonsHasDefault ? {padding: '0 6px'} : {padding: '6px 0 0 0'})
                                    }}
                                >
                                    <Select
                                        label={'Redenen voor \'Bezorgen niet mogelijk \''}
                                        options={[
                                            {
                                                value: 'default',
                                                title: 'Gebruik vervoerder instelling'
                                            },
                                            {
                                                value: 'custom',
                                                title: 'Aangepast'
                                            }
                                        ]}
                                        value={deliveryNotPossibleReasonsHasDefault ? 'default' : 'custom'}
                                        onChange={(event) => {
                                            customer.settings.orders.deliveryNotPossibleReasons = [
                                                ...event.target.value === 'default' ? ['default'] : []
                                            ]
                                            customer.settings.orders.deliveryNotPossibleReasonsSpecifications = []
                                            this.onReasonsSettingsChange(customer)
                                        }}
                                    />
                                    {!deliveryNotPossibleReasonsHasDefault &&
                                    <ReasonsSettings
                                        reseller={reseller}
                                        customer={customer}
                                        onChange={this.onReasonsSettingsChange.bind(this)}
                                        type='delivery'
                                    />
                                    }
                                </Card>
                            </div>

                            <Row>
                                <Column>
                                    <Select
                                        label='Collo toevoegen toestaan'
                                        value={customer.settings.orders.allowAddCollo}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.allowAddCollo')}
                                    >
                                        <option value='default'>{`Gebruik vervoerder instelling (${reseller.settings.allowAddCollo === 'yes' ? 'Ja' : (reseller.settings.allowAddCollo === 'deliver' ? 'Alleen bij bezorgen' : (reseller.settings.allowAddCollo === 'pickup' ? 'Alleen bij ophalen' : 'Nee'))})`}</option>
                                        <option value='yes'>Ja</option>
                                        <option value='deliver'>Alleen bezorgen</option>
                                        <option value='pickup'>Alleen bij ophalen</option>
                                        <option value=''>Nee</option>
                                    </Select>
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <TextArea
                                        label='Instructies nieuwe rit'
                                        info='Deze instructies worden weergegeven bij het aanmaken van een nieuwe rit voor deze klant.'
                                        value={customer.settings.orders.newOrderInstructions}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.newOrderInstructions')}
                                    />
                                </Column>
                            </Row>


                            <H5 style={{marginBottom: 24, marginTop: 36}}>Administratie</H5>

                            {reseller.settings.orders.useNewPrices &&
                                <Row>
                                    <Column>
                                        <NewSelect
                                            label='Standaard tarieftabel'
                                            allowEmptyValue
                                            value={customer.settings.orders.defaultPriceTable}
                                            onChange={this.onChangeValue.bind(this, 'settings.orders.defaultPriceTable')}
                                        >
                                            {(reseller.settings.orders.prices || []).map((priceTable) => {
                                                return (
                                                    <option key={priceTable.id} value={priceTable.id}>{priceTable.name}</option>
                                                )
                                            })}
                                        </NewSelect>
                                    </Column>
                                    <Column/>
                                </Row>
                            }

                            <Row>
                                <Column>
                                    <Toggle
                                        label='Referentie verplicht'
                                        checked={customer.settings.orders.referenceRequired}
                                        onChange={this.onChangeChecked.bind(this, 'settings.orders.referenceRequired')}
                                    />
                                </Column>
                                <Column>
                                    <Toggle
                                        label='Genereer referentie automatisch'
                                        checked={customer.settings.orders.generateReference}
                                        onChange={this.onChangeChecked.bind(this, 'settings.orders.generateReference')}
                                    />
                                </Column>
                            </Row>

                            <H5 style={{marginBottom: 24, marginTop: 36}}>Notificaties</H5>

                            <Row>
                                <Column>
                                    <Select
                                        label='Email notificaties ontvanger'
                                        value={customer.settings.orders.emailNotificationsReceiverOption}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.emailNotificationsReceiverOption')}
                                    >
                                        <option value='default'>Gebruik vervoerder instelling</option>
                                        <option value='other'>Aangepast</option>
                                        <option value=''>Verstuur geen notificaties</option>
                                    </Select>
                                </Column>

                                <Column>
                                    {customer.settings.orders.emailNotificationsReceiverOption === 'other' && (
                                        <Select
                                            label='Aangepaste email notificaties ontvanger'
                                            values={customer.settings.orders.emailNotificationsReceiver}
                                            options={notificationsReceiver|| []}
                                            onChange={this.onChangeValue.bind(this, 'settings.orders.emailNotificationsReceiver')}
                                        />
                                    )}
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Select
                                        label='Email notificaties klant'
                                        value={customer.settings.orders.emailNotificationsCustomerOption}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.emailNotificationsCustomerOption')}
                                    >
                                        <option value='default'>Gebruik vervoerder instelling</option>
                                        <option value='other'>Aangepast</option>
                                        <option value=''>Verstuur geen notificaties</option>
                                    </Select>
                                </Column>

                                <Column>
                                    {customer.settings.orders.emailNotificationsCustomerOption === 'other' && (
                                        <Select
                                            label='Aangepaste email notificaties klant'
                                            values={customer.settings.orders.emailNotificationsCustomer}
                                            options={notificationsCustomer || []}
                                            onChange={this.onChangeValue.bind(this, 'settings.orders.emailNotificationsCustomer')}
                                        />
                                    )}
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Select
                                        label='Toon melding bij nieuwe rit'
                                        value={customer.settings.orders.showNewOrderNotification}
                                        onChange={this.onChangeValue.bind(this, 'settings.orders.showNewOrderNotification')}
                                        options={[
                                            {
                                                value: 'default',
                                                title: 'Gebruik vervoerder instelling'
                                            },
                                            {
                                                title: 'Nooit',
                                                value: 'never'
                                            },
                                            {
                                                title: 'Alleen rit voor vandaag',
                                                value: 'onlyToday'
                                            },
                                            {
                                                title: 'Altijd',
                                                value: 'always'
                                            }
                                        ]}
                                    />
                                </Column>

                                <Column/>
                            </Row>
                        </>
                    }

                    {tab === 'zendingen' &&
                        <ParcelSettings
                            customer={customer}
                            carriers={carriers}
                            reseller={reseller}
                            onChange={(customer) => this.setState({customer})}
                        />
                    }


                    {tab === 'koppelingen' &&
                    <>
                        <Row>
                            <Column>
                                <Input
                                    style={{flex: 1, marginRight: 15}}
                                    label='API Key'
                                    readOnly
                                    value={customer.settings.apiKey || ''}
                                />
                            </Column>

                            <Column>

                                {!customer.settings.apiKey &&
                                    <Button
                                        variant='outline-white'
                                        icon='mdi mdi-plus'
                                        label='Nieuwe API key'
                                        onClick={this.onClickGenerateApiKey.bind(this)}
                                    />
                                }

                                {customer.settings.apiKey &&
                                    <IconButton onClick={this.onClickCopy.bind(this, customer.settings.apiKey)}>
                                        <i className='mdi mdi-content-copy'/>
                                    </IconButton>
                                }

                                {customer.settings.apiKey &&
                                    <IconButton onClick={this.onClickRemoveApiKey.bind(this)}>
                                        <i className='mdi mdi-delete'/>
                                    </IconButton>
                                }
                            </Column>
                        </Row>

                        {customer.settings.apiKey &&
                        <>
                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='Webhook URL ritten'
                                        value={customer.settings.webhookURL || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.webhookURL')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='Webhook URL zendingen'
                                        value={customer.settings.parcelWebhookURL || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.parcelWebhookURL')}
                                    />
                                </Column>
                            </Row>

                            {(customer.settings.webhookURL || customer.settings.parcelWebhookURL) &&
                            <>
                                <Row>
                                    <Column>
                                        <Input
                                            style={{flex: 1}}
                                            label='Webhook Authorization header'
                                            value={customer.settings.webhookAuthHeader || ''}
                                            onChange={this.onChangeValue.bind(this, 'settings.webhookAuthHeader')}
                                        />
                                    </Column>
                                </Row>

                                <Row>
                                    <Column>
                                        <Input
                                            style={{flex: 1, marginRight: 15}}
                                            label='HMAC key'
                                            readOnly
                                            value={customer.settings.hmacSecret || ''}
                                        />
                                    </Column>

                                    <Column>
                                        {!customer.settings.hmacSecret &&
                                            <Button
                                                variant='outline-white'
                                                icon='mdi mdi-plus'
                                                label='Nieuwe HMAC key'
                                                onClick={this.onClickGenerateHmacSecret.bind(this)}
                                            />
                                        }

                                        {customer.settings.hmacSecret &&
                                            <IconButton onClick={this.onClickCopy.bind(this, customer.settings.hmacSecret)}>
                                                <i className='mdi mdi-content-copy'/>
                                            </IconButton>
                                        }

                                        {customer.settings.hmacSecret &&
                                            <IconButton onClick={this.onClickRemoveHmacSecret.bind(this)}>
                                                <i className='mdi mdi-delete'/>
                                            </IconButton>
                                        }
                                    </Column>
                                </Row>
                            </>
                            }
                        </>
                        }

                        <br/>
                        {!reseller.isShop &&
                            <Row>
                                <Column>
                                    <Toggle
                                        style={{flex: 1, marginRight: 6}}
                                        label='Status updates ritten via FTP'
                                        checked={customer.settings.useFTP}
                                        onChange={this.onChangeChecked.bind(this, 'settings.useFTP')}
                                    />
                                </Column>
                            </Row>
                        }

                        {customer.settings.useFTP &&
                        <>
                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='FTP host'
                                        value={customer.settings.ftpHost || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpHost')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        type='number'
                                        style={{flex: 1}}
                                        label='FTP poort'
                                        value={customer.settings.ftpPort || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpPort')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='FTP gebruikersnaam'
                                        value={customer.settings.ftpUser || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpUser')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        type='password'
                                        autocomplete='new-password'
                                        style={{flex: 1}}
                                        label='FTP wachtwoord'
                                        value={customer.settings.ftpPassword || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpPassword')}
                                    />
                                </Column>
                            </Row>
                        </>
                        }

                        {!reseller.isShop &&
                            <Row>
                                <Column>
                                    <Toggle
                                        style={{flex: 1, marginRight: 6}}
                                        label='Importeer ritten via FTP'
                                        checked={customer.settings.useOrderImportFTP}
                                        onChange={this.onChangeChecked.bind(this, 'settings.useOrderImportFTP')}
                                    />
                                </Column>
                            </Row>
                        }

                        {customer.settings.useOrderImportFTP &&
                        <>
                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='FTP host'
                                        value={customer.settings.ftpOrderImportHost || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpOrderImportHost')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        type='number'
                                        style={{flex: 1}}
                                        label='FTP poort'
                                        value={customer.settings.ftpOrderImportPort || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpOrderImportPort')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='FTP gebruikersnaam'
                                        value={customer.settings.ftpOrderImportUser || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpOrderImportUser')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        type='password'
                                        autocomplete='new-password'
                                        style={{flex: 1}}
                                        label='FTP wachtwoord'
                                        value={customer.settings.ftpOrderImportPassword || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpOrderImportPassword')}
                                    />
                                </Column>
                            </Row>

                            <Row>
                                <Column>
                                    <Input
                                        style={{flex: 1}}
                                        label='FTP folder'
                                        value={customer.settings.ftpOrderImportDir || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.ftpOrderImportDir')}
                                    />
                                </Column>
                            </Row>

                            {customer.settings.ftpOrderImportHost?.indexOf('boekhuis.nl') > -1 &&
                            <Row>
                                <Column>
                                    <Input
                                        type='password'
                                        autocomplete='new-password'
                                        style={{flex: 1}}
                                        label='CB webhook wachtwoord'
                                        value={customer.settings.cbWebhookPassword || ''}
                                        onChange={this.onChangeValue.bind(this, 'settings.cbWebhookPassword')}
                                    />
                                </Column>
                            </Row>
                            }
                        </>
                        }

                        <Row style={{marginTop: 24}}>
                            <Column>
                                <Toggle
                                    label='Recept Locatie ritten met ophaaladres'
                                    info='Ritten die via de koppeling met Recept Locatie worden aangemeld worden aangemaakt met als ophaaladres het adres van de klant.'
                                    checked={customer.settings.orders.receptlocatieDirectOrders}
                                    onChange={this.onChangeChecked.bind(this, 'settings.orders.receptlocatieDirectOrders')}
                                />
                            </Column>
                        </Row>
                    </>
                    }

                    {message &&
                        <Alert dismissible variant="success">{message}</Alert>
                    }
                    {error &&
                        <Alert variant='danger'>{error}</Alert>
                    }
                    {mailMessage &&
                        <Alert dismissible variant="success">{mailMessage}</Alert>
                    }
                    {mailError &&
                        <Alert dismissible variant="danger">{mailError}</Alert>
                    }

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {customer.login && customer.loginEmail && tab === 'gegevens' &&
                            <Button
                                variant='text'
                                loading={mailLoading}
                                onClick={this.onSendWelcomeMail.bind(this)}
                            >
                                Verstuur welkomstmail
                            </Button>
                        }
                        <Button type="submit" loading={loading}>Opslaan</Button>
                    </div>
                </form>

                <Popup ref={(ref) => this.popup = ref}/>
            </Modal>
        )
    }
}

function generateUUID() {
    let d = new Date().getTime()

    if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now()
    }

    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random()*16)%16 | 0
        d = Math.floor(d/16)
        return (c=='x' ? r : (r&0x3|0x8)).toString(16)
    })

    return uuid
}

export default (CustomerModal)
